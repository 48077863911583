import memoize from 'lodash.memoize';
import { IAppState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const opinionsListSelector = (state: IAppState) => state.opinions.list;

export const opinionsReceivedSelector = (state: IAppState) =>
  state.opinions.listReceived;
export const opinionsGivenSelector = (state: IAppState) =>
  state.opinions.listGiven;

export const opinionsArraySelector = createSelector(
  opinionsListSelector,
  (opinions) => {
    return Object.values(opinions);
  },
);

export const opinionsGivenArraySelector = createSelector(
  [opinionsGivenSelector],
  (opinions) => {
    return Object.values(opinions);
  },
);

export const opinionsReceivedArraySelector = createSelector(
  [opinionsReceivedSelector],
  (opinions) => {
    return Object.values(opinions);
  },
);

export const makeOpinionListSelector = createSelector(
  opinionsListSelector,
  (opinions) => memoize((id: string) => opinions && opinions[id]),
);

export const makeOpinionGivenSelector = createSelector(
  opinionsGivenSelector,
  (opinions) => memoize((id: string) => opinions && opinions[id]),
);

export const makeOpinionReceivedSelector = createSelector(
  opinionsReceivedSelector,
  (opinions) => memoize((id: string) => opinions && opinions[id]),
);

export const makeOpinionToUriSelector = createSelector(
  opinionsGivenSelector,
  (opinions) => (opinionToUri: string) => {
    return Object.values(opinions).find(
      (item) => item.opinionToUri === opinionToUri,
    );
  },
);

export const viewSelector = (state: IAppState) => state.opinions.view['list'];

export const viewReceivedSelector = (state: IAppState) =>
  state.opinions.view['listReceived'];
export const viewGivenSelector = (state: IAppState) =>
  state.opinions.view['listGiven'];

export const loadingSelector = (state: IAppState, key = 'list') =>
  state.opinions.loading[key];

export const errorSelector = (state: IAppState, key = 'list') =>
  state.opinions.error[key];

export const averageSelector = (state: IAppState) => state.opinions.average;
