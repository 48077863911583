import { IOpinion } from '../../entities/opinions.entity';
import { OpinionsApi } from '../api/opinions.api';
import {
  fetchOpinionsFailed,
  fetchOpinionsRequest,
  fetchOpinionsSucceeded,
} from '../slices/opinions.slice';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk, IAppState } from 'store';

export const updateOpinionThunk = (
  id: string,
  opinion: Partial<IOpinion<any, any>>,
): AppThunk<Promise<IOpinion<any, any>>> => async (
  dispatch: Dispatch<any>,
  getState: () => IAppState,
) => {
  let response: AxiosResponse<IOpinion<any, any>> = null;
  dispatch(fetchOpinionsRequest({ key: id }));
  try {
    response = await OpinionsApi.update(id, opinion);

    dispatch(
      fetchOpinionsSucceeded({
        list: { [response.data.id]: response.data },
        key: id,
      }),
    );
    return response.data;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
    };
    // - Fetch failed, set error
    dispatch(
      fetchOpinionsFailed({
        key: id,
        error,
      }),
    );
    throw error;
  }
};
