import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMangoPayResponse } from '../../entities/mango-pay.response';
import { IPayloadError, IAppState } from 'store';
import memoize from 'lodash/memoize';

export interface IPaymentLoading {
  loading: boolean;
  loadingDefaultCard: boolean;
}
export interface IPaymentInitialState {
  errors: {
    [key: string]: IPayloadError | undefined;
    defaultCard: IPayloadError | undefined;
    fetchCard: IPayloadError | undefined;
  };
  loadings: IPaymentLoading & { [key: string]: boolean };
  mangoPay?: IMangoPayResponse;
  token?: string;
}
export const initialState: IPaymentInitialState = {
  loadings: {
    loading: false,
    loadingDefaultCard: false,
  },
  token: undefined,
  mangoPay: undefined,
  errors: {
    defaultCard: undefined,
    fetchCard: undefined,
  },
};

export type State = typeof initialState;

export const loadingSelector = (state: IAppState) =>
  state.paymentMangoPay.loadings;

export const makeLoadingSelector = createSelector(loadingSelector, (loadings) =>
  memoize((item: string) => {
    return !!loadings[item];
  }),
);

export const fetchDefaultCardErrorSelector = (state: IAppState) =>
  state.paymentMangoPay.errors
    ? state.paymentMangoPay.errors.defaultCard
    : null;
export const fetchCardErrorSelector = (state: IAppState) =>
  state.paymentMangoPay.errors ? state.paymentMangoPay.errors.fetchCard : null;
export const mangoPaySelector = (state: IAppState) =>
  state.paymentMangoPay.mangoPay;
export const tokenCredentialSelector = (state: IAppState) =>
  state.paymentMangoPay.token;

const paymentSlice = createSlice({
  name: 'paymentMangoPay',
  initialState,
  reducers: {
    /**
     * Set id empty for clear credentials info
     */
    fetchCredentialsClear: (state: State, _action: PayloadAction) => {
      state.mangoPay = undefined;
    },
    /**
     * Set loading to true when waiting for credential info
     */
    fetchCredentials: (state: State, _action: PayloadAction) => {
      state.loadings.loading = true;
    },
    /**
     * Store the credentials info and reset loading
     */
    fetchCredentialsSucceeded: (
      state: State,
      action: PayloadAction<IMangoPayResponse>,
    ) => {
      state.mangoPay = action.payload;
      state.loadings.loading = false;
    },
    /**
     * Reset loading
     */
    fetchCredentialsFailed: (
      state: State,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.loadings.loading = false;
    },
    fetchAddCard: (state: State) => {
      state.loadings.loading = true;
    },
    fetchAddCardFailed: (
      state: State,
      action: PayloadAction<IPayloadError>,
    ) => {
      state.loadings.loading = false;
      state.errors = {
        ...state.errors,
        fetchCard: action.payload,
      };
    },
    fetchAddCardSucceeded: (state) => {
      state.loadings.loading = false;
      if (state.errors) {
        state.errors.fetchCard = undefined;
      }
    },
    fetchSetDefaultCard: (
      state,
      { payload: { cardId } }: PayloadAction<{ cardId: string }>,
    ) => {
      state.loadings[cardId] = true;
      state.errors = {
        ...state.errors,
        defaultCard: undefined,
      };
    },
    fetchSetDefaultCardSucceeded: (
      state: State,
      { payload: { cardId } }: PayloadAction<{ cardId: string }>,
    ) => {
      state.loadings[cardId] = false;
    },
    fetchSetDefaultCardFailed: (
      state: State,
      action: PayloadAction<IPayloadError & { cardId: string }>,
    ) => {
      state.loadings[action.payload.cardId] = false;
      state.errors = {
        ...state.errors,
        defaultCard: action.payload,
      };
    },
    fetchCardDeletionRequest: (
      state: State,
      { payload: { cardId } }: PayloadAction<{ cardId: string }>,
    ) => {
      state.loadings[cardId] = true;
      state.errors = {
        ...state.errors,
        [cardId]: undefined,
      };
    },
    fetchCardDeletionSucceeded: (
      state: State,
      { payload: { cardId } }: PayloadAction<{ cardId: string }>,
    ) => {
      state.loadings[cardId] = false;
    },
    fetchCardDeletionFailed: (
      state: State,
      {
        payload: { cardId, ...rest },
      }: PayloadAction<IPayloadError & { cardId: string }>,
    ) => {
      state.loadings[cardId] = true;
      state.errors = {
        ...state.errors,
        [cardId]: rest,
      };
    },
  },
});

export const {
  fetchCredentialsFailed,
  fetchCredentialsSucceeded,
  fetchCredentialsClear,
  fetchCredentials,
  fetchAddCard,
  fetchAddCardSucceeded,
  fetchAddCardFailed,
  fetchSetDefaultCard,
  fetchSetDefaultCardFailed,
  fetchSetDefaultCardSucceeded,
  fetchCardDeletionRequest,
  fetchCardDeletionFailed,
  fetchCardDeletionSucceeded,
} = paymentSlice.actions;

export default paymentSlice.reducer;
