import { AxiosError, AxiosResponse } from 'axios';
import { IPerson } from 'person';
import { Dispatch } from 'redux';
import { AppThunk, generateErrorHandlerAction } from 'store';

import { IOrganization } from '../../entities/organization.entity';
import { OrganizationApi } from '../api/organization.api';
import {
  fetchAddFounder,
  fetchAddFounderFailed,
  fetchAddFounderSucceeded,
  fetchDeleteFounder,
  fetchDeleteFounderFailed,
  fetchDeleteFounderSucceeded,
  fetchUpdateFounder,
  fetchUpdateFounderFailed,
  fetchUpdateFounderSucceeded,
} from '../slices/founder.slice';

export const addFounderRequest = (
  organizationId: string,
  founder: Partial<IPerson>,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrganization> | null = null;
  dispatch(fetchAddFounder());

  try {
    if (founder && organizationId) {
      response = await OrganizationApi.addFounder(organizationId, founder);
    } else {
      dispatch(
        fetchAddFounderFailed({
          isAxiosError: false,
          message: 'Founder not created',
          name: 'Format Error',
          stack: '',
        }),
      );
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchAddFounderFailed,
      ),
    );
    onError(error);
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set founder
    dispatch(fetchAddFounderSucceeded());
    onError(undefined);
  }
};

export const updateFounderRequest = (
  organizationId: string,
  founderId: string,
  founder: Partial<IPerson>,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrganization> | null = null;
  dispatch(fetchUpdateFounder());

  try {
    if (founder && organizationId) {
      response = await OrganizationApi.updateFounder(
        organizationId,
        founderId,
        founder,
      );
    } else {
      dispatch(
        fetchUpdateFounderFailed({
          isAxiosError: false,
          message: 'Founder not updated',
          name: 'Format Error',
          stack: '',
        }),
      );
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchUpdateFounderFailed,
      ),
    );
    onError(error);
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set founder
    dispatch(fetchUpdateFounderSucceeded());
    onError(undefined);
  }
};

export const deleteFounderRequest = (
  organizationId: string,
  founderId: string,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchDeleteFounder());

  try {
    await OrganizationApi.deleteFounder(organizationId, founderId);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchDeleteFounderFailed({
        isAxiosError,
        message,
        name,
        code: statusCode,
        stack,
      }),
    );
    onError(err);
    return;
  }

  dispatch(fetchDeleteFounderSucceeded());
  onError(undefined);
  return;
};
