import { AxiosResponse } from 'axios';
import { normalize, schema } from 'normalizr';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { ICalendarEvent, IEventOwnerFilter } from '../../entities';
import { CalendarApi } from '../api/calendar.api';
import {
  fetchEvents,
  fetchEventsFailed,
  fetchEventsSucceeded,
} from '../slices/calendar.slice';

export const getEventsOwnerThunk = <T>(
  params: Partial<IEventOwnerFilter> = {},
): AppThunk<Promise<{ [key: string]: ICalendarEvent<T> }>> => async (
  dispatch: Dispatch<any>,
) => {
  let response: AxiosResponse<ICalendarEvent<T>[]> = null;
  dispatch(fetchEvents('events'));

  try {
    response = await CalendarApi.getAllEventsByOwner(params);
    const eventSchema = new schema.Entity('events');
    const {
      entities: { events },
    } = normalize(response.data, [eventSchema]);

    dispatch(
      fetchEventsSucceeded({
        events: events as { [key: string]: ICalendarEvent<T> },
        key: 'events',
      }),
    );

    return events;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: null,
      toJSON: null,
    };

    dispatch(
      fetchEventsFailed({
        key: 'events',
        error,
      }),
    );

    throw error;
  }
};
