import { differenceInHours, differenceInMinutes } from 'date-fns';
import memoize from 'lodash/memoize';

import { createSelector } from '@reduxjs/toolkit';

import { IEvent } from '../../entities/event.entity';
import { IAppStateCalendar } from './calendar.slice';

export const findOneLoadingSelector = (state: IAppStateCalendar) =>
  state.calendar.loading;
export const findOneErrorSelector = (state: IAppStateCalendar) =>
  state.calendar.error;
export const findOneSelector = (state: IAppStateCalendar) =>
  state.calendar.current;

export const eventsSelector = (state: IAppStateCalendar) =>
  state.calendar.events;
export const errorSelector = (state: IAppStateCalendar, key = 'events') =>
  state.calendar.error[key];
export const loadingSelector = (state: IAppStateCalendar, key = 'events') =>
  state.calendar.loading[key];
export const eventsArraySelector = createSelector(eventsSelector, (CmsApi) => {
  return Object.values(CmsApi);
});

export const makeEventSelector = createSelector(eventsSelector, (events) =>
  memoize((id: string) => events && events[id]),
);

export const slotsSelector = createSelector(eventsArraySelector, (events) => {
  const slots: { [key: string]: IEvent } = events.reduce((acc, event) => {
    acc = {
      ...acc,
      [event.startDate]: {
        id: event.id,
        start: event.startDate,
        duration: {
          hours: differenceInHours(
            new Date(event.endDate),
            new Date(event.startDate),
          ),
          minutes:
            differenceInMinutes(
              new Date(event.endDate),
              new Date(event.startDate),
            ) % 60,
        },
        ...event.metadatas,
      },
    };
    return acc;
  }, {});
  return slots;
});
