import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IPagination } from 'shared-entities/pagination.entity';
import { EventsStatusListener, ReduxDispatch } from 'store';
import { IOption, IProduct } from '../../entities/product.entity';
import { ProductApi } from '../api/product.api';
import FormData from 'form-data';
import {
  fetchClearProduct,
  fetchClearProducts,
  makeProductsByTypeSelector,
  productErrorSelector,
  productLoadingSelector,
  productsByOrganizationSelector,
  productsCategoryOrganizationSelector,
  productSelector,
  productsListLoadingSelector,
  productsLoadingSelector,
  productsSelector,
  viewSelector,
  totalProductsSelector,
} from '../slices/product.slice';
import { addOptionRequest } from '../thunks/add-option.thunk';
import { addProduct as addProductRequest } from '../thunks/add-product.thunk';
import { deleteOptionRequest } from '../thunks/delete-option.thunk';
import { deleteProductRequest } from '../thunks/delete-product.thunk';
import { getProductRequest } from '../thunks/get-product.thunk';
import {
  getProductsByOrganizationRequest,
  getProductsFromOrganizationRequest,
  getProductsRequest,
  getPublicProductsRequest,
} from '../thunks/get-products.thunk';
import { setDisableProductRequest } from '../thunks/set-disable-product.thunk';
import { setEnableProductRequest } from '../thunks/set-enable-product.thunk';
import { updateProduct as updateProductRequest } from '../thunks/update-product.thunk';
import { v4 as uuidv4 } from 'uuid';

export const useProducts = <T>() => {
  const dispatch = useDispatch<ReduxDispatch>();
  const { t } = useTranslation();

  const getProductsSuperAdmin = async (
    page: number,
    itemsPerPage: number,
    filters: {} = {},
  ) => {
    const response: AxiosResponse<IPagination<
      IProduct<T>
    >> = await ProductApi.getPublicProducts(page, itemsPerPage, filters);
    const { results, view } = response.data;

    return { results, view };
  };

  const getProduct = (id: string) => {
    return dispatch(getProductRequest(id));
  };

  const getPublicProducts = (params = {}) => {
    return dispatch(getPublicProductsRequest(params));
  };

  const calculatePrice = async (
    price: number,
    planUri: string,
  ): Promise<{
    amount: number;
  }> => {
    const response = await ProductApi.calculatePrice(price, planUri);

    return response.data;
  };

  const addPhoto = (
    productId: string,
    media: File,
    onProgress?: (progressEvent: any) => void,
  ) => {
    const formData = new FormData();
    formData.append('upload', media);
    return axios.post(
      `/api/shop/products/${productId}/pictures/${uuidv4()}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: onProgress,
      },
    );
  };

  const removePhoto = (
    productId: string | undefined,
    mediaId: string | undefined,
  ) => {
    return axios.delete(`/api/shop/products/${productId}/pictures/${mediaId}`);
  };

  const getParentProducts = async (q: string) => {
    return await ProductApi.getParentProducts(q);
  };

  const updateProduct = async (id: string, data: Partial<IProduct<T>>) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateProductRequest(id, data, (err) => {
          if (err != null) {
            reject(err);
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
          } else {
            resolve();
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
          }
        }),
      );
    });
  };

  const addProduct = async (data: Partial<IProduct<T>>) =>
    dispatch(addProductRequest(data));

  const deleteProduct = async (id: string) => {
    return new Promise((resolve, reject) => {
      dispatch(
        deleteProductRequest(id, (err) => {
          if (err != null) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.delete-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.delete-success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  const clearProduct = () => {
    return dispatch(fetchClearProduct());
  };
  const clearProducts = () => {
    return dispatch(fetchClearProducts());
  };

  const getProductsCategoryFromOrganization = (
    organizationUri: string,
    categoryUri: string,
  ) => {
    dispatch(getProductsFromOrganizationRequest(organizationUri, categoryUri));
  };

  const getProducts = (params: { [key: string]: string | number }) => {
    dispatch(getProductsRequest(params));
  };

  const getProductsByOrganization = (organizationUri: string) => {
    dispatch(getProductsByOrganizationRequest(organizationUri));
  };

  const setAvailabilityProduct = (id: string, availability: boolean) => {
    if (availability) {
      return new Promise((resolve, reject) => {
        dispatch(
          setEnableProductRequest(id, (err) => {
            if (err != null) {
              reject(err);
            } else {
              resolve();
            }
          }),
        );
      });
    }
    return new Promise((resolve, reject) => {
      dispatch(
        setDisableProductRequest(id, (err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const addOption = (productId: string, data: IOption) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addOptionRequest(productId, data, (err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const deleteOption = (optionId: string, productId: string) => {
    return new Promise((resolve, reject) => {
      dispatch(
        deleteOptionRequest(optionId, productId, (err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const replaceOption = async (
    optionId: string,
    productId: string,
    data: IOption,
  ) => {
    await deleteOption(optionId, productId);
    await addOption(productId, data);
  };

  const product: IProduct<T> = useSelector(productSelector);
  const productLoading = useSelector(productLoadingSelector);
  const productError = useSelector(productErrorSelector);
  const productsCategory: {
    [key: string]: IProduct<T>;
  } = useSelector(productsCategoryOrganizationSelector);
  const productsByTypeSelector = useSelector(makeProductsByTypeSelector);
  const productsByType = (type: string) =>
    productsByTypeSelector(type) as IProduct<T>[];
  const productsLoading = useSelector(productsLoadingSelector);
  const productsListLoading = useSelector(productsListLoadingSelector);
  const products = useSelector(productsSelector);
  const view = useSelector(viewSelector);
  const total = useSelector(totalProductsSelector);

  const productsByOrganization: (
    organizationId: string,
  ) => {
    [key: string]: IProduct<T>;
  } = (organizationId: string) =>
    useSelector(productsByOrganizationSelector(organizationId));

  return {
    calculatePrice,
    products,
    view,
    total,
    productsListLoading,
    productsLoading,
    productsByType,
    getPublicProducts,
    productsByOrganization,
    getProductsByOrganization,
    clearProducts,
    getProductsSuperAdmin,
    getProduct,
    updateProduct,
    addProduct,
    deleteProduct,
    product,
    productLoading,
    productError,
    clearProduct,
    getProductsCategoryFromOrganization,
    productsCategory,
    getParentProducts,
    setAvailabilityProduct,
    addOption,
    deleteOption,
    replaceOption,
    getProducts,
    addPhoto,
    removePhoto,
  };
};
