import axios from 'axios';

export class OrderApi {
  public static getOrders = (page = 1, itemsPerPage = 15, filters?: {}) => {
    return axios.get(`/api/shop/orders`, {
      params: { page, itemsPerPage, ...filters },
    });
  };

  public static changeOrderStatus = (orderId: string, status: number) => {
    return axios.post(`/api/shop/orders/${orderId}/status/${status}`);
  };

  public static getOrder = (orderId: string) => {
    return axios.get(`/api/shop/orders/${orderId}`);
  };

  public static getOrdersByOrganization = (
    organizationId: string,
    page = 1,
    itemsPerPage = 15,
    filters?: {},
  ) => {
    return axios.get(`/api/shop/orders/list-to/${organizationId}`, {
      params: { page, itemsPerPage, ...filters },
    });
  };
}
