import { IProduct } from '../../entities/product.entity';
import { ProductApi } from '../api/product.api';
import {
  fetchSetAvailabilityProduct,
  fetchSetAvailabilityProductFailed,
  fetchSetAvailabilityProductSucceeded,
} from '../slices/product.slice';

import { AxiosError, AxiosProxyConfig, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

export const setEnableProductRequest = (
  productId: string,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchSetAvailabilityProduct());

  let response: AxiosResponse<IProduct> | null = null;

  try {
    response = await ProductApi.setEnableProduct(productId);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (responseError?.data) {
      const {
        data: { statusCode, message },
      } = responseError;
      // - Fetch failed, set error
      dispatch(
        fetchSetAvailabilityProductFailed({
          isAxiosError,
          message,
          name,
          code: statusCode,
          stack,
        }),
      );

      onError({ isAxiosError, message, name, code: statusCode, stack } as any);
      return;
    }
  }

  if (response) {
    const { data } = response;

    const product = data;

    dispatch(fetchSetAvailabilityProductSucceeded(product));
    onError();
    return;
  }

  onError({
    isAxiosError: false,
    message: 'An error occured',
    name: '',
    code: '404',
    stack: '',
    toJSON: () => ({}),
    config: (null as unknown) as AxiosProxyConfig,
  });
  return;
};
