import axios, { AxiosRequestConfig } from 'axios';
import Qs from 'qs';

const configHandler = (config: AxiosRequestConfig) => {
  if (localStorage) {
    const token = localStorage.getItem('token');
    const impersonateToken = localStorage.getItem('impersonateToken');
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (impersonateToken) {
      config.headers.Authorization = `Bearer ${impersonateToken}`;
    }
  }
  return config;
};

axios.interceptors.request.use(configHandler);

const analyticsInstance = axios.create({});

analyticsInstance.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;
analyticsInstance.defaults.headers.common.referrer = ``;
analyticsInstance.defaults.headers.post['Content-Type'] = 'application/json';
analyticsInstance.defaults.headers.get['Content-Type'] = 'application/json';
analyticsInstance.defaults.headers.put['Content-Type'] = 'application/json';
analyticsInstance.defaults.withCredentials = true;
analyticsInstance.defaults.timeout = 30000;

analyticsInstance.interceptors.request.use(configHandler);

const cmsInstance = axios.create({});
cmsInstance.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;
cmsInstance.defaults.headers.common.referrer = ``;
cmsInstance.defaults.headers.post['Content-Type'] = 'application/json';
cmsInstance.defaults.headers.get['Content-Type'] = 'application/json';
cmsInstance.defaults.headers.put['Content-Type'] = 'application/json';
cmsInstance.defaults.withCredentials = true;
cmsInstance.defaults.timeout = 30000;

cmsInstance.interceptors.request.use(configHandler);

export { cmsInstance, analyticsInstance };

const placeInstance = axios.create({});

placeInstance.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;
placeInstance.defaults.headers.common.referrer = ``;
placeInstance.defaults.headers.post['Content-Type'] = 'application/json';
placeInstance.defaults.headers.get['Content-Type'] = 'application/json';
placeInstance.defaults.headers.put['Content-Type'] = 'application/json';
placeInstance.defaults.withCredentials = true;
placeInstance.defaults.timeout = 30000;

placeInstance.interceptors.request.use(configHandler);

export { placeInstance };

const calendarInstance = axios.create({});

calendarInstance.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;
calendarInstance.defaults.headers.common.referrer = ``;
calendarInstance.defaults.headers.post['Content-Type'] = 'application/json';
calendarInstance.defaults.headers.get['Content-Type'] = 'application/json';
calendarInstance.defaults.headers.put['Content-Type'] = 'application/json';
calendarInstance.defaults.withCredentials = true;
calendarInstance.defaults.timeout = 30000;

// Format nested params correctly
calendarInstance.interceptors.request.use((config) => {
  const newConfig = configHandler(config);
  newConfig.paramsSerializer = (params) => {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false,
    });
  };

  return newConfig;
});

export { calendarInstance };

const communicationInstance = axios.create({});

communicationInstance.defaults.headers.common[
  'Access-Control-Allow-Origin'
] = `*`;
communicationInstance.defaults.headers.common.referrer = ``;
communicationInstance.defaults.headers.post['Content-Type'] =
  'application/json';
communicationInstance.defaults.headers.get['Content-Type'] = 'application/json';
communicationInstance.defaults.headers.put['Content-Type'] = 'application/json';
communicationInstance.defaults.withCredentials = true;
communicationInstance.defaults.timeout = 30000;

// Format nested params correctly
communicationInstance.interceptors.request.use((config) => {
  const newConfig = configHandler(config);
  newConfig.paramsSerializer = (params) => {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false,
    });
  };

  return newConfig;
});

export { communicationInstance };

const opinionsInstance = axios.create({});

opinionsInstance.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;
opinionsInstance.defaults.headers.common.referrer = ``;
opinionsInstance.defaults.headers.post['Content-Type'] = 'application/json';
opinionsInstance.defaults.headers.get['Content-Type'] = 'application/json';
opinionsInstance.defaults.headers.put['Content-Type'] = 'application/json';
opinionsInstance.defaults.withCredentials = true;
opinionsInstance.defaults.timeout = 30000;

opinionsInstance.interceptors.request.use(configHandler);

export { opinionsInstance };

const pdfInstance = axios.create({});

pdfInstance.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;
pdfInstance.defaults.headers.common.referrer = ``;
pdfInstance.defaults.headers.post['Content-Type'] = 'application/pdf';
pdfInstance.defaults.headers.get['Content-Type'] = 'application/pdf';
pdfInstance.defaults.headers.put['Content-Type'] = 'application/pdf';
pdfInstance.defaults.withCredentials = true;
pdfInstance.defaults.timeout = 30000;

pdfInstance.interceptors.request.use(configHandler);

export { pdfInstance };

export const initialize = (
  apiKey: string,
  apiSecret: string,
  instanceName: string,
  baseURL?: string,
  analyticsURL?: string,
  cmsURL?: string,
  placeURL?: string,
  calendarURL?: string,
  communicationURL?: string,
  opinionURL?: string,
  pdfURL?: string,
) => {
  axios.defaults.baseURL = baseURL || `https://api.${instanceName}.io`;
  analyticsInstance.defaults.baseURL =
    analyticsURL || `https://analytics.${instanceName}.io`;
  cmsInstance.defaults.baseURL = cmsURL || `https://cms.${instanceName}.io`;
  placeInstance.defaults.baseURL =
    placeURL || `https://place.${instanceName}.io`;
  calendarInstance.defaults.baseURL =
    calendarURL || `https://calendar.${instanceName}.io`;
  communicationInstance.defaults.baseURL =
    communicationURL || `https://communication.${instanceName}.io`;
  opinionsInstance.defaults.baseURL =
    opinionURL || `https://opinion.${instanceName}.io`;
  pdfInstance.defaults.baseURL =
    pdfURL || `https://pdf-generator.${instanceName}.io`;

  axios.defaults.headers.common['X-WZ-API-KEY'] = apiKey;
  axios.defaults.headers.common['X-WZ-API-SECRET'] = apiSecret;

  analyticsInstance.defaults.headers.common['X-WZ-API-KEY'] = apiKey;
  analyticsInstance.defaults.headers.common['X-WZ-API-SECRET'] = apiSecret;

  cmsInstance.defaults.headers.common['X-WZ-API-KEY'] = apiKey;
  cmsInstance.defaults.headers.common['X-WZ-API-SECRET'] = apiSecret;

  placeInstance.defaults.headers.common['X-WZ-API-KEY'] = apiKey;
  placeInstance.defaults.headers.common['X-WZ-API-SECRET'] = apiSecret;

  calendarInstance.defaults.headers.common['X-WZ-API-KEY'] = apiKey;
  calendarInstance.defaults.headers.common['X-WZ-API-SECRET'] = apiSecret;

  communicationInstance.defaults.headers.common['X-WZ-API-KEY'] = apiKey;

  communicationInstance.defaults.headers.common['X-WZ-API-SECRET'] = apiSecret;

  opinionsInstance.defaults.headers.common['X-WZ-API-KEY'] = apiKey;

  opinionsInstance.defaults.headers.common['X-WZ-API-SECRET'] = apiSecret;

  pdfInstance.defaults.headers.common['X-WZ-API-KEY'] = apiKey;

  pdfInstance.defaults.headers.common['X-WZ-API-SECRET'] = apiSecret;
};
