import { IOpinion } from '../../entities/opinions.entity';
import { OpinionsApi } from '../api/opinions.api';
import {
  fetchOpinionsFailed,
  fetchOpinionsRequest,
  fetchOpinionsSucceeded,
} from '../slices/opinions.slice';

import { AxiosResponse } from 'axios';
import { normalize, schema } from 'normalizr';
import { IOrganization } from 'organization';
import { IPerson } from 'person';
import { Dispatch } from 'redux';
import { IView } from 'shared-entities/pagination.entity';
import { AppThunk, IAppState } from 'store';

export const getOpinionsGivenThunk = (params?: {
  [key: string]: string | number;
}): AppThunk<
  Promise<{
    [key: string]: IOpinion<IOrganization, IPerson>;
  }>
> => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  let response: AxiosResponse<{
    results: IOpinion<IOrganization, IPerson>[];
    view: IView;
  }> = null;
  dispatch(fetchOpinionsRequest({ key: 'listGiven' }));
  try {
    response = await OpinionsApi.getIGiveOpinions(params);
    const opinionsSchema = new schema.Entity('opinionsIGive');
    const {
      entities: { opinionsIGive },
    } = normalize(response.data.results, [opinionsSchema]);

    dispatch(
      fetchOpinionsSucceeded({
        list: opinionsIGive,
        view: response.data.view,
        key: 'listGiven',
        type: 'given',
      }),
    );
    return opinionsIGive;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
    };
    // - Fetch failed, set error
    dispatch(
      fetchOpinionsFailed({
        key: 'listGiven',
        error,
      }),
    );
    throw error;
  }
};
