import { IView } from 'shared-entities/pagination.entity';
import { IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICalendar, ICalendarEvent } from '../../entities';

const initialState: {
  events: { [key: string]: ICalendarEvent };
  error: { [key: string]: IPayloadError };
  loading: { [key: string]: boolean };
  view: { [key: string]: IView | undefined };
  current?: ICalendar;
} = {
  current: undefined,
  loading: { events: false },
  error: {},
  events: {},
  view: {},
};

export type CalendarState = typeof initialState;
export interface IAppStateCalendar {
  calendar: CalendarState;
}

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    fetchClear: (state: CalendarState, _action: PayloadAction) => {
      state.events = {};
      state.error = {};
      state.loading = {};
      state.view = {};
    },
    fetchEvents: (
      state: CalendarState,
      { payload }: PayloadAction<{ key?: string }>,
    ) => {
      state.loading[payload.key || 'events'] = true;
    },
    fetchEventsSucceeded: (
      state: CalendarState,
      {
        payload,
      }: PayloadAction<{
        events?: { [key: string]: ICalendarEvent };
        view?: IView;
        key?: string;
        id?: string;
      }>,
    ) => {
      if (payload.key === 'delete') {
        delete state.events[payload.id];
      } else {
        state.events = { ...state.events, ...payload.events };
      }

      state.loading[payload.key || 'events'] = false;
    },
    fetchEventsFailed: (
      state: CalendarState,
      { payload }: PayloadAction<{ key?: string; error: IPayloadError }>,
    ) => {
      state.error[payload.key || 'events'] = payload.error;
      state.loading[payload.key || 'events'] = false;
    },
    fetchFindOne: (state: CalendarState) => {
      state.loading['findOne'] = true;
      state.error['findOne'] = undefined;
    },
    fetchFindOneSucceeded: (
      state: CalendarState,
      { payload }: PayloadAction<ICalendar>,
    ) => {
      state.loading['findOne'] = false;
      state.current = payload;
    },
    fetchFindOneFailed: (
      state: CalendarState,
      { payload }: PayloadAction<IPayloadError>,
    ) => {
      state.loading['findOne'] = false;
      state.error['findOne'] = payload;
    },
    fetchUpdateCalendar: (
      state: CalendarState,
      { payload }: PayloadAction<{ key: string }>,
    ) => {
      state.loading[payload.key] = true;
      state.error[payload.key] = undefined;
    },
    fetchUpdateCalendarSucceeded: (
      state: CalendarState,
      { payload }: PayloadAction<{ key: string; calendar: ICalendar }>,
    ) => {
      if (payload.key === 'findOne') {
        state.current = payload.calendar;
      }
      state.loading[payload.key] = false;
    },
    fetchUpdateCalendarFailed: (
      state: CalendarState,
      { payload }: PayloadAction<{ key: string; error: IPayloadError }>,
    ) => {
      state.error[payload.key] = payload.error;
      state.loading[payload.key] = false;
    },
    fetchCalendar: (
      state: CalendarState,
      { payload }: PayloadAction<{ key?: string }>,
    ) => {
      state.loading[payload.key || 'current'] = true;
    },
    fetchCalendarSuccedded: (
      state: CalendarState,
      { payload }: PayloadAction<{ key: string; calendar: ICalendar }>,
    ) => {
      state.current = payload.calendar;
      state.loading[payload.key] = false;
    },
    fetchCalendarFailed: (
      state: CalendarState,
      { payload }: PayloadAction<{ key: string; error: IPayloadError }>,
    ) => {
      state.loading[payload.key] = false;
      state.error[payload.key] = payload.error;
    },
  },
});

export const {
  fetchUpdateCalendarSucceeded,
  fetchUpdateCalendarFailed,
  fetchUpdateCalendar,
  fetchEvents,
  fetchEventsFailed,
  fetchEventsSucceeded,
  fetchClear,
  fetchFindOne,
  fetchFindOneFailed,
  fetchFindOneSucceeded,
  fetchCalendar,
  fetchCalendarFailed,
  fetchCalendarSuccedded,
} = calendarSlice.actions;

export const { reducer: calendarReducer } = calendarSlice;
