import { PersonApi } from '../api/person.api';
import {
  fetchPerson,
  fetchPersonDisabledSucceeded,
  fetchPersonEnabledSucceeded,
  fetchPersonFailed,
  fetchPersons,
  fetchPersonsFailed,
  fetchPersonsMe,
  fetchPersonsMeFailed,
  fetchPersonsMeSucceeded,
  fetchPersonsSearchSucceeded,
  fetchPersonsSucceeded,
  fetchPersonSucceeded,
  fetchUpdatePerson,
  fetchUpdatePersonFailed,
  fetchUpdatePersonSucceeded,
} from '../slices/person.slice';

import { AxiosError, AxiosResponse } from 'axios';
import { formatBirthDate } from 'helpers/date.format.helper';
import { IPerson } from 'person';
import { Dispatch } from 'redux';
import { IPagination } from 'shared-entities/pagination.entity';
import { AppThunk, generateErrorHandlerAction, IAppState } from 'store';
import { v4 as uuidv4 } from 'uuid';

export const getMeRequest = (
  callback: (error?: { message: string; statusCode: string }) => void,
  params?: { [key: string]: string },
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchPersonsMe());

  let response: AxiosResponse<IPerson> | null = null;

  try {
    response = await PersonApi.getMe(params);
  } catch (err) {
    const { name, response: responseError, isAxiosError, stack } = err;
    if (responseError?.data) {
      const {
        data: { statusCode, message },
      } = responseError;
      // - Fetch failed, set error
      dispatch(
        fetchPersonsMeFailed({
          isAxiosError,
          message,
          name,
          code: statusCode,
          stack,
        }),
      );
      callback({ message, statusCode });
      localStorage.removeItem('token');
      // FIXME: fix redirection without token
      //window.location.reload();
      return;
    }
    dispatch(
      fetchPersonsMeFailed({
        isAxiosError: false,
        message: 'An error occured',
        name: '',
        code: '400',
        stack: '',
      }),
    );
    localStorage.removeItem('token');
    // FIXME: fix redirection without token
    //window.location.reload();
    return;
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set person
    const { data: person } = response;
    dispatch(fetchPersonsMeSucceeded(person));
    callback();
    return;
  }
  callback({ message: 'failed', statusCode: '' });
};

export const updateMe = (
  partialUser: Partial<IPerson>,
): AppThunk<Promise<IPerson>> => async (
  dispatch: Dispatch<any>,
  getState: () => IAppState,
) => {
  let response: AxiosResponse<IPerson> | null = null;
  dispatch(fetchPersonsMe());

  try {
    const user = getState().person.me;
    if (user && user.id != null) {
      if (partialUser.birthDate) {
        partialUser.birthDate = formatBirthDate(partialUser.birthDate);
      }
      response = await PersonApi.updateMe(user.id, partialUser);
    } else {
      dispatch(
        fetchPersonsMeFailed({
          isAxiosError: false,
          message: 'User id does not exists',
          name: 'Format Error',
          stack: '',
        }),
      );
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchPersonsMeFailed,
      ),
    );
    throw error;
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set person
    const { data: person } = response;
    dispatch(fetchPersonsMeSucceeded(person));
    return response.data;
  }
  return undefined;
};

export const updateUserThunk = (
  id: string,
  partialUser: Partial<IPerson>,
): AppThunk<Promise<IPerson>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IPerson> | null = null;
  dispatch(fetchUpdatePerson(id));

  try {
    response = await PersonApi.updateMe(id, partialUser);
    if (response && response.data && response.data.id) {
      // - Dispatch succeeded, set person
      const { data: person } = response;
      dispatch(fetchUpdatePersonSucceeded({ id, data: person }));
      return person;
    } else {
      throw new Error('Unknown error');
    }
  } catch (error) {
    dispatch(
      fetchUpdatePersonFailed({
        id,
        error: error?.response?.status ?? 'Unknown error',
      }),
    );

    throw error;
  }
};

export const getPersonsRequest = (
  page = 1,
  itemsPerPage = 10,
  params = {},
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchPersons({ key: 'list' }));

  let response: AxiosResponse<IPagination<IPerson>> | null = null;

  try {
    response = await PersonApi.getPersons(page, itemsPerPage, params);
  } catch (err) {
    const { name, response: responseError, isAxiosError, stack } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchPersonsFailed({
        key: 'list',
        error: { isAxiosError, message, name, code: statusCode, stack },
      }),
    );
    return;
  }
  if (response && response.data) {
    const searchResult = response.data.results.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    dispatch(
      fetchPersonsSucceeded({
        key: 'list',
        data: searchResult,
        total: response.data.view.total,
        page: response.data.view.page,
        itemsPerPage: response.data.view.itemsPerPage,
      }),
    );
    return;
  }
};
export const getSearchPersonsRequest = (
  page = 1,
  itemsPerPage = 10,
  params = {},
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchPersons({ key: 'list' }));

  let response: AxiosResponse<IPagination<IPerson>> | null = null;

  try {
    response = await PersonApi.getPersons(page, itemsPerPage, params);
  } catch (err) {
    const { name, response: responseError, isAxiosError, stack } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchPersonsFailed({
        key: 'list',
        error: { isAxiosError, message, name, code: statusCode, stack },
      }),
    );
    return;
  }
  if (response && response.data) {
    const result = response.data.results.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    dispatch(
      fetchPersonsSearchSucceeded({
        key: 'list',
        data: result,
        total: response.data.view.total,
        page: response.data.view.page,
        itemsPerPage: response.data.view.itemsPerPage,
      }),
    );
    return;
  }
};

export const getPersonRequest = (
  personId: string,
  analytics?: boolean,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchPerson());

  let response: AxiosResponse<IPerson> | null = null;

  try {
    response = await PersonApi.getPerson(personId, analytics);
  } catch (err) {
    const { name, response: responseError, isAxiosError, stack } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchPersonFailed({
        isAxiosError,
        message,
        name,
        code: statusCode,
        stack,
      }),
    );
    return;
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set person
    const { data } = response;

    dispatch(fetchPersonSucceeded(data));
    return;
  }
};

export const addPersonRequest = (
  person: Partial<IPerson>,
): AppThunk<Promise<IPerson>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IPerson> | null = null;
  dispatch(fetchPersons({ key: 'add' }));
  const data = { id: uuidv4(), ...person };

  try {
    const { email } = person;
    if (email === undefined) {
      dispatch(
        fetchPersonFailed({
          isAxiosError: false,
          message: 'Customer not created',
          name: 'Format Error',
          stack: '',
        }),
      );
      return;
    }
    response = await PersonApi.postPerson({ id: uuidv4(), ...data });
  } catch (error) {
    dispatch(fetchPersonsFailed({ key: 'add', error }));
    throw error;
  }

  dispatch(
    fetchPersonsSucceeded({
      key: 'add',
      data: { [data.id]: data },
    } as any),
  );
  return response.data;
};

export const addSuperAdminRequest = (
  person: Partial<IPerson>,
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IPerson> | null = null;
  dispatch(fetchPersons({ key: 'add' }));
  const data = person;

  try {
    const { email } = person;
    if (email === undefined) {
      dispatch(
        fetchPersonFailed({
          isAxiosError: false,
          message: 'Admin not created',
          name: 'Format Error',
          stack: '',
        }),
      );
      return;
    }
    response = await PersonApi.postSuperAdmin({ id: uuidv4(), ...data });
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(error as AxiosError<any>, fetchPersonFailed),
    );
    onError(error);
  }
  if (response && response?.data && response.data.id) {
    const personObj = [];
    personObj[response.data.id] = { ...response.data };

    dispatch(
      fetchPersonsSucceeded({
        key: 'add',
        data: (personObj as unknown) as { [key: string]: IPerson },
      }),
    );
    onError();
  }
};

export const deletePersonRequest = (
  userId: string,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchPersons({ key: 'delete' }));

  try {
    await PersonApi.deletePerson(userId);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    dispatch(
      fetchPersonsFailed({
        key: 'delete',
        error: { isAxiosError, message, name, code: statusCode, stack },
      }),
    );
    onError(err);
    return;
  }

  dispatch(fetchPersonsSucceeded({ key: 'delete', id: userId }));
  onError(undefined);
  return;
};

export const disabledPersonRequest = (personId: string): AppThunk => async (
  dispatch: Dispatch<any>,
) => {
  let response: AxiosResponse<any> | null = null;
  dispatch(fetchPersons({ key: 'disabled' }));

  try {
    response = await PersonApi.disablePerson(personId);

    if (response.status !== 200) {
      dispatch(
        fetchPersonFailed({
          isAxiosError: false,
          message: 'Customer not disabled',
          name: 'Format Error',
          stack: '',
        }),
      );
      return;
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(error as AxiosError<any>, fetchPersonFailed),
    );
  }
  if (response.status === 200) {
    dispatch(fetchPersonDisabledSucceeded(personId));
  }
};

export const enabledPersonRequest = (
  personId: string,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<any> | null = null;
  dispatch(fetchPersons({ key: 'enabled' }));

  try {
    response = await PersonApi.enablePerson(personId);

    if (response.status !== 200) {
      dispatch(
        fetchPersonFailed({
          isAxiosError: false,
          message: 'User not enabled',
          name: 'Format Error',
          stack: '',
        }),
      );
      return;
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(error as AxiosError<any>, fetchPersonFailed),
    );
  }
  if (response.status === 200) {
    dispatch(fetchPersonEnabledSucceeded(personId));
  }
};
