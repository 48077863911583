import { IClaimOpinion } from '../../entities/claim.opinion.entity';
import { IOpinion } from '../../entities/opinions.entity';
import {
  errorSelector,
  loadingSelector,
  makeOpinionGivenSelector,
  makeOpinionReceivedSelector,
} from '../slices/opinions.selectors';
import { opinionClaimThunk } from '../thunks/opinion.claim.thunk';
import { createOpinionThunk } from '../thunks/opinion.create.thunk';
import { updateOpinionThunk } from '../thunks/opinions.update.thunk';

import { IOrganization } from 'organization';
import { IPerson } from 'person';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventsStatusListener, IAppState, ReduxDispatch } from 'store';

export const useOpinion = (id: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ReduxDispatch>();
  const loading = useSelector((state: IAppState) => loadingSelector(state, id));
  const error = useSelector((state: IAppState) => errorSelector(state, id));
  const opinionReceivedSelector = useSelector(makeOpinionReceivedSelector);
  const opinionReceived = opinionReceivedSelector(id);
  const opinionGivenSelector = useSelector(makeOpinionGivenSelector);
  const opinionGiven = opinionGivenSelector(id);

  const updateOpinion = (opinion: Partial<IOpinion<any, any>>) => {
    return dispatch(updateOpinionThunk(id, opinion));
  };

  const createOpinion = (
    id: string,
    opinion: Partial<IOpinion<IOrganization, IPerson>>,
  ) => {
    return new Promise((resolve, reject) =>
      dispatch(
        createOpinionThunk(id, opinion, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
            resolve();
          }
        }),
      ),
    );
  };

  const createClaimOpinion = (opinionId: string, claim: IClaimOpinion) => {
    return new Promise((resolve, reject) => {
      dispatch(
        opinionClaimThunk(opinionId, claim, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  return {
    opinionGiven,
    opinionReceived,
    updateOpinion,
    createOpinion,
    createClaimOpinion,
    loading,
    error,
  };
};
