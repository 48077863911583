import { IView } from 'shared-entities/pagination.entity';
import { IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPost } from '../../entities/post.entity';

const initialState: {
  list: { [key: string]: IPost };
  error: { [key: string]: IPayloadError };
  loading: { [key: string]: boolean };
  view: { [key: string]: IView | undefined };
} = {
  loading: { list: false },
  error: {},
  list: {},
  view: {
    list: {
      page: 1,
      itemsPerPage: 3,
      total: 0,
    },
  },
};

export type CmsState = typeof initialState;
export interface IAppStateCms {
  cms: CmsState;
}

export const cmsSelector = (state: IAppStateCms) => state.cms.list;

const cmsSlice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    fetchClear: (state: CmsState, _action: PayloadAction) => {
      state.list = {};
      state.error = {};
      state.loading = {};
    },
    fetchCms: (
      state: CmsState,
      { payload }: PayloadAction<{ key?: string }>,
    ) => {
      state.loading[payload.key || 'list'] = true;
    },
    fetchCmsSucceeded: (
      state: CmsState,
      {
        payload,
      }: PayloadAction<{
        list?: { [key: string]: IPost };
        view?: IView;
        key?: string;
        id?: string;
        type?: string;
      }>,
    ) => {
      if (payload.key === 'delete') {
        delete state.list[payload.id];
      } else {
        if (
          payload.type &&
          (payload.type === 'story' || payload.type === 'blog')
        ) {
          state.list = { ...payload.list, ...state.list };
        } else {
          state.list = { ...state.list, ...payload.list };
        }
      }

      state.loading[payload.key || 'list'] = false;
      if (payload.view && !!payload.type) {
        state.view[payload.type] = payload.view;
      }
    },
    fetchCmsFailed: (
      state: CmsState,
      { payload }: PayloadAction<{ key?: string; error: IPayloadError }>,
    ) => {
      state.error[payload.key || 'list'] = payload.error;
      state.loading[payload.key || 'list'] = false;
    },
  },
});

export const {
  fetchCms,
  fetchCmsFailed,
  fetchCmsSucceeded,
  fetchClear,
} = cmsSlice.actions;

export const { reducer: cmsReducer } = cmsSlice;
