import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPerson } from 'person';
import { ILemonwayResponse } from 'shop';
import { IAppState, IPayloadError } from 'store';

export interface IPaymentInitialState {
  error: IPayloadError;
  lemonway?: {
    lemowayConfig: {
      loading: boolean;
      lemonway: ILemonwayResponse;
    };
    lemonwayCardInfo: {
      lemonway: Partial<IPerson>;
      loading: boolean;
    };
  };
}
export const initialState: IPaymentInitialState = {
  lemonway: {
    lemonwayCardInfo: {
      lemonway: {},
      loading: false,
    },
    lemowayConfig: {
      lemonway: {} as ILemonwayResponse,
      loading: false,
    },
  },
  error: {} as IPayloadError,
};

export type PaymentState = typeof initialState;

export const lemowayConfigSelector = (state: IAppState) =>
  state.paymentLemonWay.lemonway.lemowayConfig.lemonway;
export const lemowayConfigLoadingSelector = (state: IAppState) =>
  state.paymentLemonWay.lemonway.lemowayConfig.loading;

export const lemowayCardSelector = (state: IAppState) =>
  state.paymentLemonWay.lemonway.lemonwayCardInfo.lemonway;
export const lemowayCardLoadingSelector = (state: IAppState) =>
  state.paymentLemonWay.lemonway.lemonwayCardInfo.loading;

const paymentSlice = createSlice({
  name: 'paymentLemonWay',
  initialState,
  reducers: {
    fetchClearPayment: (state: PaymentState, _action: PayloadAction) => {
      state.lemonway = {
        lemonwayCardInfo: {
          lemonway: {},
          loading: false,
        },
        lemowayConfig: {
          lemonway: {} as ILemonwayResponse,
          loading: false,
        },
      };
      state.error = {} as IPayloadError;
    },
    fetchConfigPayment: (state: PaymentState) => {
      state.lemonway.lemowayConfig.loading = true;
    },
    fetchCardInfoPayment: (state: PaymentState) => {
      state.lemonway.lemonwayCardInfo.loading = true;
    },
    fetchConfigPaymentSuccedded: (
      state: PaymentState,
      action: PayloadAction<{
        data: ILemonwayResponse;
      }>,
    ) => {
      const { payload } = action;
      state.lemonway.lemowayConfig.lemonway = payload.data;
      state.lemonway.lemowayConfig.loading = false;
    },
    fetchCardPaymentSuccedded: (
      state: PaymentState,
      action: PayloadAction<{
        data: Partial<IPerson>;
      }>,
    ) => {
      const { payload } = action;
      state.lemonway.lemonwayCardInfo.lemonway = payload.data;
      state.lemonway.lemonwayCardInfo.loading = false;
    },
    fetchPaymentFailed: (
      state: PaymentState,
      action: PayloadAction<IPayloadError>,
    ) => {
      const { payload } = action;
      state.error = payload;
    },
  },
});

export const {
  fetchClearPayment,
  fetchPaymentFailed,
  fetchCardInfoPayment,
  fetchCardPaymentSuccedded,
  fetchConfigPayment,
  fetchConfigPaymentSuccedded,
} = paymentSlice.actions;

export const { reducer: paymentReducer } = paymentSlice;
