import axios from 'axios';
import { IPicture } from 'gallery';

export class GalleryApi {
  public static getGallery = () => {
    return axios.get('/api/galleries');
  };
  public static deleteGalleryItem = (itemId: string) => {
    return axios.delete(`/api/galleries/${itemId}`);
  };
  public static addGalleryItem = (pictureWithCategory: {
    media: IPicture;
    id: string;
    categoryUri: string;
  }) => {
    return axios.post('/api/galleries', pictureWithCategory);
  };
}
