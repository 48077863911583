import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { categoryAdapter } from 'shop/adapter/category.adapter';
import { AppThunk } from 'store';

import {
  ICategory,
  ICategoryNestedResponse,
} from '../../entities/category.type';
import { ProductApi } from '../api/product.api';
import {
  fetchCategoryFailed,
  fetchCategorySucceeded,
  fetchUpdateCategory,
} from '../slices/category.slice';

export const updateCategoryRequest = (
  id: string,
  partialCategory: Partial<ICategory>,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<ICategoryNestedResponse> | null = null;
  dispatch(fetchUpdateCategory(id));

  try {
    response = await ProductApi.updateCategory(id, partialCategory);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (responseError?.data) {
      const {
        data: { statusCode, message },
      } = responseError;
      dispatch({
        key: 'update',
        error: {
          isAxiosError,
          message,
          name,
          code: statusCode,
          stack,
        },
      });
      onError(err);
      return;
    }
  }

  if (response && response.data && response.data.id) {
    const category = categoryAdapter([response.data]);
    dispatch(fetchCategorySucceeded({ key: 'update', data: category }));
    onError(undefined);
    return;
  }

  dispatch(
    fetchCategoryFailed({
      key: 'update',
      error: {
        isAxiosError: false,
        message: '',
        name: '',
        code: '404',
        stack: '',
      },
    }),
  );
  onError(undefined);
};
