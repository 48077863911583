import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { CmsApi } from '../api/cms.api';
import {
  fetchCms,
  fetchCmsFailed,
  fetchCmsSucceeded,
} from '../slices/cms.slice';

export const deleteCmsThunk = (
  id: string,
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchCms({ key: 'delete' }));
  try {
    await CmsApi.deletePost(id);

    dispatch(
      fetchCmsSucceeded({
        key: 'delete',
        id,
      }),
    );
    onError();
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(
      fetchCmsFailed({
        key: 'delete',
        error,
      }),
    );
    onError(error);
    throw error;
  }
};
