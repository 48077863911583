import { IOrganization } from 'organization/entities';
import { IPerson } from 'person/entities';
import { IProduct } from 'shop/entities';

/**
 * Item availability
 *
 * @export
 * @enum {number}
 */
export enum ItemAvailability {
  inStock = 'inStock',
  outOfStock = 'outOfStock',
}

export interface IOrder<T = any> {
  orderStatus: OrderStatusEnum;
  price: number;
  priceTaxIncluded: number;
  numberOfCredit: number;
  id: string;
  uri: string;
  consumerId: string;
  customer: IPerson;
  organization: IOrganization;
  orderNumber: number;
  orderNumberOfDay: number;
  createdAt: string;
  orderedItems: IOrderedItem<T>[];
  acceptedOffer: IOffer[];
  type?: string;
  priceCommission?: number;
  priceTaxIncludedWithCommission?: number;
  meansOfPayment?: string;
  updatedAt?: string;
  payments?: string[];
  orderStatusHistory?: IOrderStatus[];
  refunds?: string[];
  priceAdvancePayment?: number;
  priceToPay?: number;
}

export interface IOrderStatus {
  createdAt: string;
  id: string;
  status: string;
  userUri: string;
  message: string;
}

export interface IOffer {
  availability?: ItemAvailability;
  price: number;
  taxRate: number;
  priceTaxIncluded: number;
  priceDiscount?: number;
}

export interface ISubscriptionLocation {
  id: string;
}

export interface IOrderedItem<T = any> {
  id?: string;
  productId: string;
  product: IProduct<T>;
  orderQuantity: number;
  price: number;
  taxRate: number;
  priceTaxIncluded: number;
  options: any[];
  metadatas?: any;
  schedules?: ISchedule[];
  priceDiscount?: number;
}

export interface IDisplayed {
  backgroundImg: string;
  highlightImg?: string;
  actualityContent: null;
  actualityTitle: null;
  city: string;
  id: string;
  isBarService: boolean;
  isOpen: boolean;
  latitude: string;
  longitude: string;
  name: string;
  position: string;
  type: string;
  weekTime: IWeekTime[];
  quotas?: any;
  logoImg: string;
}

export interface IWeekTime {
  day: string;
  closed: boolean;
  morningTime: string;
  nightTime: null;
}

export interface IOpeningHoursSpecification {
  id: string;
  dayOfWeek?: string;
  closes: string;
  opens: string;
}

export interface IServiceType {
  parent: null;
  childrens: any[];
  id: string;
  values: IValue[];
  uri: string;
  code: string;
  createdAt: string;
}

export interface ISchedule {
  allowedFromDate: string;
  allowedUntilDate: string;
}

export interface IValue {
  language: string;
  value: string;
  createdAt: string;
}

/**
 * Order status
 */
export enum OrderStatusEnum {
  BASKET = 0,
  BASKET_VALIDATED = 100,
  WAITING_PAYMENT = 200,
  PAYMENT_REFUSED = 300,
  PAYED = 400,
  WAITING_VALIDATION = 500,
  ORDER_REFUSED = 600,
  ORDER_ACCEPTED = 700,
  ORDER_IN_PROGRESS = 800,
  ORDER_READY = 900,
  FINISHED = 1000,
  PAY_OUT = 1050,
  REFUND = 1100,
  CONSUMER_CANCELLED = 9000,
  ORGANIZATION_CANCELLED = 9100,
}
