import axios, { AxiosRequestConfig } from 'axios';
import FormData from 'form-data';
import { IPicture } from 'gallery/entities';
import { IPerson } from 'person/entities';
import { IPlace } from 'place/entities/place.entity';
import { IView } from 'shared-entities/pagination.entity';
import { IRib } from 'shop/entities/rib.entity';

import { Addresses, IOrganization } from '../../entities/organization.entity';
import {
  IMedia,
  KycDocumentType,
} from '../../entities/organization.kyc.entity';
import { AddressType } from '../hooks/organization.address.hook';

export class OrganizationApi {
  public static ORGANIZATION_ENDPOINT = '/api/organizations';

  public static getOrganizations = (page = 1, itemsPerPage = 10, filter) =>
    axios.get<{ results: IOrganization[]; view: IView }>('/api/organizations', {
      params: {
        page,
        itemsPerPage,
        ...filter,
      },
    });

  public static postOrganizationCommunication = (
    organizationId: string,
    title: string,
    message: string,
    icon: string,
  ) => {
    return axios.post<IOrganization>(
      `/api/organizations/${organizationId}/communication`,
      {
        title,
        message,
        icon,
      },
    );
  };

  public static getOrganization = (organizationId: string) =>
    axios.get(`/api/organizations/${organizationId}`);

  public static addOrganization = (params: Partial<IOrganization>) =>
    axios.post(`/api/organizations`, params);

  public static addFounder = (
    organizationId: string,
    params: Partial<IPerson>,
  ) => axios.post(`/api/organizations/${organizationId}/founders`, params);

  public static updateFounder = (
    organizationId: string,
    founderId: string,
    params: Partial<IPerson>,
  ) =>
    axios.put(
      `/api/organizations/${organizationId}/founders/${founderId}`,
      params,
    );

  public static addOpeningHours = (
    organizationId: string,
    openingTime: {
      id: string;
      dayOfWeek: string;
      closes: Date | string;
      opens: Date | string;
    },
  ) =>
    axios.post(
      `/api/organizations/${organizationId}/opening-hours-specification/`,
      openingTime,
    );

  public static updateOrganization = (
    id: string,
    data: Partial<IOrganization>,
  ) => axios.put(`/api/organizations/${id}`, data);

  public static addOrganizationPicture = (
    id: string,
    params: { pictureId: string; picture: File },
  ) => {
    const formData = new FormData();
    formData.append('upload', params.picture);
    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return axios.post(
      `/api/organizations/${id}/pictures/${params.pictureId}`,
      formData,
      config,
    );
  };

  public static removeAddressFromOrganization = (
    organizationId: string,
    addressId: string,
  ) => {
    return axios.delete(
      `api/organizations/${organizationId}/addresses/${addressId}`,
    );
  };

  public static addAddressToOrganization = (
    organizationId: string,
    place: IPlace & { type: AddressType },
  ) => {
    return axios.post(`api/organizations/${organizationId}/addresses`, place);
  };

  public static addLogo = (
    organizationId: string,
    pictureId: string,
    picture: File,
    onProgress?: (progressEvent: any) => void,
  ) => {
    const formData = new FormData();
    formData.append('upload', picture);
    return axios.post(
      `/api/organizations/${organizationId}/logo/create/${pictureId}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: onProgress,
      },
    );
  };

  public static deleteLogo = (organizationId: string) => {
    return axios.delete(`api/organizations/${organizationId}/logo`);
  };

  public static addPictureWithExistingOne = (
    organizationId: string,
    picture: IPicture,
    pictureId: string,
  ) => {
    return axios.post(
      `/api/organizations/${organizationId}/pictures/${pictureId}/from-media`,
      picture,
    );
  };

  public static addBackgroundImageWithExistingOne = (
    organizationId: string,
    picture: IPicture,
    pictureId: string,
  ) => {
    return axios.post(
      `/api/organizations/${organizationId}/background-image/create/${pictureId}/from-media`,
      picture,
    );
  };

  public static removeOrganizationPicture = (id: string, mediaId: string) => {
    return axios.delete(`/api/organizations/${id}/pictures/${mediaId}`);
  };

  public static deleteFounder = (organizationId: string, founderId: string) =>
    axios.delete(`/api/organizations/${organizationId}/founders/${founderId}`);

  public static addRIB = (id: string, rib: IRib) =>
    axios.post(`/api/organizations/${id}/rib`, rib);

  public static getRIB = (organizationId: string) =>
    axios.get(`/api/organizations/${organizationId}/rib`);

  public static addKyc = (
    organizationId: string,
    params: {
      id: string;
      media: IMedia;
      type: KycDocumentType;
    },
  ) => axios.post(`/api/organizations/${organizationId}/kycs`, params);

  public static getKyc = (organizationId: string) =>
    axios.get(`/api/organizations/${organizationId}/kycs`);

  public static updateAddress = (
    organizationId: string,
    id: string,
    updateAddress: Partial<Addresses>,
  ) =>
    axios.put(
      `/api/organizations/${organizationId}/addresses/${id}`,
      updateAddress,
    );
}
