import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk, generateErrorHandlerAction, IAppState } from 'store';

import { UserApi } from '../api/user.api';
import {
  fetchChangePassword,
  fetchPasswordFailed,
  fetchPasswordSucceeded,
} from '../slices/user.slice';

export const changePasswordRequest = (
  oldPassword: string,
  password: string,
  passwordRepeat: string,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  let response: AxiosResponse<boolean> | null = null;
  dispatch(fetchChangePassword());
  try {
    const user = getState().person.me;
    if (user && user.id != null) {
      response = await UserApi.postChangePassword(
        oldPassword,
        password,
        passwordRepeat,
      );
    } else {
      dispatch(
        fetchPasswordFailed({
          isAxiosError: false,
          message: 'User id does not exists',
          name: 'Format Error',
          stack: '',
        }),
      );
      return;
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(error as AxiosError<any>, fetchPasswordFailed),
    );
    onError(error);
    return;
  }

  if (response && response.data) {
    // - Dispatch succeeded
    dispatch(fetchPasswordSucceeded());
    onError(undefined);
    return;
  }

  dispatch(
    fetchPasswordFailed({
      isAxiosError: false,
      message: 'User id does not exists',
      name: 'Format Error',
      stack: '',
    }),
  );
  onError(undefined);
};
