import { IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConfigCode, IConfig } from '../../entities/config.entity';

const initialState: {
  loading: boolean;
  config: { [key in ConfigCode]?: IConfig };
  error: IPayloadError;
  publicConfig: { [key: string]: any };
} = {
  loading: false,
  config: undefined,
  error: undefined,
  publicConfig: undefined,
};

export type ConfigState = typeof initialState;
export interface IAppStateConfig {
  config: ConfigState;
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    fetchConfig: (state: ConfigState) => {
      state.error = undefined;
      state.loading = true;
    },
    fetchConfigSucceeded: (
      state: ConfigState,
      action: PayloadAction<{
        key?: string;
        data?: { [key in ConfigCode]?: IConfig };
      }>,
    ) => {
      state.loading = false;
      if (action.payload.key === 'public') {
        state.publicConfig = { ...state.publicConfig, ...action.payload.data };
      } else {
        state.config = { ...state.config, ...action.payload.data };
      }
    },
    fetchConfigFailed: (
      state: ConfigState,
      { payload }: PayloadAction<IPayloadError>,
    ) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  fetchConfig,
  fetchConfigSucceeded,
  fetchConfigFailed,
} = configSlice.actions;

export const { reducer: configReducer } = configSlice;
