import { ICategoryNested, ICategoryNestedResponse } from 'shop';

export const categoryAdapter = (
  categories: ICategoryNestedResponse[],
): { [key: string]: ICategoryNested } => {
  const nestedCategories: { [key: string]: ICategoryNested } = Object.values(
    categories,
  ).reduce((previousValue: { [key: string]: ICategoryNested }, category) => {
    const { subCategories, ...rest } = category;
    let subCategoriesNormalized = {};

    if (subCategories && subCategories.length > 0) {
      const flattenCategories = (categs: ICategoryNestedResponse[]) => {
        // Get object with category id as key
        const flatCategories: {
          [key: string]: ICategoryNested;
        } = categs.reduce((prev, cat) => {
          let normalizedSubCategs = {};
          if (cat.subCategories && cat.subCategories.length > 0) {
            normalizedSubCategs = flattenCategories(
              cat.subCategories as ICategoryNestedResponse[],
            );
          }
          prev[cat.id] = { ...cat, subCategories: normalizedSubCategs };
          return prev;
        }, {});

        // Add categories to root object
        previousValue = { ...previousValue, ...flatCategories };

        return flatCategories;
      };

      // Get normalized subcategories, recursively
      subCategoriesNormalized = flattenCategories(
        subCategories as ICategoryNestedResponse[],
      );
    }

    previousValue[category.id] = {
      ...rest,
      subCategories: subCategoriesNormalized,
    };

    return previousValue;
  }, {});

  return nestedCategories;
};
