import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { UserApi } from '../api/user.api';
import {
  fetchPasswordResetRequest,
  fetchPasswordResetRequestFailed,
  fetchPasswordResetRequestSucceeded,
} from '../slices/user.slice';

export const postPasswordResetRequest = (
  email: string,
  callback: (error?: { message: string; statusCode: string }) => void,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchPasswordResetRequest());

  let response: AxiosResponse<string> | null = null;

  try {
    response = await UserApi.postPasswordResetRequest(email);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (!responseError?.data) {
      dispatch(
        fetchPasswordResetRequestFailed({
          isAxiosError,
          message: 'Failed',
          name,
          code: '404',
          stack,
        }),
      );

      callback({ message: 'failed', statusCode: '' });
      return;
    }
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchPasswordResetRequestFailed({
        isAxiosError,
        message,
        name,
        code: statusCode,
        stack,
      }),
    );
    callback({ message, statusCode });
    return;
  }
  if (response && response.data && response.data === 'ok') {
    // - Dispatch succeeded, set users
    dispatch(fetchPasswordResetRequestSucceeded());

    callback();
    return;
  }
  callback({ message: 'failed', statusCode: '' });
};
