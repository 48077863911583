import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import AuthApi from '../api/auth.api';
import {
  fetchLogout,
  fetchValidateCredential,
  fetchValidateCredentialFailed,
  fetchValidateCredentialSucceeded,
} from '../slices/auth.slice';

export const validateCredential = (
  username: string,
  password: string,
  callback: (error?: { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchValidateCredential());

  let response: AxiosResponse<{
    access_token: string;
  }> | null = null;

  try {
    response = await AuthApi.postRequestLoginCredential(username, password);
  } catch (err) {
    const { response: responseError } = err;
    if (!responseError?.data) {
      dispatch(fetchValidateCredentialFailed(err));
      callback({ message: 'failed', statusCode: '' });
      return;
    }
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(fetchValidateCredentialFailed(err));
    callback({ message, statusCode });
    return;
  }

  if (response && response.data && response.data.access_token) {
    // - Dispatch succeeded, set users
    dispatch(
      fetchValidateCredentialSucceeded({
        token: response.data.access_token,
      }),
    );
    localStorage.setItem('token', response.data.access_token);
    callback();
    return;
  }
  callback({ message: 'failed', statusCode: '' });
};

export const logoutThunk = (
  callback: (error?: { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchLogout());

  await AuthApi.logout();
  callback();
};
