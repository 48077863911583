import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { ProductApi } from '../api/product.api';
import {
  fetchBasket,
  fetchBasketFailed,
  fetchBasketSucceeded,
} from '../slices/basket.slice';

export const addBasketThunk = (params: {
  [key: string]: any;
}): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse = null;
  dispatch(fetchBasket());
  try {
    response = await ProductApi.addBasket(params);
    dispatch(fetchBasketSucceeded(response.data));
    return response.data;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(fetchBasketFailed(error));
    throw error;
  }
};
