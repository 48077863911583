import { AxiosResponse } from 'axios';
import { ShopMeansOfPaymentEnum } from 'config';
import { IOrder } from 'order';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { BasketApi } from '../api/basket.api';
import {
  fetchBasket,
  fetchBasketFailed,
  fetchBasketSucceeded,
} from '../slices/basket.slice';

export const validateAndPayThunk = (
  meansOfPayment: ShopMeansOfPaymentEnum,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrder> = null;

  dispatch(fetchBasket());
  try {
    response = await BasketApi.validateAndPay(meansOfPayment);

    if (response && response?.data) {
      dispatch(fetchBasketSucceeded(response.data));
      return response.data;
    }
    return;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(fetchBasketFailed({ ...error }));
    throw error;
  }
};
