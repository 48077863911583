import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { PaymentApi } from '../api/payment.api';
import {
  fetchConfigPayment,
  fetchConfigPaymentSuccedded,
  fetchPaymentFailed,
} from '../slices/payment.slice';

export const postCredentialsCardThunk = (
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse = null;

  dispatch(fetchConfigPayment());
  try {
    response = await PaymentApi.requestCredentials();

    if (response && response?.data) {
      dispatch(
        fetchConfigPaymentSuccedded({
          data: response.data,
        }),
      );
      onError(undefined);
    }
    return;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(fetchPaymentFailed({ ...error }));
    onError(error);
    throw error;
  }
};
