import { IAverage } from '../../entities/opinions.entity';
import { OpinionsApi } from '../api/opinions.api';
import {
  fetchOpinionsFailed,
  fetchOpinionsRequest,
  fetchOpinionsSucceeded,
} from '../slices/opinions.slice';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk, IAppState } from 'store';

export const getAverageThunk = (params?: {
  [key: string]: string | number;
}): AppThunk<Promise<void>> => async (
  dispatch: Dispatch<any>,
  getState: () => IAppState,
) => {
  let response: AxiosResponse<IAverage> = null;
  dispatch(fetchOpinionsRequest({ key: 'list' }));
  try {
    response = await OpinionsApi.getAverage(params);

    dispatch(
      fetchOpinionsSucceeded({
        average: response.data,
      }),
    );
  } catch (err) {
    const { name, response: responseError, isAxiosError, stack } = err;
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
    };
    // - Fetch failed, set error
    dispatch(
      fetchOpinionsFailed({
        key: 'list',
        error,
      }),
    );
    throw error;
  }
};
