import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { AppThunk } from 'store';

import { IPerson } from '../../entities';
import { PersonApi } from '../api/person.api';
import {
  fetchAddPicturePerson,
  fetchAddPicturePersonFailed,
  fetchAddPicturePersonSucceeded,
} from '../slices/person.slice';

export const addUserPictureRequest = (
  idUser: string,
  idMedia: string,
  media: File,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IPerson> | null = null;
  dispatch(fetchAddPicturePerson());

  try {
    response = await PersonApi.postUserPicture(idUser, idMedia, media);
  } catch (error) {
    dispatch(
      fetchAddPicturePersonFailed({
        isAxiosError: true,
        message: 'Error on adding picture',
        name: '',
        stack: '',
      }),
    );
    onError(error);
  }
  if (response && response?.data && response.data) {
    // - Dispatch succeeded, set Organization
    dispatch(fetchAddPicturePersonSucceeded(response.data));
    onError(undefined);
  }
};
