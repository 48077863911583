import { Dispatch } from 'react';
import { AppThunk } from 'store';

import { GalleryApi } from '../api/gallery.api';
import {
  fetchDeleteGalleryItem,
  fetchDeleteSucceeded,
  fetchGalleryFailed,
} from '../slices/gallery.slice';

export const deleteGalleryThunk = (
  galleryItemId: string,
  categoryName: string,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchDeleteGalleryItem());
  try {
    await GalleryApi.deleteGalleryItem(galleryItemId);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    dispatch(fetchGalleryFailed({ error, key: 'delete' }));
    throw err;
  }
  dispatch(
    fetchDeleteSucceeded({ category: categoryName, itemId: galleryItemId }),
  );
};
