import axios from 'axios';

import { ICategory } from '../../entities/category.type';
import { IProduct } from '../../entities/product.entity';

export class ProductApi {
  public static PUBLIC_CATEGORY_ENDPOINT = '/api/public/shop/products';
  public static PUBLIC_PRODUCT_ENDPOINT = '/api/public/shop/products';

  public static CATEGORY_ENDPOINT = '/api/shop/products/categories';
  public static OPTION_ENDPOINT = '/api/shop/products';
  public static PRODUCT_ENDPOINT = '/api/shop/products';
  public static BASKET_ENDPOINT = '/api/shop/baskets';

  public static createCategoryUri = (id: string) =>
    `${ProductApi.CATEGORY_ENDPOINT}/${id}`;

  public static getCategoryIdFromCategoryUri = (uri: string) =>
    uri.replace(`${ProductApi.CATEGORY_ENDPOINT}/`, '');

  public static getCategories = (parentId?: string, filters: object = {}) =>
    axios.get(
      `${ProductApi.CATEGORY_ENDPOINT}${parentId ? `/${parentId}` : ''}`,
      { params: filters },
    );

  public static getCategoriesAll = (
    organizationId?: string,
    filters?: { [k: string]: any },
  ) =>
    axios.get(`${ProductApi.CATEGORY_ENDPOINT}/all`, {
      params: { ...filters, organizationId },
    });

  public static getPublicCategoriesAll = (filters?: { [k: string]: any }) =>
    axios.get(
      `${ProductApi.PUBLIC_CATEGORY_ENDPOINT}/categories-and-subcategories`,
      { params: filters },
    );

  public static addCategory = (data: Partial<ICategory>, parentId?: string) =>
    axios.post(
      `${ProductApi.CATEGORY_ENDPOINT}${parentId ? `/${parentId}` : ''}`,
      data,
    );

  public static updateCategory = (id: string, data: Partial<ICategory>) =>
    axios.put(`${ProductApi.CATEGORY_ENDPOINT}/${id}`, data);

  public static deleteCategory = (id: string) =>
    axios.delete(`${ProductApi.CATEGORY_ENDPOINT}/${id}`);

  public static addOption = (productId: string, data: Partial<ICategory>) =>
    axios.post(`${ProductApi.OPTION_ENDPOINT}/${productId}/options`, data);

  public static deleteOption = (optionId: string, productId: string) =>
    axios.delete(
      `${ProductApi.OPTION_ENDPOINT}/${productId}/options/${optionId}`,
    );

  public static getPublicProducts = (
    page = 1,
    itemsPerPage = 10,
    filters: {},
  ) =>
    axios.get(`${ProductApi.PUBLIC_PRODUCT_ENDPOINT}`, {
      params: {
        page,
        itemsPerPage,
        ...filters,
      },
    });

  public static getParentProducts = (q: string) =>
    axios.get(`${ProductApi.PRODUCT_ENDPOINT}/find-in-parent`, {
      params: { q },
    });

  public static calculatePrice = (price: number, planUri: string) =>
    axios.post<{
      amount: number;
    }>(`${ProductApi.PRODUCT_ENDPOINT}/calculate-price`, { price, planUri });

  public static getProduct = (id: string) =>
    axios.get(`${ProductApi.PRODUCT_ENDPOINT}/${id}`);

  public static updateProduct = (id: string, data: Partial<IProduct>) =>
    axios.put(`${ProductApi.PRODUCT_ENDPOINT}/${id}`, data);

  public static addProduct = (data: Partial<IProduct>) =>
    axios.post(`${ProductApi.PRODUCT_ENDPOINT}`, data);

  public static deleteProduct = (id: string) =>
    axios.delete(`${ProductApi.PRODUCT_ENDPOINT}/${id}`);

  public static setEnableProduct = (id: string) => {
    return axios.post<IProduct>(`${ProductApi.PRODUCT_ENDPOINT}/${id}/enable`);
  };
  public static setDisableProduct = (id: string) => {
    return axios.post<IProduct>(`${ProductApi.PRODUCT_ENDPOINT}/${id}/disable`);
  };

  public static addBasket = (params: { [key: string]: any }) => {
    return axios.post<IProduct>(`${ProductApi.BASKET_ENDPOINT}/add`, {
      ...params,
    });
  };

  public static clearBasket = async () => {
    return axios.post<IProduct>(`${ProductApi.BASKET_ENDPOINT}/clear`);
  };

  public static getProducts = (params: { [key: string]: string | number }) => {
    return axios.get(`${ProductApi.PRODUCT_ENDPOINT}`, { params: params });
  };
}
