import { IOrganization } from 'organization/entities';
import { IPerson } from 'person/entities';

import { TraceEnum } from '../redux/trace.enum';

export class Trace {
  public id: string;
  public consumerId?: string;
  public action: TraceEnum;
  public user: IPerson;
  public value: any;
  public organization?: IOrganization;
  public year?: number;
  public month?: number;
  public day?: number;
  public objectUri: string;
  public latitude?: number;
  public longitude?: number;
  public geopoint?: string;
  public createdAt?: Date;
}
