import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk, generateErrorHandlerAction } from 'store';

import { PersonApi } from '../api/person.api';
import {
  fetchChangePassword,
  fetchChangePasswordFailed,
  fetchChangePasswordSucceeded,
  State,
} from '../slices/person.slice';

export const changePasswordRequest = (
  oldPassword: string,
  password: string,
  passwordRepeat: string,
  onError: (err?: AxiosError) => void,
): AppThunk => async (
  dispatch: Dispatch<any>,
  getState: () => { person: State },
) => {
  let response: AxiosResponse<boolean> | null = null;
  dispatch(fetchChangePassword());
  try {
    const user = getState().person.me;
    if (user && user.id != null) {
      response = await PersonApi.changePassword(
        oldPassword,
        password,
        passwordRepeat,
      );
    } else {
      dispatch(
        fetchChangePasswordFailed({
          isAxiosError: false,
          message: 'User id does not exists',
          name: 'Format Error',
          stack: '',
        }),
      );
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchChangePasswordFailed,
      ),
    );
    onError(error);
  }

  if (response && response.data) {
    // - Dispatch succeeded
    dispatch(fetchChangePasswordSucceeded());
    onError(undefined);
  }

  dispatch(
    fetchChangePasswordFailed({
      isAxiosError: false,
      message: 'User id does not exists',
      name: 'Format Error',
      stack: '',
    }),
  );
  onError(undefined);
};
