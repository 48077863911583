import { AxiosResponse } from 'axios';
import { IAddEventCalendarPayload, ICalendarEvent } from 'calendar/entities';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { CalendarApi } from '../api/calendar.api';
import {
  fetchEvents,
  fetchEventsFailed,
  fetchEventsSucceeded,
} from '../slices/calendar.slice';

export const updateEventThunk = <T>(
  calendarId: string,
  eventId: string,
  params: Partial<IAddEventCalendarPayload>,
): AppThunk<Promise<{ [key: string]: ICalendarEvent<T> }>> => async (
  dispatch: Dispatch<any>,
) => {
  let response: AxiosResponse<ICalendarEvent<T>> = null;
  dispatch(fetchEvents({ key: eventId }));

  try {
    response = await CalendarApi.updateEvent(calendarId, eventId, params);
    const event = { [response.data.id]: response.data };
    dispatch(
      fetchEventsSucceeded({
        events: event,
        key: eventId,
        id: eventId,
      }),
    );

    return event;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: null,
      toJSON: null,
    };

    dispatch(
      fetchEventsFailed({
        key: eventId,
        error,
      }),
    );

    throw error;
  }
};
