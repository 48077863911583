import { AxiosResponse } from 'axios';
import { TFunction } from 'i18next';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { BasketApi } from '../api/basket.api';
import {
  fetchBasket,
  fetchSponsorCodeToBasketFailed,
  fetchSponsorCodeToBasketSuccedded,
} from '../slices/basket.slice';

export const addSponsorCodeToBasketThunk = (
  sponsorCode: string,
  t: TFunction,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<any> | null = null;

  dispatch(fetchBasket());

  try {
    response = await BasketApi.addSponsorCodeToBasket(sponsorCode);
  } catch (error) {
    dispatch(
      fetchSponsorCodeToBasketFailed({
        ...error,
        message: error?.response?.data?.message ?? 'An error occured',
      }),
    );
    throw new Error(t('order-payment.form.errors.sponsorCode-failed'));
  }
  if (response && response.data) {
    dispatch(fetchSponsorCodeToBasketSuccedded(response.data));
    return;
  }
};
