import memoize from 'lodash.memoize';
import { IView } from 'shared-entities/pagination.entity';

import { createSelector } from '@reduxjs/toolkit';

import { IPost } from '../../entities/post.entity';
import { IAppStateCms } from './cms.slice';

export const cmsSelector = (state: IAppStateCms) => state.cms.list;

export const cmsArraySelector = createSelector(cmsSelector, (CmsApi) => {
  return Object.values(CmsApi);
});

export const pagesArraySelector = createSelector(
  cmsSelector,
  (pages: { [key: string]: IPost }) => {
    return Object.values(pages).filter((cms) => cms.type === 'page');
  },
);

export const postsArrayByTypeSelector = (type: string) =>
  createSelector(cmsSelector, (posts: { [key: string]: IPost }) => {
    return Object.values(posts).filter((cms) => cms.type === type);
  });

export const postsByTagsSelector = (tags: string) =>
  createSelector(cmsSelector, (posts: { [key: string]: IPost }) => {
    const postsByTag = Object.values(posts).filter(
      (post) =>
        post.metadatas &&
        post.metadatas.tags &&
        post.metadatas.tags[0] === tags,
    );

    return postsByTag;
  });

export const loadingSelector = (state: IAppStateCms, key = 'list') =>
  state.cms.loading[key];

export const errorSelector = (state: IAppStateCms, key = 'list') =>
  state.cms.error[key];

export const makeOpinionSelector = createSelector([cmsSelector], (cms) =>
  memoize((id: string) => cms[id]),
);

export const getPageById = (id: string) =>
  createSelector(cmsSelector, (pages: { [key: string]: IPost }) => {
    return Object.values(pages).find((cms) => cms.id === id);
  });

export const getPostById = (id: string) =>
  createSelector(cmsSelector, (pages: { [key: string]: IPost }) => {
    return Object.values(pages).find((cms) => cms.id === id);
  });

export const viewSelector = (state: IAppStateCms) => state.cms.view;
export const getViewByTypeSelector = (type: string) =>
  createSelector(viewSelector, (view: { [key: string]: IView }) =>
    view && view[type] ? view[type] : undefined,
  );
