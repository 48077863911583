import { ICommunicationNotifications } from '../../entities/communication.notification.entity';
import { NotificationsApi } from '../api/notification.api';
import {
  fetchNotificationFailed,
  fetchNotifications,
  fetchNotificationSucceeded,
} from '../slice/notifications.slice';

import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { Dispatch } from 'redux';
import { AppThunk, EventsStatusListener } from 'store';

export const enableToOrganizationThunk = (
  id: string,
  organizationUri: string,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<ICommunicationNotifications> = null;
  dispatch(fetchNotifications({ key: id }));
  try {
    response = await NotificationsApi.enableToOrganization(id, {
      organizationUri,
    });

    dispatch(
      fetchNotificationSucceeded({
        data: { [id]: response.data },
        key: id,
      }),
    );
    EventsStatusListener.getInstance().publishSuccessMessage(
      i18next.t('common.notification.update-success'),
    );
    return response.data;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };

    EventsStatusListener.getInstance().publishErrorMessage(
      i18next.t('common.notification.update-failed', { message: err.message }),
    );

    dispatch(
      fetchNotificationFailed({
        key: id,
        error,
      }),
    );

    throw error;
  }
};
