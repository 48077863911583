import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { ProductApi } from '../api/product.api';
import {
  fetchDeleteProduct,
  fetchDeleteProductFailed,
  fetchDeleteProductSucceeded,
} from '../slices/product.slice';

export const deleteProductRequest = (
  id: string,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchDeleteProduct());

  try {
    await ProductApi.deleteProduct(id);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchDeleteProductFailed({
        isAxiosError,
        message,
        name,
        code: statusCode,
        stack,
      }),
    );
    onError(err);
    return;
  }

  dispatch(fetchDeleteProductSucceeded(id));
  onError(undefined);
  return;
};
