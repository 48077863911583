import { AxiosError, AxiosResponse } from 'axios';
import { MediaApi } from 'media/redux';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { CmsApi } from '../api/cms.api';
import {
  fetchCms,
  fetchCmsFailed,
  fetchCmsSucceeded,
} from '../slices/cms.slice';

export const addMediaToArticleThunk = (
  type: string,
  postId: string,
  data: {
    id: string;
    media: File;
  },
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse = null;

  dispatch(fetchCms({ key: 'addMedia' }));
  try {
    const responseAddMedia = await MediaApi.addMedia(
      data.id,
      'cms',
      data.media,
      type,
    );

    if (responseAddMedia.data && responseAddMedia.data.id) {
      response = await CmsApi.addMedia(postId, responseAddMedia.data);
    }

    dispatch(
      fetchCmsSucceeded({
        list: { [postId]: response.data },
        key: 'addMedia',
      }),
    );
    onError();
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(
      fetchCmsFailed({
        key: 'addMedia',
        error,
      }),
    );
    onError(error);
    throw error;
  }
};
