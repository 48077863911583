import { IOrganization } from 'organization/entities';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeOrganizationsSortedByDateSelector,
  organizationLoadingSelector,
  organizationsErrorSelector,
  organizationsListArraySelector,
  organizationsLoadingSelector,
  organizationsViewSelector,
} from '../slices/organization.slice';
import {
  getOrganizationsRequest,
  getSearchOrganizationsRequest,
} from '../thunks/organization.thunk';

export const useOrganizations = <T>() => {
  const dispatch = useDispatch();

  const view = useSelector(organizationsViewSelector);

  const loading = useSelector(organizationLoadingSelector);

  const organizationsLoader = useSelector(organizationsLoadingSelector);

  const error = useSelector(organizationsErrorSelector);

  const organizations: IOrganization<T>[] = useSelector(
    organizationsListArraySelector,
  );

  const getOrganizations = (page = 1, itemsPerPage = 10, params = {}) => {
    return dispatch(getOrganizationsRequest(page, itemsPerPage, params));
  };

  const getSearchOrganizations = (page = 1, itemsPerPage = 10, params = {}) => {
    return dispatch(getSearchOrganizationsRequest(page, itemsPerPage, params));
  };

  const organizationsSortedByDateSelector = useSelector(
    makeOrganizationsSortedByDateSelector,
  );

  return {
    organizationsSortedByDateSelector,
    getSearchOrganizations,
    getOrganizations,
    loading,
    error,
    organizations,
    organizationsLoader,
    offset: 10,
    ...view,
  };
};
