import axios from 'axios';

import { IDiscount } from '../../entities/discount.entity';

export class DiscountApi {
  public static PUBLIC_DISCOUNT_ENDPOINT = '/api/shop/discounts';

  public static getDiscounts = (filter: object = {}) => {
    return axios.get(DiscountApi.PUBLIC_DISCOUNT_ENDPOINT, {
      params: { ...filter },
    });
  };

  public static addDiscount = (data: Partial<IDiscount>) => {
    return axios.post(DiscountApi.PUBLIC_DISCOUNT_ENDPOINT, data);
  };

  public static updateDiscount = (
    idDiscount: string,
    data: Partial<IDiscount>,
  ) => {
    return axios.put(
      `${DiscountApi.PUBLIC_DISCOUNT_ENDPOINT}/${idDiscount}`,
      data,
    );
  };
  public static deleteDiscount = (idDiscount: string) => {
    return axios.delete(
      `${DiscountApi.PUBLIC_DISCOUNT_ENDPOINT}/${idDiscount}`,
    );
  };

  public static getDiscountById = (idDiscount: string) => {
    return axios.get<Partial<IDiscount>>(
      `${DiscountApi.PUBLIC_DISCOUNT_ENDPOINT}/${idDiscount}`,
    );
  };
}
