import axios from 'axios';

import { ICommunicationNotifications } from '../../entities/communication.notification.entity';
import { ISendEmail, ISendSMS } from '../../entities/notification.entity';

export class NotificationsApi {
  public static NOTIFICATIONS_ENDPOINT = '/api/notifications';

  public static getNotifications = (filters: object = {}) =>
    axios.get(`${NotificationsApi.NOTIFICATIONS_ENDPOINT}`, {
      params: filters,
    });

  public static updateNotification = async (
    id: string,
    data: Partial<ICommunicationNotifications>,
  ) => axios.put(`${NotificationsApi.NOTIFICATIONS_ENDPOINT}/${id}`, data);

  public static enableToOrganization = async (
    id: string,
    data: { organizationUri: string },
  ) =>
    axios.post(
      `${NotificationsApi.NOTIFICATIONS_ENDPOINT}/${id}/enable-to-organization`,
      data,
    );

  public static disableToOrganization = async (
    id: string,
    data: { organizationUri: string },
  ) =>
    axios.post(
      `${NotificationsApi.NOTIFICATIONS_ENDPOINT}/${id}/disable-to-organization`,
      data,
    );

  public static sendEmail = async (data: ISendEmail) =>
    axios.post(`/api/notification/email`, data);

  public static sendSMS = async (data: ISendSMS) =>
    axios.post(`/api/notification/sms`, data);
}
