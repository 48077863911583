import { AxiosError } from 'axios';
import { ICommunication } from 'communication';
import { IView } from 'shared-entities/pagination.entity';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  error: undefined as undefined | AxiosError,
  list: {} as { [key: string]: ICommunication },
  view: {} as IView,
};

export type State = typeof initialState;
export const communicationSlice = createSlice({
  initialState,
  name: 'communication',
  reducers: {
    fetchCommunication: (state: State) => {
      state.error = undefined;
      state.loading = true;
    },
    fetchCommunicationSucceeded: (
      state: State,
      action: PayloadAction<{
        list: { [key: string]: ICommunication };
        view?: IView;
      }>,
    ) => {
      state.list = action.payload.list;
      state.loading = false;
      if (action.payload.view) {
        state.view = action.payload.view;
      }
    },
    fetchCommunicationFailed: (
      state: State,
      action: PayloadAction<AxiosError>,
    ) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchCommunication,
  fetchCommunicationFailed,
  fetchCommunicationSucceeded,
} = communicationSlice.actions;

export const reducer = communicationSlice.reducer;
