import { useDispatch } from 'react-redux';

import { fetchLogout } from '../slices/auth.slice';
import { logoutThunk } from '../thunks/auth.credential.thunk';

export const useAuthLogout = () => {
  const dispatch = useDispatch();
  const clearToken = () => dispatch(fetchLogout());

  const logout = () =>
    new Promise((resolve, reject) => {
      dispatch(
        logoutThunk((err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  return { logout, clearToken };
};
