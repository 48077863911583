import { IPicture } from 'gallery';
import { IPerson } from 'person';

export interface IPost<T = any> {
  id: string;
  title: string;
  content?: string;
  resume?: string;
  consumerId: string;
  authorUri: string;
  createdAt: string;
  uri: string;
  slug: string;
  status: IStatus;
  type: string;
  author: IPerson;
  beginDate?: string;
  endDate?: string;
  metadatas?: T;
  media?: IPicture;
  updatedAt?: string;
  tags?: string[];
}

export enum IStatus {
  Published = 'published',
}
