import { ISendEmail } from '../../entities/notification.entity';
import { NotificationsApi } from '../api/notification.api';
import {
  notificationsErrorSelector,
  notificationsLoadingSelector,
  notificationsSelector,
} from '../slice/notifications.slice';
import { disableToOrganizationThunk } from '../thunk/disable-to-organization.thunk';
import { enableToOrganizationThunk } from '../thunk/enable-to-organization.thunk';
import { getNotificationsRequest } from '../thunk/get-notification.thunk';
import { updateNotificationRequest } from '../thunk/update-notification.thunk';

import { ICommunicationNotifications } from 'notification/entities/communication.notification.entity';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventsStatusListener, IAppState, ReduxDispatch } from 'store';

export const useNotifications = (key: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ReduxDispatch>();

  const getNotifications = (filter?) => {
    dispatch(getNotificationsRequest(filter));
  };

  const updateNotification = (
    notificationId: string,
    data: Partial<ICommunicationNotifications>,
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateNotificationRequest(notificationId, data, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  const enableToOrganization = async (id: string, organizationUri: string) =>
    dispatch(enableToOrganizationThunk(id, organizationUri));

  const disableToOrganization = async (id: string, organizationUri: string) =>
    dispatch(disableToOrganizationThunk(id, organizationUri));

  const sendEmail = async (data: ISendEmail) => {
    try {
      await NotificationsApi.sendEmail(data);
      EventsStatusListener.getInstance().publishSuccessMessage(
        t('communication.notification.send-success'),
      );
    } catch (err) {
      EventsStatusListener.getInstance().publishErrorMessage(
        t('common.notification.update-failed'),
      );
    }
  };

  const sendSMS = async (text: string) => {
    try {
      await NotificationsApi.sendSMS({ text });
      EventsStatusListener.getInstance().publishSuccessMessage(
        t('communication.notification.send-success'),
      );
    } catch (err) {
      EventsStatusListener.getInstance().publishErrorMessage(
        t('common.notification.update-failed'),
      );
    }
  };

  const notifications = useSelector(notificationsSelector);
  const notificationsLoading = useSelector((state: IAppState) =>
    notificationsLoadingSelector(state, key),
  );
  const notificationsError = useSelector((state: IAppState) =>
    notificationsErrorSelector(state, key),
  );

  return {
    sendEmail,
    sendSMS,
    enableToOrganization,
    disableToOrganization,
    notifications,
    getNotifications,
    notificationsLoading,
    notificationsError,
    updateNotification,
  };
};
