import { IOrder } from '../../entities/order.entity';
import { OrderApi } from '../api/order.api';
import {
  fetchOrders,
  fetchOrdersFailed,
  fetchOrdersSucceeded,
} from '../slices/order.slice';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { IPagination } from 'shared-entities/pagination.entity';
import { AppThunk } from 'store';

export interface ISort {
  field?: string;
  order?: string;
}

export const getOrdersRequest = (
  page = 1,
  ITEMS_PER_PAGE = 1000,
  sort: ISort = { field: 'createdAt', order: 'desc' },
  filters: any = {},
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchOrders());

  let response: AxiosResponse<IPagination<IOrder>> | null = null;

  try {
    const query = {
      ...filters,
      'sort[field]': sort.field,
      'sort[order]': sort.order,
    };
    response = await OrderApi.getOrders(page, ITEMS_PER_PAGE, query);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (responseError) {
      const {
        data: { statusCode, message },
      } = responseError;
      dispatch(
        fetchOrdersFailed({
          key: 'list',
          error: { isAxiosError, message, name, code: statusCode, stack },
        }),
      );
      return;
    }
    dispatch(
      fetchOrdersFailed({
        key: 'list',
        error: { isAxiosError, message: 'Not found', name, code: '404', stack },
      }),
    );
    return;
  }
  if (response && response.data) {
    const orders: IOrder[] = response.data.results.map((order) => {
      return order;
    });
    const orderObj = orders.reduce((previousValue, currentValue) => {
      previousValue[currentValue.id] = currentValue;
      return previousValue;
    }, {});
    dispatch(
      fetchOrdersSucceeded({
        key: 'list',
        data: orderObj,
        view: response?.data?.view,
        total: response?.data?.view?.total,
      }),
    );
  }
};

export const getOrdersByOrganizationRequest = (
  organizationId: string,
  page = 1,
  ITEMS_PER_PAGE = 15,
  sort: ISort = { field: 'createdAt', order: 'desc' },
  filters: any = {},
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchOrders());

  let response: AxiosResponse<IPagination<IOrder>> | null = null;

  try {
    const query = {
      ...filters,
      'sort[field]': sort.field,
      'sort[order]': sort.order,
    };
    response = await OrderApi.getOrdersByOrganization(
      organizationId,
      page,
      ITEMS_PER_PAGE,
      query,
    );
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (responseError) {
      const {
        data: { statusCode, message },
      } = responseError;
      dispatch(
        fetchOrdersFailed({
          key: 'list',
          error: { isAxiosError, message, name, code: statusCode, stack },
        }),
      );
      return;
    }
    dispatch(
      fetchOrdersFailed({
        key: 'list',
        error: { isAxiosError, message: 'Not found', name, code: '404', stack },
      }),
    );
    return;
  }
  if (response && response.data) {
    const orders: IOrder[] = response.data.results.map((order) => {
      return order;
    });
    const orderObj = orders.reduce((previousValue, currentValue) => {
      previousValue[currentValue.id] = currentValue;
      return previousValue;
    }, {});
    dispatch(
      fetchOrdersSucceeded({
        key: 'list',
        data: orderObj,
        view: response?.data?.view,
      }),
    );
  }
};
