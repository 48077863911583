import axios from 'axios';
import { IPerson } from 'person';

export class EmployeeApi {
  public static ORGANIZATION_ENDPOINT = '/api/organizations';

  public static addEmployee = (
    organizationId: string,
    params: Partial<IPerson>,
  ) => axios.post(`/api/organizations/${organizationId}/employees`, params);

  public static updateEmployee = (
    organizationId: string,
    accountId: string,
    data: Partial<IPerson>,
  ) =>
    axios.put(
      `/api/organizations/${organizationId}/employees/${accountId}`,
      data,
    );

  public static deleteEmployee = (organizationId: string, employeeId: string) =>
    axios.delete(
      `/api/organizations/${organizationId}/employees/${employeeId}`,
    );
}
