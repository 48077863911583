import { IPerson } from 'person';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { employeeLoadingSelector } from '../slices/employee.slice';
import {
  addEmployeeRequest,
  deleteEmployeeRequest,
  updateEmployeeRequest,
} from '../thunks/employee.thunk';

export const useEmployee = <T>() => {
  const dispatch = useDispatch();

  const addEmployee = async (
    organizationId: string,
    employee: Partial<IPerson<T>>,
  ) =>
    new Promise((resolve, reject) => {
      const normalizedOrganizationEmployee = {
        id: uuidv4(),
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        birthDate: employee.birthDate,
      };
      dispatch(
        addEmployeeRequest(
          organizationId,
          normalizedOrganizationEmployee,
          (err) => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          },
        ),
      );
    });

  const updateEmployee = async (
    organizationId: string,
    accountId: string,
    employee: Partial<IPerson<T>>,
  ) =>
    new Promise((resolve, reject) => {
      const normalizedOrganizationEmployee = {
        id: employee.id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        birthDate: employee.birthDate,
      };
      dispatch(
        updateEmployeeRequest(
          organizationId,
          accountId,
          normalizedOrganizationEmployee,
          (err) => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          },
        ),
      );
    });

  const deleteEmployee = async (organizationId: string, employeeId: string) => {
    return new Promise((resolve, reject) => {
      dispatch(
        deleteEmployeeRequest(organizationId, employeeId, (err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const loading = useSelector(employeeLoadingSelector);

  return { addEmployee, updateEmployee, deleteEmployee, loading };
};
