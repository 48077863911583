import { useDispatch, useSelector } from 'react-redux';
import { getBugReportsRequest } from '../thunks/bug-report.thunk';
import {
  bugReportsSelector,
  bugReportsErrorSelector,
  bugReportsLoadingSelector,
  totalSelector,
  itemsPerPageSelector,
  pageSelector,
} from '../slices/bug-report.slice';

export const useBugReport = () => {
  const dispatch = useDispatch();

  const getBugReports = (page = 1, itemsPerPage = 10, params = {}) => {
    dispatch(getBugReportsRequest(page, itemsPerPage, params));
  };

  const bugReports = useSelector(bugReportsSelector);
  const error = useSelector(bugReportsErrorSelector);
  const loading = useSelector(bugReportsLoadingSelector);
  const total = useSelector(totalSelector);
  const itemsPerPage = useSelector(itemsPerPageSelector);
  const page = useSelector(pageSelector);

  return {
    getBugReports,
    bugReports,
    error,
    loading,
    total,
    page,
    itemsPerPage,
  };
};
