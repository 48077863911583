import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk, generateErrorHandlerAction } from 'store';

import { IProduct } from '../../entities/product.entity';
import { ProductApi } from '../api/product.api';
import {
  fetchUpdateProduct,
  fetchUpdateProductFailed,
  fetchUpdateProductSucceeded,
} from '../slices/product.slice';

export const updateProduct = (
  id: string,
  partialProduct: Partial<IProduct>,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IProduct> | null = null;
  dispatch(fetchUpdateProduct());

  const request = partialProduct;

  try {
    response = await ProductApi.updateProduct(id, request);
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchUpdateProductFailed,
      ),
    );
    onError(error);
  }
  if (response && response.data && response.data.id) {
    dispatch(fetchUpdateProductSucceeded(response.data));
    onError(undefined);
  }
};
