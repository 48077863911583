import { OrderApi } from '../api/order.api';
import {
  fetchOrderStatus,
  fetchOrderStatusFailed,
  fetchOrderStatusSucceeded,
} from '../slices/order.slice';

import { AxiosError, AxiosResponse } from 'axios';
import { IOrder } from 'order';
import { Dispatch } from 'react';
import { AppThunk } from 'store';

export const changeOrderStatusThunk = (
  orderId: string,
  status: number,
): AppThunk<Promise<IOrder>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrder> | null = null;
  dispatch(fetchOrderStatus({ orderId }));
  try {
    response = await OrderApi.changeOrderStatus(orderId, status);
  } catch (err) {
    const { isAxiosError, message, stack, name, code } = err as AxiosError;
    dispatch(
      fetchOrderStatusFailed({
        error: { isAxiosError, message, stack, name, code },
        orderId,
      }),
    );
    throw err;
  }
  dispatch(fetchOrderStatusSucceeded({ order: response.data }));
  return response.data;
};
