import { AxiosResponse } from 'axios';

import { IAnalytics } from '../../entities/analytics.entity';
import { Trace } from '../../entities/trace.model';
import AnalyticsApi from '../api/analytics.api';
import { TraceEnum } from '../trace.enum';

export const useAnalytics = () => {
  const getFavoriteProductToPerson = async (
    personId: string,
  ): Promise<IAnalytics[]> => {
    try {
      const response: AxiosResponse<
        IAnalytics[]
      > = await AnalyticsApi.getProduct('NUMBER', 4, personId);
      return response.data;
    } catch (e) {
      return [];
    }
  };

  const getFavoriteProduct = async (
    beginDate?: Date,
    endDate?: Date,
  ): Promise<IAnalytics[]> => {
    try {
      const response: AxiosResponse<
        IAnalytics[]
      > = await AnalyticsApi.getProduct('NUMBER', 4, null, beginDate, endDate);
      return response.data;
    } catch (e) {
      return [];
    }
  };

  const getProduct = async (productId?: string): Promise<IAnalytics> => {
    try {
      const response: AxiosResponse<
        IAnalytics[]
      > = await AnalyticsApi.getProduct(
        'NUMBER',
        null,
        null,
        null,
        null,
        productId,
      );
      return response.data ? response.data[0] : null;
    } catch (e) {
      return null;
    }
  };

  const getProductCategories = async (): Promise<IAnalytics[]> => {
    try {
      const response: AxiosResponse<
        IAnalytics[]
      > = await AnalyticsApi.getProduct('NUMBER', null, null, null, null, null);
      return response.data ? response.data : null;
    } catch (e) {
      return null;
    }
  };

  const getNumberVisitsToPerson = async (
    personId: string,
  ): Promise<IAnalytics> => {
    try {
      const response: AxiosResponse<
        IAnalytics[]
      > = await AnalyticsApi.getAudiences('VISITED', 3, personId);
      return response.data && response.data.length > 0
        ? response.data[0]
        : null;
    } catch (e) {
      return null;
    }
  };
  const getAverageCaToPerson = async (
    personId: string,
  ): Promise<IAnalytics> => {
    try {
      const response: AxiosResponse<
        IAnalytics[]
      > = await AnalyticsApi.getCaAverage('USER', 1, personId);
      return response.data && response.data.length > 0
        ? response.data[0]
        : null;
    } catch (e) {
      return null;
    }
  };

  const getMoreLoyal = async (): Promise<IAnalytics[]> => {
    try {
      const response: AxiosResponse<
        IAnalytics[]
      > = await AnalyticsApi.getAudiences('VISITED', 3);
      return response.data;
    } catch (e) {
      return [];
    }
  };
  const getNewCustomer = async (): Promise<IAnalytics[]> => {
    try {
      const response: AxiosResponse<
        IAnalytics[]
      > = await AnalyticsApi.getAudiences('DATE_DESC', 3);
      return response.data;
    } catch (e) {
      return [];
    }
  };
  const getOldestCustomer = async (): Promise<IAnalytics[]> => {
    try {
      const response: AxiosResponse<
        IAnalytics[]
      > = await AnalyticsApi.getAudiences('DATE_ASC', 3);
      return response.data;
    } catch (e) {
      return [];
    }
  };
  const getMostSpendersCustomer = async (): Promise<IAnalytics[]> => {
    try {
      const response: AxiosResponse<IAnalytics[]> = await AnalyticsApi.getCa(
        'USER',
        3,
      );
      return response.data;
    } catch (e) {
      return [];
    }
  };

  const getCa = async (
    beginDate: Date,
    endDate: Date,
    filterByDate: string,
    sortBy: string,
    userId?: string,
  ): Promise<IAnalytics[]> => {
    try {
      const response: AxiosResponse<IAnalytics[]> = await AnalyticsApi.getCa(
        sortBy,
        null,
        userId,
        beginDate,
        endDate,
        filterByDate,
      );
      return response.data;
    } catch (e) {
      return [];
    }
  };

  const getCaAverage = async (
    beginDate: Date,
    endDate: Date,
    filterByDate: string,
    sortBy: string,
  ): Promise<IAnalytics[]> => {
    try {
      const response: AxiosResponse<
        IAnalytics[]
      > = await AnalyticsApi.getCaAverage(
        sortBy,
        null,
        null,
        beginDate,
        endDate,
        filterByDate,
      );
      return response.data;
    } catch (e) {
      return [];
    }
  };

  const findFirstOrder = async (personId?: string): Promise<Trace> => {
    try {
      const response: AxiosResponse<Trace> = await AnalyticsApi.findOneBy(
        TraceEnum.ORDER_FINISHED,
        personId,
        'asc',
      );
      return response.data;
    } catch (e) {
      return null;
    }
  };

  return {
    findFirstOrder,
    getMoreLoyal,
    getNewCustomer,
    getOldestCustomer,
    getMostSpendersCustomer,
    getNumberVisitsToPerson,
    getAverageCaToPerson,
    getFavoriteProductToPerson,
    getCa,
    getCaAverage,
    getFavoriteProduct,
    getProduct,
    getProductCategories,
  };
};
