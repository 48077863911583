import { IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  loading: boolean;
  error: string;
  delete: {
    loading: boolean;
    error: string;
  };
} = {
  loading: false,
  error: '',
  delete: {
    loading: false,
    error: '',
  },
};

export type EmployeeState = typeof initialState;

export interface IAppStateEmployee {
  employee: EmployeeState;
}

export const employeeLoadingSelector = (state: IAppStateEmployee) =>
  state.employee.loading;

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    fetchAddEmployee: (state: EmployeeState, _action: PayloadAction) => {
      state.loading = true;
    },

    fetchAddEmployeeSucceeded: (state: EmployeeState) => {
      state.loading = false;
    },

    fetchAddEmployeeFailed: (
      state: EmployeeState,
      action: PayloadAction<IPayloadError>,
    ) => {
      const { payload } = action;
      state.error = payload.message;
      state.loading = false;
    },

    fetchUpdateEmployee: (state: EmployeeState, _action: PayloadAction) => {
      state.loading = true;
    },

    fetchUpdateEmployeeSucceeded: (state: EmployeeState) => {
      state.loading = false;
    },

    fetchUpdateEmployeeFailed: (
      state: EmployeeState,
      action: PayloadAction<IPayloadError>,
    ) => {
      const { payload } = action;
      state.error = payload.message;
      state.loading = false;
    },

    fetchDeleteEmployee: (state: EmployeeState, _action: PayloadAction) => {
      state.delete.loading = true;
    },

    fetchDeleteEmployeeSucceeded: (state: EmployeeState) => {
      state.delete.loading = false;
    },

    fetchDeleteEmployeeFailed: (
      state: EmployeeState,
      action: PayloadAction<IPayloadError>,
    ) => {
      const { payload } = action;
      state.delete.error = payload.message;
      state.delete.loading = false;
    },
  },
});

export const {
  fetchAddEmployee,
  fetchAddEmployeeSucceeded,
  fetchAddEmployeeFailed,
  fetchUpdateEmployee,
  fetchUpdateEmployeeSucceeded,
  fetchUpdateEmployeeFailed,
  fetchDeleteEmployee,
  fetchDeleteEmployeeSucceeded,
  fetchDeleteEmployeeFailed,
} = employeeSlice.actions;

export const { reducer: employeeReducer } = employeeSlice;
