import { IProduct } from '../../entities/product.entity';
import { ProductApi } from '../api/product.api';
import {
  fetchProduct,
  fetchProductFailed,
  fetchProductSucceeded,
} from '../slices/product.slice';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

export const getProductRequest = (id: string): AppThunk => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(fetchProduct());

  let response: AxiosResponse<IProduct> | null = null;

  try {
    response = await ProductApi.getProduct(id);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchProductFailed({
        key: 'get',
        data: {
          [id]: {
            isAxiosError,
            message,
            name,
            code: statusCode,
            stack,
          },
        },
        error: {
          isAxiosError,
          message,
          name,
          code: statusCode,
          stack,
        },
      }),
    );
    return;
  }
  if (response) {
    const { data } = response;

    dispatch(fetchProductSucceeded({ key: 'get', data: { [id]: data }, id }));
    return;
  }
};
