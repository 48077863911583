import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxDispatch } from 'store';

import {
  errorAddSelector,
  errorDeleteSelector,
  errorListSelector,
  listSelector,
  loadingAddSelector,
  loadingDeleteSelector,
  loadingListSelector,
} from '../slices/gallery.selector';
import { deleteGalleryThunk } from '../thunks/gallery.delete.thunk';
import { getGalleryThunk } from '../thunks/gallery.get.thunk';
import { addGalleryThunk } from '../thunks/gallery.post.thunk';

export const useGallery = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const dispatch = useDispatch<ReduxDispatch>();

  // List concerns
  const list = useSelector(listSelector);
  const listLoading = useSelector(loadingListSelector);
  const listError = useSelector(errorListSelector);
  const getList = () => dispatch(getGalleryThunk());

  // Addition concerns
  const addLoading = useSelector(loadingAddSelector);
  const addError = useSelector(errorAddSelector);
  const addItem = (file: File, categoryUri: string) => {
    setUploadProgress(0);
    return dispatch(
      addGalleryThunk(file, categoryUri, (progressEvent) => {
        console.warn(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        );
        setUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        );
      }),
    );
  };

  // Deletion concerns
  const deleteLoading = useSelector(loadingDeleteSelector);
  const deleteError = useSelector(errorDeleteSelector);
  const deleteItem = (itemId: string, categoryName: string) =>
    dispatch(deleteGalleryThunk(itemId, categoryName));

  return {
    getList,
    list,
    listLoading,
    listError,
    addItem,
    addError,
    addLoading,
    deleteLoading,
    deleteError,
    deleteItem,
    uploadProgress,
  };
};
