import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { IConfig } from '../../entities/config.entity';
import { ConfigApi } from '../api/config.api';
import {
  fetchConfig,
  fetchConfigFailed,
  fetchConfigSucceeded,
} from '../slices/config.slice';

export const postAdminConfigThunk = (
  publicConfig: Partial<IConfig>,
): AppThunk<Promise<IConfig>> => async (dispatch: Dispatch) => {
  let response: AxiosResponse<IConfig> = null;
  dispatch(fetchConfig());

  try {
    response = await ConfigApi.postAdminConfig(publicConfig);

    const data = { [publicConfig.code]: response.data.value };

    dispatch(
      fetchConfigSucceeded({
        key: 'public',
        data: data,
      }),
    );
    return response.data;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: null,
      toJSON: null,
    };
    dispatch(fetchConfigFailed(error));
    throw error;
  }
};
