import { IOrder } from 'order/entities';

import { PdfApi } from '../api/pdf.api';

export const usePdf = () => {
  const generateInvoice = async (order: IOrder) => {
    return PdfApi.generateInvoice(order);
  };
  return { generateInvoice };
};
