import AuthApi from '../api/auth.api';
import {
  fetchImpersonate,
  fetchImpersonateFailed,
  fetchImpersonateSucceeded,
} from '../slices/auth.slice';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

export const getImpersonateRequest = (
  userId: string,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchImpersonate());

  let response: AxiosResponse<{ access_token: string }> | null = null;

  try {
    response = await AuthApi.getImpersonate(userId);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    if (!responseError?.data) {
      dispatch(
        fetchImpersonateFailed({
          isAxiosError,
          message: 'Failed',
          name,
          code: '404',
          stack,
        }),
      );
      return;
    }
  }
  if (response && response.data && response.data.access_token) {
    // - Dispatch succeeded, set users
    if (localStorage) {
      localStorage.setItem('impersonateToken', response.data.access_token);
    }
    dispatch(
      fetchImpersonateSucceeded({
        impersonateToken: response.data.access_token,
      }),
    );

    if (window) {
      window.location.reload();
    }

    return;
  }
};
