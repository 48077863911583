import { IPicture } from 'gallery';
import { IView } from 'shared-entities/pagination.entity';
import { IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  list: { [key: string]: Array<{ media: IPicture; id: string }> };
  errors: { list: IPayloadError; add: IPayloadError; delete: IPayloadError };
  loadings: { list: boolean; add: boolean; delete: boolean };
  view: IView | undefined;
} = {
  loadings: { list: false, add: false, delete: false },
  errors: { list: undefined, add: undefined, delete: undefined },
  list: {},
  view: undefined,
};

export type GalleryState = typeof initialState;
export interface IAppStateGallery {
  gallery: GalleryState;
}

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    fetchGallery: (state: GalleryState) => {
      state.loadings.list = true;
      state.errors.list = undefined;
    },
    fetchPostGallery: (state: GalleryState) => {
      state.loadings.add = true;
      state.errors.add = undefined;
    },
    fetchDeleteGalleryItem: (state: GalleryState) => {
      state.loadings.delete = true;
      state.errors.delete = undefined;
    },
    fetchGallerySucceeded: (
      state: GalleryState,
      {
        payload,
      }: PayloadAction<{
        list: { [key: string]: Array<{ media: IPicture; id: string }> };
        key: string;
        view?: IView;
      }>,
    ) => {
      state.list = payload.list;
      state.loadings[payload.key] = false;
      if (payload.view) {
        state.view = payload.view;
      }
    },
    fetchDeleteSucceeded: (
      state: GalleryState,
      { payload }: PayloadAction<{ category: string; itemId: string }>,
    ) => {
      state.errors.delete = undefined;
      state.loadings.delete = false;
      state.list[payload.category] = state.list[payload.category].filter(
        (item) => item.id !== payload.itemId,
      );
    },
    fetchGalleryFailed: (
      state: GalleryState,
      { payload }: PayloadAction<{ error: IPayloadError; key: string }>,
    ) => {
      state.errors[payload.key] = payload.error;
      state.loadings[payload.key] = false;
    },
  },
});

export const {
  fetchGallery,
  fetchGalleryFailed,
  fetchGallerySucceeded,
  fetchPostGallery,
  fetchDeleteGalleryItem,
  fetchDeleteSucceeded,
} = gallerySlice.actions;

export const { reducer: galleryReducer } = gallerySlice;
