import { ICommunicationNotifications } from '../../entities/communication.notification.entity';
import { NotificationsApi } from '../api/notification.api';
import {
  fetchClear,
  fetchNotificationFailed,
  fetchNotifications,
  fetchNotificationSucceeded,
} from '../slice/notifications.slice';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { IPagination } from 'shared-entities/pagination.entity';
import { AppThunk } from 'store';

export const getNotificationsRequest = (filter): AppThunk => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(fetchNotifications({ key: 'list' }));

  let response: AxiosResponse<
    IPagination<ICommunicationNotifications>
  > | null = null;

  try {
    response = await NotificationsApi.getNotifications(filter);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchNotificationFailed({
        key: 'list',
        data: {},
        error: {
          isAxiosError,
          message,
          name,
          code: statusCode,
          stack,
        },
      }),
    );
    return;
  }

  if (response != null && response?.data) {
    dispatch(fetchClear());
    const { results } = response.data;
    const notificationsObj = results.reduce((previousValue, currentValue) => {
      previousValue[currentValue.id] = currentValue;
      return previousValue;
    }, {});

    dispatch(
      fetchNotificationSucceeded({ key: 'list', data: notificationsObj }),
    );
    return;
  }

  return;
};
