import { errorReducer } from './generic.error.reducer';
import { ReduxDispatch } from './store.entity';

import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from 'auth/redux';
import { bugReportReducer } from 'bug-reports/redux/slices/bug-report.slice';
import { calendarReducer } from 'calendar/redux/slices/calendar.slice';
import { claimReducer } from 'claim/redux/slices/claim.slice';
import { cmsReducer } from 'cms/redux/slices/cms.slice';
import { reducer as communicationReducer } from 'communication/redux/slices/communications.slice';
import { configReducer } from 'config/redux/slices/config.slice';
import { galleryReducer } from 'gallery/redux/slices/gallery.slice';
import { notificationReducer } from 'notification/redux/slice/notifications.slice';
import { opinionReducer } from 'opinions/redux/slices/opinions.slice';
import { orderReducer } from 'order/redux/slices/order.slice';
import { employeeReducer } from 'organization/redux/slices/employee.slice';
import { founderReducer } from 'organization/redux/slices/founder.slice';
import { kycReducer } from 'organization/redux/slices/kyc.slice';
import { organizationReducer } from 'organization/redux/slices/organization.slice';
import { personReducer } from 'person/redux/slices/person.slice';
import { useDispatch } from 'react-redux';
import { basketReducer } from 'shop/redux/slices/basket.slice';
import { categoryReducer } from 'shop/redux/slices/category.slice';
import { discountReducer } from 'shop/redux/slices/discount.slice';
import { loyaltyReducer } from 'shop/redux/slices/loyalty.slice';
import { paymentReducer as paymentLemonWayReducer } from 'shop/redux/slices/payment.slice';
import { productReducer } from 'shop/redux/slices/product.slice';
import { subscriptionsReducer } from 'subscriptions/redux/slices/subscriptions.slice';
import { userReducer } from 'user/redux/slices/user.slice';
import paymentMangoPayReducer from 'payment-mangopay/redux/slices/payment.slice';

/**
 * Dispatch
 */
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export const appReducer = combineReducers({
  errors: errorReducer,
  auth: authReducer,
  person: personReducer,
  order: orderReducer,
  organization: organizationReducer,
  employee: employeeReducer,
  founder: founderReducer,
  kyc: kycReducer,
  claim: claimReducer,
  bugReport: bugReportReducer,
  opinions: opinionReducer,
  category: categoryReducer,
  discount: discountReducer,
  basket: basketReducer,
  loyalty: loyaltyReducer,
  product: productReducer,
  paymentLemonWay: paymentLemonWayReducer,
  paymentMangoPay: paymentMangoPayReducer,
  communication: communicationReducer,
  notification: notificationReducer,
  subscriptions: subscriptionsReducer,
  config: configReducer,
  gallery: galleryReducer,
  cms: cmsReducer,
  callendar: calendarReducer,
  user: userReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'auth/fetchLogout') {
    state = undefined;
  }
  return appReducer(state, action);
};
