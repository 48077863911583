import { IAppState, IPayloadError } from 'store';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProduct } from '../../entities/product.entity';
import { IView } from 'shared-entities';

const initialState: {
  list: { [key: string]: IProduct };
  error: { [key: string]: IPayloadError };
  loading: { [key: string]: boolean };
  view?: IView;
  total?: number;
  products: {
    loading: boolean;
    list: { [key: string]: IProduct };
  };
  product: {
    error: string;
    loading: boolean;
    product?: IProduct;
  };
  addOption: {
    error: string;
    loading: boolean;
    product?: IProduct;
  };
  deleteOption: {
    error: string;
    loading: boolean;
  };
  delete: {
    error: string;
    loading: boolean;
  };
  parent: {
    error: string;
    loading: boolean;
  };
} = {
  list: {},
  error: {},
  loading: {},
  products: {
    loading: false,
    list: {},
  },
  product: {
    error: '',
    loading: false,
    product: undefined,
  },
  addOption: {
    error: '',
    loading: false,
    product: undefined,
  },
  deleteOption: {
    error: '',
    loading: false,
  },
  delete: {
    error: '',
    loading: false,
  },
  parent: {
    error: '',
    loading: false,
  },
};

export type ProductState = typeof initialState;
export const productListSelector = (state: IAppState) => state.product.list;
export const productSelector = (state: IAppState) =>
  state.product.product.product;
export const productLoadingSelector = (state: IAppState) =>
  state.product.product.loading;
export const productsListLoadingSelector = (state: IAppState) =>
  state.product.products.loading;
export const productErrorSelector = (state: IAppState) =>
  state.product.product.error;
export const productsCategoryOrganizationSelector = (state: IAppState) =>
  state.product.products.list;

export const productsLoadingSelector = (state: IAppState) =>
  state.product.loading['list'];

export const viewSelector = (state: IAppState) => state.order.view;
export const totalProductsSelector = (state: IAppState) => state.product.total;

export const makeProductsByTypeSelector = createSelector(
  productListSelector,
  (products) => (type: string) =>
    Object.values(products).filter((product) => product.type === type),
);

const availabilityProductReducer = {
  fetchSetAvailabilityProduct: (
    state: ProductState,
    _action: PayloadAction,
  ) => {
    state.product.loading = true;
  },
  fetchSetAvailabilityProductSucceeded: (
    state: ProductState,
    action: PayloadAction<IProduct>,
  ) => {
    const { payload } = action;
    state.product.loading = false;
    state.products.list[payload.id] = { ...payload };
  },
  fetchSetAvailabilityProductFailed: (
    state: ProductState,
    _action: PayloadAction<IPayloadError>,
  ) => {
    state.product.error = _action.payload.message;
    state.product.loading = false;
  },
};

const parentProductsReducer = {
  fetchParentProducts: (state: ProductState, _action: PayloadAction) => {
    state.parent.loading = true;
  },

  fetchParentProductsSucceeded: (
    state: ProductState,
    _action: PayloadAction,
  ) => {
    state.parent.loading = false;
  },

  fetchParentProductsFailed: (
    state: ProductState,
    _action: PayloadAction<IPayloadError>,
  ) => {
    state.parent.loading = false;
  },
};

const productsFromOrganizationReducer = {
  fetchProductsFromOrganization: (
    state: ProductState,
    _action: PayloadAction,
  ) => {
    state.products.loading = true;
  },

  fetchProductsFromOrganizationSucceeded: (
    state: ProductState,
    action: PayloadAction<{ [key: string]: IProduct }>,
  ) => {
    state.products.list = action.payload;
    state.products.loading = false;
  },

  fetchProductsFromOrganizationFailed: (
    state: ProductState,
    _action: PayloadAction<IPayloadError>,
  ) => {
    state.products.loading = false;
  },
};

const deleteProductReducer = {
  fetchDeleteProduct: (state: ProductState, _action: PayloadAction) => {
    state.delete.loading = true;
  },

  fetchDeleteProductSucceeded: (
    state: ProductState,
    action: PayloadAction<string>,
  ) => {
    const { payload } = action;
    const { [payload]: omit, ...rest } = state.products.list;
    state.products.list = rest;

    const { [payload]: remove, ...restProducts } = state.list;
    state.list = restProducts;

    state.delete.loading = false;
  },

  fetchDeleteProductFailed: (
    state: ProductState,
    action: PayloadAction<IPayloadError>,
  ) => {
    const { payload } = action;
    state.delete.error = payload.message;
    state.delete.loading = false;
  },
};

const addProductReducer = {
  fetchAddProduct: (state: ProductState, _action: PayloadAction) => {
    state.product.loading = true;
  },

  fetchAddProductSucceeded: (
    state: ProductState,
    action: PayloadAction<IProduct>,
  ) => {
    const { payload } = action;
    state.products.list[payload.id] = payload;
    state.product.loading = false;
  },

  fetchAddProductFailed: (
    state: ProductState,
    action: PayloadAction<IPayloadError>,
  ) => {
    const { payload } = action;
    state.product.error = payload.message;
    state.product.loading = false;
  },
};

const updateProductReducer = {
  fetchUpdateProduct: (state: ProductState, _action: PayloadAction) => {
    state.product.loading = true;
  },

  fetchUpdateProductSucceeded: (
    state: ProductState,
    action: PayloadAction<IProduct>,
  ) => {
    const { payload } = action;
    state.product.loading = false;
    state.products.list[payload.id] = { ...payload };
  },

  fetchUpdateProductFailed: (
    state: ProductState,
    action: PayloadAction<IPayloadError>,
  ) => {
    const { payload } = action;
    state.product.error = payload.message;
    state.product.loading = false;
  },
};

const getProductReducer = {
  fetchProduct: (state: ProductState, _action: PayloadAction) => {
    state.product.loading = true;
  },
};

const addOptionReducer = {
  fetchAddOption: (state: ProductState, _action: PayloadAction) => {
    state.addOption.loading = true;
  },

  fetchAddOptionSucceeded: (
    state: ProductState,
    action: PayloadAction<IProduct>,
  ) => {
    const { payload } = action;
    state.products.list[payload.id] = payload;
    state.addOption.loading = false;
  },

  fetchAddOptionFailed: (
    state: ProductState,
    action: PayloadAction<IPayloadError>,
  ) => {
    const { payload } = action;
    state.addOption.error = payload.message;
    state.addOption.loading = false;
  },
};

const deleteOptionReducer = {
  fetchDeleteOption: (state: ProductState, _action: PayloadAction) => {
    state.deleteOption.loading = true;
  },

  fetchDeleteOptionSucceeded: (
    state: ProductState,
    action: PayloadAction<{ optionId: string; productId: string }>,
  ) => {
    // const { [optionId]: omit, ...options } = state.products.list[
    //   productId
    // ].options;
    // state.products.list[productId].options = { ...options };
    // state.deleteOption.loading = false;
  },

  fetchDeleteOptionFailed: (
    state: ProductState,
    action: PayloadAction<IPayloadError>,
  ) => {
    const { payload } = action;
    state.deleteOption.error = payload.message;
    state.deleteOption.loading = false;
  },
};

export const productsSelector = (state: IAppState) => state.product.list;

export const productsByOrganizationSelector = (organizationId: string) =>
  createSelector(productsSelector, (products: { [key: string]: IProduct }) => {
    const filteredProducts = Object.values(products).filter(
      (product) =>
        product.organizationUri === `/api/organizations/${organizationId}`,
    );
    return filteredProducts.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
  });

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    fetchClearProduct: (state: ProductState, _action: PayloadAction) => {
      state.product.product = undefined;
      state.product.error = '';
      state.product.loading = false;
    },
    fetchClearProducts: (state: ProductState, _action: PayloadAction) => {
      state.products.list = {};
      state.products.loading = false;
    },
    fetchProducts: (state: ProductState, _action: PayloadAction) => {
      state.loading['list'] = true;
    },

    fetchProductSucceeded: (
      state: ProductState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IProduct };
        id?: string;
        view?: IView;
        total?: number;
      }>,
    ) => {
      const { payload } = action;

      if (payload.key === 'delete') {
        delete state.list[payload.id];
      } else {
        state.list = { ...state.list, ...payload.data };
      }

      const keys = Object.keys(payload);

      keys.map((key) => {
        state.loading[key] = false;
      });

      if (action.payload.view) {
        state.view = action.payload.view;
      }
      state.total = payload.total;
      if (payload.id && payload.data && payload.data[payload.id]) {
        state.product.product = payload.data[payload.id];
        state.product.loading = false;
      }

      state.loading[payload.key] = false;
    },

    fetchProductFailed: (
      state: ProductState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IPayloadError };
        error: IPayloadError;
      }>,
    ) => {
      const { payload } = action;

      const keys = Object.keys(payload.data);

      keys.map((key) => {
        state.loading[key] = false;
      });
      keys.map((key) => {
        state.error[key] = payload[key];
      });
      state.error[payload.key] = payload.error;
    },
    ...productsFromOrganizationReducer,
    ...deleteProductReducer,
    ...getProductReducer,
    ...parentProductsReducer,
    ...addProductReducer,
    ...updateProductReducer,
    ...availabilityProductReducer,
    ...addOptionReducer,
    ...deleteOptionReducer,
  },
});

export const {
  fetchClearProduct,
  fetchClearProducts,
  fetchProduct,
  fetchUpdateProduct,
  fetchUpdateProductFailed,
  fetchUpdateProductSucceeded,
  fetchAddProduct,
  fetchAddProductFailed,
  fetchAddProductSucceeded,
  fetchDeleteProduct,
  fetchDeleteProductFailed,
  fetchDeleteProductSucceeded,
  fetchProductsFromOrganization,
  fetchProductsFromOrganizationSucceeded,
  fetchProductsFromOrganizationFailed,
  fetchParentProducts,
  fetchParentProductsFailed,
  fetchParentProductsSucceeded,
  fetchSetAvailabilityProduct,
  fetchSetAvailabilityProductFailed,
  fetchSetAvailabilityProductSucceeded,
  fetchAddOption,
  fetchAddOptionFailed,
  fetchAddOptionSucceeded,
  fetchDeleteOption,
  fetchDeleteOptionFailed,
  fetchDeleteOptionSucceeded,
  fetchProducts,
  fetchProductSucceeded,
  fetchProductFailed,
} = productSlice.actions;

export const { reducer: productReducer } = productSlice;
