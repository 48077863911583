import { IPost } from '../../entities/post.entity';
import {
  errorSelector,
  getPostById,
  loadingSelector,
} from '../slices/cms.selectors';
import { deleteCmsThunk } from '../thunks/cms-delete.thunk';
import { updateCmsThunk } from '../thunks/cms-update.thunk';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventsStatusListener, ReduxDispatch } from 'store';

export const usePost = <T>(id: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ReduxDispatch>();
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const post: IPost<T> = useSelector(getPostById(id));

  const updatePost = (values: Partial<IPost<T>>) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateCmsThunk(id, values, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
            resolve();
          }
        }),
      );
    });
  };
  const deletePost = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        deleteCmsThunk(id, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  return { deletePost, updatePost, post, loading, error };
};
