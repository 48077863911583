import { IOrganization } from 'organization';
import { IPerson } from 'person';
import { opinionsInstance } from 'store/api';

import { IClaimOpinion } from '../../entities/claim.opinion.entity';
import { IOpinion } from '../../entities/opinions.entity';

export const OPINIONS_ENDPOINT = '/api/opinions';
export const PUBLIC_OPINIONS_ENDPOINT = '/api/public/opinions';

export class OpinionsApi {
  static getAll = (params?: { [key: string]: string | number }) =>
    opinionsInstance.get(`${OPINIONS_ENDPOINT}`, { params });

  static getAllPublic = (params?: { [key: string]: string | number }) =>
    opinionsInstance.get(`${PUBLIC_OPINIONS_ENDPOINT}`, { params });

  static update = (id: string, opinion: Partial<IOpinion<any, any>>) =>
    opinionsInstance.put(`${OPINIONS_ENDPOINT}/${id}/status`, {
      ...opinion,
    });

  static createOpinion = (
    idPerson: string,
    opinion: Partial<IOpinion<IOrganization, IPerson>>,
  ) => opinionsInstance.post(`${OPINIONS_ENDPOINT}/${idPerson}`, opinion);

  static getIGiveOpinions = (params?: { [key: string]: string | number }) =>
    opinionsInstance.get(`${OPINIONS_ENDPOINT}/i-give`, {
      params,
    });

  static createClaimOpinion = (opinionId: string, claim: IClaimOpinion) =>
    opinionsInstance.post(`${OPINIONS_ENDPOINT}/${opinionId}/claim`, claim);

  static getAverage = (filters?: {}) =>
    opinionsInstance.get(`${OPINIONS_ENDPOINT}/average`, {
      params: { ...filters },
    });
}
