import axios from 'axios';
import { Loyalty } from 'shop/entities';

export class LoyaltyApi {
  public static PUBLIC_LOYALTY_ENDPOINT = '/api/shop/loyalties';

  public static getLoyalty = (organizationId: string) => {
    return axios.get(
      `${LoyaltyApi.PUBLIC_LOYALTY_ENDPOINT}/organizations/${organizationId}`,
    );
  };

  public static updateLoyalty = (
    organizationId: string,
    data: Partial<Loyalty>,
  ) => {
    return axios.put(
      `${LoyaltyApi.PUBLIC_LOYALTY_ENDPOINT}/${organizationId}`,
      data,
    );
  };
}
