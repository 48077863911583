import { IOrder } from '../../entities/order.entity';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { compareAsc, compareDesc, isToday } from 'date-fns';
import memoize from 'lodash.memoize';
import { IView } from 'shared-entities/pagination.entity';
import { IAppState, IPayloadError } from 'store';

const initialState: {
  list: { [key: string]: IOrder };
  error: { [key: string]: IPayloadError };
  loading: { [key: string]: boolean };
  view?: IView;
  total: number;
} = {
  list: {},
  error: {},
  loading: {},
  view: { total: 0, page: 1, itemsPerPage: 10 },
  total: 0,
};

export const viewSelector = (state: IAppState) => state.order.view;

export type OrderState = typeof initialState;

export const ordersSelector = <T>(state: IAppState) =>
  state.order.list as { [key: string]: IOrder<T> };

export const makeOrdersSortedByDateSelector = createSelector(
  ordersSelector,
  (orders) =>
    memoize((field: string, order: 'asc' | 'desc') => {
      let sorted = [];
      if (order === 'asc') {
        sorted = Object.values(orders).sort((a, b) =>
          compareAsc(new Date(a[field]), new Date(b[field])),
        );
      } else {
        sorted = Object.values(orders).sort((a, b) =>
          compareDesc(new Date(a[field]), new Date(b[field])),
        );
      }
      return sorted;
    }),
);

export const makeOrderSelector = createSelector(ordersSelector, (opinions) =>
  memoize((id: string) => opinions[id]),
);
export const totalOrdersSelector = (state: IAppState) => state.order.total;

export const ordersOfTodaySelector = createSelector(
  ordersSelector,
  (orders: { [key: string]: IOrder }) => {
    return Object.values(orders).filter(
      (order) =>
        order &&
        order?.orderedItems &&
        order?.orderedItems[0] &&
        order?.orderedItems[0]?.schedules &&
        order?.orderedItems[0]?.schedules[0] &&
        isToday(new Date(order.orderedItems[0].schedules[0].allowedFromDate)),
    );
  },
);
export const ordersLoadingSelector = (state: IAppState) =>
  state.order.loading['list'];

const orderSlice = createSlice({
  reducers: {
    fetchOrdersClear: (state: OrderState, _action: PayloadAction) => {
      state.list = {};
      state.error = {};
      state.loading = {};
      state.total = 0;
    },

    fetchOrders: (state: OrderState, _action: PayloadAction) => {
      state.loading['list'] = true;
    },

    fetchOrdersSucceeded: (
      state: OrderState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IOrder };
        id?: string;
        view?: IView;
        total?: number;
      }>,
    ) => {
      const { payload } = action;

      if (payload.key === 'delete') {
        delete state.list[payload.id];
      } else {
        state.list = { ...payload.data, ...state.list };
      }

      const keys = Object.keys(payload);
      keys.map((key) => {
        state.loading[key] = false;
      });

      if (action.payload.view) {
        state.view = action.payload.view;
      }
      state.total = payload.total;
      state.loading[payload.key] = false;
    },
    fetchOrderStatus: (
      state: OrderState,
      action: PayloadAction<{ orderId: string }>,
    ) => {
      state.loading[action.payload.orderId] = true;
      state.error[action.payload.orderId] = undefined;
    },
    fetchOrderStatusSucceeded: (
      state: OrderState,
      { payload }: PayloadAction<{ order: IOrder }>,
    ) => {
      state.loading[payload.order.id] = false;
      state.list = { ...state.list, [payload.order.id]: payload.order };
    },
    fetchOrderStatusFailed: (
      state: OrderState,
      { payload }: PayloadAction<{ orderId: string; error: IPayloadError }>,
    ) => {
      state.loading[payload.orderId] = false;
      state.error[payload.orderId] = payload.error;
    },
    fetchOrdersFailed: (
      state: OrderState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IPayloadError };
        error: IPayloadError;
      }>,
    ) => {
      const { payload } = action;

      state.loading[payload.key] = false;
      state.error[payload.key] = payload.error;
    },
  },
  initialState,
  name: 'order',
});

export const {
  fetchOrders,
  fetchOrdersClear,
  fetchOrdersFailed,
  fetchOrdersSucceeded,
  fetchOrderStatus,
  fetchOrderStatusFailed,
  fetchOrderStatusSucceeded,
} = orderSlice.actions;

export const { reducer: orderReducer } = orderSlice;
