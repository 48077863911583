import { IAppState, IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBugReport } from '../../entities/bug.report.entity';

const initialState: {
  loading: { [key: string]: boolean };
  error: { [key: string]: IPayloadError };
  list: { [key: string]: IBugReport };
  total: number;
  itemsPerPage: number;
  page: number;
} = {
  loading: {},
  error: {},
  list: {},
  total: 0,
  itemsPerPage: 10,
  page: 1,
};

export type BugReportState = typeof initialState;

export const bugReportsLoadingSelector = (state: IAppState) =>
  state.bugReport.loading;
export const bugReportsErrorSelector = (state: IAppState) =>
  state.bugReport.error;

export const bugReportsSelector = (state: IAppState) => state.bugReport.list;

export const totalSelector = (state: IAppState) => state.bugReport.total;
export const pageSelector = (state: IAppState) => state.bugReport.page;
export const itemsPerPageSelector = (state: IAppState) =>
  state.bugReport.itemsPerPage;

export const bugReportsArraySelector = (state: IAppState) =>
  Object.values(state.bugReport.list);

const bugReportSlice = createSlice({
  name: 'bugReport',
  initialState,
  reducers: {
    fetchClear: (state: BugReportState, _action: PayloadAction) => {
      state.list = {};
      state.error = {};
      state.loading = {};
    },
    fetchBugReports: (state: BugReportState, _action: PayloadAction) => {
      state.loading['list'] = true;
    },

    fetchBugReportSucceeded: (
      state: BugReportState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IBugReport };
        id?: string;
        page?: number;
        total?: number;
        itemsPerPage?: number;
      }>,
    ) => {
      const { payload } = action;

      if (payload.key === 'delete') {
        delete state.list[payload.id];
      } else {
        state.list = { ...state.list, ...payload.data };
      }

      state.total = payload.total;
      state.page = payload.page;
      state.itemsPerPage = payload.itemsPerPage;
      state.loading[payload.key] = false;
    },

    fetchBugReportFailed: (
      state: BugReportState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IPayloadError };
        error: IPayloadError;
      }>,
    ) => {
      const { payload } = action;
      const key = payload.key;

      state.loading[key] = false;
      state.error[key] = payload.error;
    },
  },
});

export const {
  fetchBugReports,
  fetchBugReportSucceeded,
  fetchBugReportFailed,
  fetchClear,
} = bugReportSlice.actions;

export const { reducer: bugReportReducer } = bugReportSlice;
