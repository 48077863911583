import { IDiscount } from '../../entities/discount.entity';
import { DiscountApi } from '../api/discount.api';
import {
  fetchDiscountFailed,
  fetchDiscountSuccedded,
  fetchGetDiscounts,
} from '../slices/discount.slice';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { IPagination } from 'shared-entities/pagination.entity';
import { discountAdapter } from 'shop/adapter/discount.adapter';
import { AppThunk } from 'store';

export const getDiscounts = (filter: object = {}): AppThunk => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(fetchGetDiscounts());

  let response: AxiosResponse<IPagination<IDiscount>> | null = null;

  try {
    response = await DiscountApi.getDiscounts(filter);
  } catch (err) {
    const { response: responseError } = err;
    if (responseError?.data) {
      // - Fetch failed, set error
      dispatch(
        fetchDiscountFailed({
          key: 'list',
          error: {
            ...responseError,
            message: 'An error occured',
          },
        }),
      );
      return;
    }
  }

  if (response != null && response?.data) {
    const { data } = response;
    const discounts = data;

    dispatch(
      fetchDiscountSuccedded({
        key: 'list',
        data: discountAdapter(discounts.results),
      }),
    );
    return;
  }

  return;
};
