import { AxiosError } from 'axios';
import { IAppState } from 'store/store.entity';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IFetchValidateCredentialSucceeded } from '../actions-types/auth.action.types';

const initialState = {
  token: '',
  loading: false,
  error: undefined as AxiosError | undefined,
  impersonateToken: '',
  impersonateLoading: false,
};

export type AuthState = typeof initialState;
export const tokenSelector = (state: IAppState) => state.auth.token;
export const impersonateTokenSelector = (state: IAppState) =>
  state.auth.impersonateToken;
export const loadingSelector = (state: IAppState) => state.auth.loading;
export const errorSelector = (state: IAppState) => state.auth.error;

const authSlice = createSlice({
  reducers: {
    /**
     * Reset the token when the user logout
     *
     * @param {State} state
     * @param {PayloadAction} action
     */
    fetchLogout(state: AuthState, _action: PayloadAction) {
      state.token = '';
    },

    /**
     * Set loading status true as the SMS validation is running
     *
     * @param {State} state
     * @param {PayloadAction} action
     */
    fetchValidateCredential(state: AuthState, _action: PayloadAction) {
      state.loading = true;
      state.error = undefined;
    },

    /**
     * Reset the loading status as the sms validation has succeeded
     *
     * @param {State} state
     * @param {PayloadAction<IFetchValidateCredentialSucceeded>} action
     */
    fetchValidateCredentialSucceeded(
      state: AuthState,
      action: PayloadAction<IFetchValidateCredentialSucceeded>,
    ) {
      state.token = action.payload.token;
      state.loading = false;
    },

    /**
     * Reset loading status as the sms validation failed
     *
     * @param {State} state
     * @param {PayloadAction<AxiosError>} action
     */
    fetchValidateCredentialFailed(
      state: AuthState,
      action: PayloadAction<AxiosError>,
    ) {
      state.loading = false;
      state.error = action.payload;
    },

    fetchImpersonate(state: AuthState, _action: PayloadAction) {
      state.impersonateLoading = true;
    },

    fetchImpersonateClear(state: AuthState, _action: PayloadAction) {
      state.impersonateToken = '';
    },

    fetchImpersonateSucceeded(
      state: AuthState,
      action: PayloadAction<{ impersonateToken: string }>,
    ) {
      state.impersonateToken = action.payload.impersonateToken;
      state.impersonateLoading = false;
    },

    fetchImpersonateFailed(state: AuthState, _action: PayloadAction<any>) {
      state.impersonateLoading = false;
    },
  },
  initialState,
  name: 'auth',
});

export const {
  fetchLogout,
  fetchValidateCredential,
  fetchValidateCredentialSucceeded,
  fetchValidateCredentialFailed,
  fetchImpersonate,
  fetchImpersonateFailed,
  fetchImpersonateSucceeded,
} = authSlice.actions;

export const { reducer: authReducer } = authSlice;
