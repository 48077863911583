import { useDispatch, useSelector } from 'react-redux';
import { ReduxDispatch } from 'store';

import { IConfig } from '../../entities/config.entity';
import {
  configSelector,
  errorConfigSelector,
  loadingConfigSelector,
  publicConfigSelector,
} from '../slices/config.selectors';
import { postAdminConfigThunk } from '../thunks/config.admin.thunk';
import { getPublicConfigThunk } from '../thunks/config.get.public.thunk';
import { get as getThunk } from '../thunks/config.get.thunk';
import { update as updateThunk } from '../thunks/config.update.thunk';
import { ConfigApi } from 'config/redux/api/config.api';
import { AxiosResponse } from 'axios';

export const useConfig = () => {
  const dispatch = useDispatch<ReduxDispatch>();

  const error = useSelector(errorConfigSelector);
  const config = useSelector(configSelector);
  const loading = useSelector(loadingConfigSelector);
  const publicConfig = useSelector(publicConfigSelector);

  const updateConfig = (partialConfig: Partial<IConfig>[]) => {
    return dispatch(updateThunk(partialConfig));
  };

  const getConfig = () => {
    return dispatch(getThunk());
  };

  const getApiConfig = async (code: string) => {
    try {
      const response: AxiosResponse<any> = await ConfigApi.getPublicConfig(
        code,
      );
      return response.data;
    } catch (e) {
      return undefined;
    }
  };

  const getPublicConfig = (code: string) => {
    return dispatch(getPublicConfigThunk(code));
  };

  const setPublicConfig = (partialConfig: Partial<IConfig>) => {
    return dispatch(postAdminConfigThunk(partialConfig));
  };

  return {
    getApiConfig,
    getConfig,
    updateConfig,
    getPublicConfig,
    setPublicConfig,
    error,
    config,
    loading,
    publicConfig,
  };
};
