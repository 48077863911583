import { useDispatch, useSelector } from 'react-redux';
import { ReduxDispatch } from 'store';

import {
  IAddEventCalendarPayload,
  ICalendarEvent,
  IEventOwnerFilter,
} from '../../entities';
import {
  errorSelector,
  eventsArraySelector,
  loadingSelector,
  slotsSelector,
} from '../slices/calendar.selectors';
import { fetchClear, IAppStateCalendar } from '../slices/calendar.slice';
import { addEventThunk } from '../thunks/add-event.thunk';
import { getEventsOwnerThunk } from '../thunks/events-owner.thunk';

export const useEvents = <T>() => {
  const dispatch = useDispatch<ReduxDispatch>();
  const loading = useSelector(loadingSelector);
  const loadingAdd = useSelector((state: IAppStateCalendar) =>
    loadingSelector(state, 'add'),
  );

  const error = useSelector(errorSelector);
  const errorAdd = useSelector((state: IAppStateCalendar) =>
    errorSelector(state, 'add'),
  );
  const events: ICalendarEvent<T>[] = useSelector(eventsArraySelector);
  const slots = useSelector(slotsSelector);

  const getEventsOwner = (params: IEventOwnerFilter = {}) =>
    dispatch(getEventsOwnerThunk<T>(params));

  const addEvent = (calendarId: string, params: IAddEventCalendarPayload) =>
    dispatch(addEventThunk<T>(calendarId, params));
  const clear = () => dispatch(fetchClear());

  return {
    clear,
    addEvent,
    getEventsOwner,
    loading,
    loadingAdd,
    error,
    errorAdd,
    events,
    slots,
  };
};
