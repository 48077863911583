import { IClaim } from '../../entities/claim.entity';
import { addClaimRequest } from '../thunks/create.claim.thunk';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EventsStatusListener, ReduxDispatch } from 'store';

export const useClaim = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ReduxDispatch>();

  const addClaim = (idClaim: string, claim: IClaim) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addClaimRequest(idClaim, claim, (err) => {
          if (err != null) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed'),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  return { addClaim };
};
