import { UserApi } from '../api/user.api';
import {
  fetchPasswordResetValidate,
  fetchPasswordResetValidateFailed,
  fetchPasswordResetValidateSucceeded,
} from '../slices/user.slice';

import { Dispatch } from 'redux';
import { AppThunk } from 'store';

export const postPasswordResetValidate = (
  password: string,
  passwordRepeat: string,
  token: string,
  callback: (error?: { message: string; statusCode: string }) => void,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchPasswordResetValidate());

  try {
    await UserApi.postPasswordResetValidate(password, passwordRepeat, token);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (!responseError?.data) {
      dispatch(
        fetchPasswordResetValidateFailed({
          isAxiosError,
          message: 'Failed',
          name,
          code: '404',
          stack,
        }),
      );

      callback({ message: 'failed', statusCode: '' });
      return;
    }
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchPasswordResetValidateFailed({
        isAxiosError,
        message,
        name,
        code: statusCode,
        stack,
      }),
    );
    callback({ message, statusCode });
    return;
  }
  // - Dispatch succeeded, set users
  dispatch(fetchPasswordResetValidateSucceeded());
  callback();
};
