import { useDispatch, useSelector } from 'react-redux';
import { ILemonwayAddCard } from 'shop/entities/lemonway.entity';
import { ReduxDispatch } from 'store';

import {
  lemowayCardSelector,
  lemowayConfigSelector,
} from '../slices/payment.slice';
import { addLemonwayCardThunk } from '../thunks/add.card.thunk';
import { postCredentialsCardThunk } from '../thunks/post.credential.card.thunk';

export const usePayment = () => {
  const dispatch = useDispatch<ReduxDispatch>();

  const requestCredentials = () => {
    return new Promise((resolve, reject) => {
      return dispatch(
        postCredentialsCardThunk((err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const addCard = (card: ILemonwayAddCard) => {
    return new Promise((resolve, reject) => {
      return dispatch(
        addLemonwayCardThunk(card, (err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const lemonwayCard = useSelector(lemowayCardSelector);
  const lemonwayConfig = useSelector(lemowayConfigSelector);

  return { requestCredentials, lemonwayCard, lemonwayConfig, addCard };
};
