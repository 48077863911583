import { IPerson } from 'person';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxDispatch } from 'store';
import {
  fetchDefaultCardErrorSelector,
  makeLoadingSelector,
} from '../slices/payment.slice';
import { setDefaultCard as setDefaultCardThunk } from '../thunks/card.default.thunk';
import { deleteCard as deleteCardThunk } from '../thunks/card.delete.thunk';

export const useCardActions = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const setDefaultCard = (
    cardId: string,
    actionToDispatch?: (user?: IPerson) => void,
  ) => dispatch(setDefaultCardThunk(cardId, actionToDispatch));

  const deleteCard = (
    cardId: string,
    actionToDispatch?: (user?: IPerson) => void,
  ) => dispatch(deleteCardThunk(cardId, actionToDispatch));

  const loading = useSelector(makeLoadingSelector);

  const error = useSelector(fetchDefaultCardErrorSelector);
  return { setDefaultCard, error, loading, deleteCard };
};
