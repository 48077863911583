import { IOpinion } from '../../entities/opinions.entity';
import { OpinionsApi } from '../api/opinions.api';
import {
  fetchOpinionsFailed,
  fetchOpinionsRequest,
  fetchOpinionsSucceeded,
} from '../slices/opinions.slice';

import { AxiosResponse } from 'axios';
import { normalize, schema } from 'normalizr';
import { Dispatch } from 'redux';
import { IView } from 'shared-entities/pagination.entity';
import { AppThunk, IAppState } from 'store';

export const getOpinionsThunk = (params?: {
  [key: string]: string;
}): AppThunk<Promise<{ [key: string]: IOpinion<any, any> }>> => async (
  dispatch: Dispatch<any>,
  getState: () => IAppState,
) => {
  let response: AxiosResponse<{
    results: IOpinion<any, any>[];
    view: IView;
  }> = null;
  dispatch(fetchOpinionsRequest('list'));
  try {
    response = await OpinionsApi.getAll(params);
    const opinionsSchema = new schema.Entity('opinions');
    const {
      entities: { opinions },
    } = normalize(response.data.results, [opinionsSchema]);

    dispatch(
      fetchOpinionsSucceeded({
        list: opinions,
        view: response.data.view,
        key: 'list',
      }),
    );
    return opinions;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
    };
    // - Fetch failed, set error
    dispatch(
      fetchOpinionsFailed({
        key: 'list',
        error,
      }),
    );
    throw error;
  }
};

export const getPublicOpinionsThunk = (params?: {
  [key: string]: string | number;
}): AppThunk<Promise<{ [key: string]: IOpinion<any, any> }>> => async (
  dispatch: Dispatch<any>,
  getState: () => IAppState,
) => {
  let response: AxiosResponse<{
    results: IOpinion<any, any>[];
    view: IView;
  }> = null;
  dispatch(fetchOpinionsRequest({ key: 'list' }));

  try {
    response = await OpinionsApi.getAllPublic(params);
    const opinionsSchema = new schema.Entity('opinions');
    const {
      entities: { opinions },
    } = normalize(response.data.results, [opinionsSchema]);

    dispatch(
      fetchOpinionsSucceeded({
        list: opinions,
        view: response.data.view,
        key: 'list',
      }),
    );
    return opinions;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
    };
    // - Fetch failed, set error
    dispatch(
      fetchOpinionsFailed({
        key: 'list',
        error,
      }),
    );
    throw error;
  }
};
