import { IBugReport } from '../../entities/bug.report.entity';
import { BugReportsApi } from '../api/bug-reports.api';
import {
  fetchBugReportFailed,
  fetchBugReports,
  fetchBugReportSucceeded,
} from '../slices/bug-report.slice';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { IPagination } from 'shared-entities/pagination.entity';
import { AppThunk } from 'store';

export const getBugReportsRequest = (
  page = 1,
  itemsPerPage = 10,
  params = {},
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchBugReports());

  let response: AxiosResponse<IPagination<IBugReport>> | null = null;

  try {
    response = await BugReportsApi.getBugreports(page, itemsPerPage, params);
  } catch (err) {
    const { name, response: responseError, isAxiosError, stack } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchBugReportFailed({
        key: 'list',
        error: { isAxiosError, message, name, code: statusCode, stack },
      }),
    );
    return;
  }
  if (response && response.data) {
    const result = response.data.results.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    dispatch(
      fetchBugReportSucceeded({
        key: 'list',
        data: result,
        total: response.data.view.total,
        page: response.data.view.page,
        itemsPerPage: response.data.view.itemsPerPage,
      }),
    );
    return;
  }
};
