import axios from 'axios';

import { IClaim } from '../../entities/claim.entity';

export default class ClaimApi {
  public static createClaim = (claimId: string, claim: IClaim) =>
    axios.post(`/api/claims/${claimId}`, claim);

  public static getAllClaims = () => {
    return axios.get('/api/claims');
  };

  public static getOneClaim = (claimId) => axios.get(`/api/claims/${claimId}`);
}
