import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCardErrorSelector,
  makeLoadingSelector,
  mangoPaySelector,
} from '../slices/payment.slice';
import { saveCard } from '../thunks/card.registration.thunk';

export const useCardRegistration = () => {
  const dispatch = useDispatch();
  const mangoPay = useSelector(mangoPaySelector);
  const loading = useSelector(makeLoadingSelector)('loading');
  const saveCardError = useSelector(fetchCardErrorSelector);

  const addCard = (
    cardCvx: string,
    cardExpirationDate: string,
    cardNumber: string,
  ) =>
    new Promise((resolve, reject) => {
      if (mangoPay) {
        dispatch(
          saveCard(
            cardCvx,
            cardExpirationDate,
            cardNumber,
            mangoPay?.CardRegistrationURL,
            mangoPay?.PreregistrationData,
            mangoPay?.AccessKey,
            mangoPay?.Id,
            (err) => {
              if (err) {
                reject(err);
              } else {
                resolve();
              }
            },
          ),
        );
      }
    });

  return { addCard, loading, saveCardError };
};
