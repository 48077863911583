import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { CalendarApi } from '../api/calendar.api';
import {
  fetchEvents,
  fetchEventsFailed,
  fetchEventsSucceeded,
} from '../slices/calendar.slice';

export const deleteEventThunk = (
  calendarId: string,
  eventId: string,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchEvents({ key: 'delete' }));

  try {
    await CalendarApi.deleteEvent(calendarId, eventId);

    dispatch(
      fetchEventsSucceeded({
        key: 'delete',
        id: eventId,
      }),
    );
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: null,
      toJSON: null,
    };

    dispatch(
      fetchEventsFailed({
        key: 'delete',
        error,
      }),
    );

    throw error;
  }
};
