import { ISubmitPayoutPayload } from 'shop';
import { ShopApi } from '../api/shop.api';

export const useShop = () => {
  const submitPayout = async (payload: ISubmitPayoutPayload) =>
    ShopApi.submitPayout(payload);

  return {
    submitPayout,
  };
};
