import { IPlace } from 'place';

export enum ConfigPayment {
  'SHOP_PAYMENT_ACCEPT_ADVANCE_PAYMENT_EXTERNAL' = 'SHOP_PAYMENT_ACCEPT_ADVANCE_PAYMENT_EXTERNAL',
  'SHOP_PAYMENT_ACCEPT_ADVANCE_PAYMENT_CARD' = 'SHOP_PAYMENT_ACCEPT_ADVANCE_PAYMENT_CARD',
  'SHOP_PAYMENT_ACCEPT_EXTERNAL' = 'SHOP_PAYMENT_ACCEPT_EXTERNAL',
  'SHOP_PAYMENT_ACCEPT_CARD' = 'SHOP_PAYMENT_ACCEPT_CARD',
}

export enum ShopMeansOfPaymentEnum {
  CARD = 'CARD', // Par carte
  ADVANCE_PAYMENT = 'ADVANCE_PAYMENT', // Acompte + Paiement en ligne
  ADVANCE_PAYMENT_EXTERNAL = 'ADVANCE_PAYMENT_EXTERNAL', // Acompte + Paiement sur place
  EXTERNAL = 'EXTERNAL', // Sur place
  CREDIT = 'CREDIT', // Avec virtual money
}

export type ConfigCode =
  | 'CALENDAR_MAX_DISTANCE_FROM_OWNER'
  | 'CALENDAR_MAX_DISTANCE_BETWEEN_EVENTS'
  | 'CALENDAR_LOCATION_RESTRICTION_TYPE'
  | 'CALENDAR_ALLOWED_LOCALITIES'
  | 'WEBSITE_PUB_HEADER'
  | 'CALENDAR_RECURRENCE_ENABLED'
  | 'OFF_PEAK_HOURS_PERCENTAGE'
  | ConfigPayment;

export type IConfigValue = { [key: number]: any } | IPlace[] | number | string;

export interface IConfig {
  isPublic?: boolean;
  code: ConfigCode;
  value: IConfigValue;
}
