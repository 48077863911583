import { formatBirthDate } from 'helpers/date.format.helper';
import { IPerson } from 'person';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { founderLoadingSelector } from '../slices/founder.slice';
import {
  addFounderRequest,
  deleteFounderRequest,
  updateFounderRequest,
} from '../thunks/founder.thunk';

export const useFounder = <T>() => {
  const dispatch = useDispatch();

  const addFounder = async (
    organizationId: string,
    founder: Partial<IPerson<T>>,
  ) => {
    return new Promise((resolve, reject) => {
      const normalizedOrganizationFounder = {
        id: uuidv4(),
        ...founder,
      };
      dispatch(
        addFounderRequest(
          organizationId,
          normalizedOrganizationFounder,
          (err) => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          },
        ),
      );
    });
  };
  const updateFounder = async (
    organizationId: string,
    founderId: string,
    founder: Partial<IPerson<T>>,
  ) =>
    new Promise((resolve, reject) => {
      const normalizedOrganizationFounder = {
        id: founder.id,
        firstName: founder.firstName,
        lastName: founder.lastName,
        email: founder.email,
        birthDate: formatBirthDate(founder.birthDate),
      };
      dispatch(
        updateFounderRequest(
          organizationId,
          founderId,
          normalizedOrganizationFounder,
          (err) => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          },
        ),
      );
    });

  const deleteFounder = async (organizationId: string, founderId: string) => {
    return new Promise((resolve, reject) => {
      dispatch(
        deleteFounderRequest(organizationId, founderId, (err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const loading = useSelector(founderLoadingSelector);

  return { addFounder, updateFounder, deleteFounder, loading };
};
