import { IOpinion } from '../../entities/opinions.entity';
import { OpinionsApi } from '../api/opinions.api';
import {
  fetchOpinionsFailed,
  fetchOpinionsRequest,
  fetchOpinionsSucceeded,
} from '../slices/opinions.slice';

import { AxiosError, AxiosResponse } from 'axios';
import { IOrganization } from 'organization';
import { IPerson } from 'person';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

export const createOpinionThunk = (
  id: string,
  opinion: Partial<IOpinion<IOrganization, IPerson>>,
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOpinion<any, any>> = null;
  dispatch(fetchOpinionsRequest({ key: 'add' }));
  try {
    response = await OpinionsApi.createOpinion(id, opinion);

    dispatch(
      fetchOpinionsSucceeded({
        list: { [response.data.id]: response.data },
        key: 'add',
      }),
    );

    onError();
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: null,
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(
      fetchOpinionsFailed({
        key: 'add',
        error,
      }),
    );
    onError(error);
    throw error;
  }
};
