import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';
import PaymentApi from '../api/payment.api';
import { fetchAddCard, fetchAddCardSucceeded } from '../slices/payment.slice';

export const saveCard = (
  cardCvx: string,
  cardExpirationDate: string,
  cardNumber: string,
  CardRegistrationURL: string,
  PreRegistrationData: string,
  AccessKey: string,
  Id: string,
  onResponseReceived: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchAddCard());
  let registerResponse: AxiosResponse<any> | null = null;
  let response: AxiosResponse<any> | null = null;

  try {
    // - Register Card on mangoPay
    registerResponse = await PaymentApi.registerCard(
      cardCvx,
      cardExpirationDate,
      cardNumber,
      CardRegistrationURL,
      PreRegistrationData,
      AccessKey,
      Id,
    );
    // - Register card server side
  } catch (err) {
    //dispatch(generateErrorHandlerAction(err as AxiosError, fetchAddCardFailed));
    //onResponseReceived(err);
  }
  console.log({ registerResponse, response });

  if (registerResponse) {
    response = await PaymentApi.postSaveCard(
      registerResponse?.data?.id,
      registerResponse?.data?.data,
    );
  }

  if (response) {
    dispatch(fetchAddCardSucceeded());
    onResponseReceived();
  }
};
