import { AxiosError, AxiosResponse } from 'axios';
import { IPerson } from 'person';
import { Dispatch } from 'redux';
import { ILemonwayAddCard } from 'shop';
import { AppThunk } from 'store';

import { PaymentApi } from '../api/payment.api';
import {
  fetchCardInfoPayment,
  fetchCardPaymentSuccedded,
  fetchPaymentFailed,
} from '../slices/payment.slice';

export const addLemonwayCardThunk = (
  card: ILemonwayAddCard,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IPerson> | null = null;

  dispatch(fetchCardInfoPayment());

  try {
    response = await PaymentApi.saveCardLemonway(card);
  } catch (error) {
    dispatch(
      fetchPaymentFailed({
        ...error,
        message: error?.response?.data?.message ?? 'An error occured',
      }),
    );

    onError({
      ...error,
      message: error?.response?.data?.message ?? 'An error occured',
    });
    return;
  }
  if (response && response.data && response.data.id) {
    dispatch(
      fetchCardPaymentSuccedded({
        data: response.data,
      }),
    );
    onError();
    return;
  }
  onError({
    statusCode: '404',
    message: 'An error occured',
  });
  return;
};
