function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isUserValidForMangoPay = <
  T extends {
    birthDate?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  }
>(
  user: T,
) => {
  return (
    user.birthDate != null &&
    user.birthDate.trim() !== '' &&
    user.firstName != null &&
    user.firstName.trim() !== '' &&
    user.lastName != null &&
    user.lastName.trim() !== '' &&
    user.email != null &&
    validateEmail(user.email)
  );
};
