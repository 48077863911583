import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { discountAdapter } from 'shop/adapter/discount.adapter';
import { AppThunk } from 'store';

import { IDiscount } from '../../entities/discount.entity';
import { DiscountApi } from '../api/discount.api';
import {
  fetchAddDiscount,
  fetchDiscountFailed,
  fetchDiscountSuccedded,
} from '../slices/discount.slice';

export const addDiscount = (
  discount: Partial<IDiscount>,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IDiscount> | null = null;

  dispatch(fetchAddDiscount());

  try {
    response = await DiscountApi.addDiscount(discount);
  } catch (error) {
    dispatch(
      fetchDiscountFailed({
        ...error,
        message: error?.response?.data?.message ?? 'An error occured',
      }),
    );
    onError({
      ...error,
      message: error?.response?.data?.message ?? 'An error occured',
    });
    return;
  }
  if (response && response.data && response.data.id) {
    dispatch(
      fetchDiscountSuccedded({
        key: 'add',
        data: discountAdapter([response.data]),
      }),
    );
    onError(undefined);
    return;
  }
  onError({
    statusCode: '404',
    message: 'An error occured',
  });
  return;
};
