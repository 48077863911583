export enum TraceEnum {
  // USER
  USER_LOGGED = 'user:logged',
  USER_LOGGED_SMS = 'user:logged:sms',
  USER_LOGGED_FACEBOOK = 'user:logged:facebook',
  USER_LOGGED_GOOGLE = 'user:logged:google',
  USER_LOGGED_EMAIL = 'user:logged:email',
  USER_LOGOUT = 'user:logout',
  // PERSON
  PERSON_REGISTER = 'person:register',
  // ORGANIZATION
  ORGANIZATION_VIEW = 'organization:view',
  ORGANIZATION_VISITED = 'organization:visited',
  ORGANIZATION_VISITED_FIRST = 'organization:visited-first',
  ORGANIZATION_LIKED = 'organization:liked',
  // ORDER
  ORDER_FINISHED = 'order:finished',
  ORDER_FINISHED_AMOUNT = 'order:finished:amount',
  ORDER_FINISHED_AMOUNT_COMMISSION = 'order:finished:amount:commission',
  ORDER_FINISHED_AMOUNT_TO_ORGANIZATION = 'order:finished:amount:to-organization',
  ORDER_FINISHED_PRODUCT = 'order:finished:product',
  ORDER_FINISHED_PRODUCT_AMOUNT = 'order:finished:product:amount',
  // PRODUCT
  PRODUCT_ADD_TO_BASKET = 'product:add-to-basket',
  PRODUCT_LIKED = 'product:liked',
  PRODUCT_VIEWS = 'product:views',
}
