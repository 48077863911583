import { IAppState, IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICommunicationNotifications } from '../../entities/communication.notification.entity';

const initialState: {
  loading: { [key: string]: boolean };
  error: { [key: string]: IPayloadError };
  list: { [key: string]: ICommunicationNotifications };
} = {
  loading: {},
  error: {},
  list: {},
};

export type NotificationsState = typeof initialState;

export const notificationsLoadingSelector = (state: IAppState, key: string) =>
  state.notification.loading[key];
export const notificationsErrorSelector = (state: IAppState, key: string) =>
  state.notification.error[key];

export const notificationsSelector = (state: IAppState) =>
  state.notification.list;

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    fetchClear: (state: NotificationsState, _action: PayloadAction) => {
      state.list = {};
      state.error = {};
      state.loading = {};
    },
    fetchNotifications: (
      state: NotificationsState,
      { payload }: PayloadAction<{ key?: string }>,
    ) => {
      state.loading[payload.key] = true;
    },

    fetchNotificationSucceeded: (
      state: NotificationsState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: ICommunicationNotifications };
        id?: string;
      }>,
    ) => {
      const { payload } = action;

      state.list = { ...state.list, ...payload.data };
      state.loading[payload.key] = false;
    },

    fetchNotificationFailed: (
      state: NotificationsState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IPayloadError };
        error: IPayloadError;
      }>,
    ) => {
      const { payload } = action;

      state.loading[payload.key] = false;
      state.error[payload.key] = payload.error;
    },
  },
});

export const {
  fetchClear,
  fetchNotifications,
  fetchNotificationSucceeded,
  fetchNotificationFailed,
} = notificationSlice.actions;

export const { reducer: notificationReducer } = notificationSlice;
