import { IAppStateGallery } from './gallery.slice';

export const loadingListSelector = (state: IAppStateGallery) =>
  state.gallery.loadings.list;

export const loadingAddSelector = (state: IAppStateGallery) =>
  state.gallery.loadings.add;

export const loadingDeleteSelector = (state: IAppStateGallery) =>
  state.gallery.loadings.delete;

export const errorListSelector = (state: IAppStateGallery) =>
  state.gallery.errors.list;

export const errorAddSelector = (state: IAppStateGallery) =>
  state.gallery.errors.add;

export const errorDeleteSelector = (state: IAppStateGallery) =>
  state.gallery.errors.delete;

export const listSelector = (state: IAppStateGallery) => state.gallery.list;
