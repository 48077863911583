import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { categoryAdapter } from 'shop/adapter/category.adapter';
import { AppThunk } from 'store';
import { v4 as uuidv4 } from 'uuid';

import {
  ICategory,
  ICategoryNestedResponse,
} from '../../entities/category.type';
import { ProductApi } from '../api/product.api';
import {
  fetchAddCategory,
  fetchCategoryFailed,
  fetchCategorySucceeded,
} from '../slices/category.slice';

export const addCategoryRequest = (
  partialCategory: Partial<ICategory>,
  parentId: string | undefined,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<ICategoryNestedResponse> | null = null;

  dispatch(fetchAddCategory());

  try {
    response = await ProductApi.addCategory(
      {
        ...partialCategory,
        id: uuidv4(),
      },
      parentId,
    );
  } catch (error) {
    dispatch(
      fetchCategoryFailed({
        key: 'add',
        error: {
          ...error,
          message: 'An error occured',
        },
      }),
    );
    onError({ ...error, message: 'An error occured' });
    return;
  }
  if (response && response.data && response.data.id) {
    const category = categoryAdapter([response.data]);
    dispatch(
      fetchCategorySucceeded({
        key: 'add',
        data: category,
      }),
    );
    onError(undefined);
    return;
  }
  onError({
    statusCode: '404',
    message: 'An error occured',
  });
  return;
};
