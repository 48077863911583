import { IAppState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const loadingSelector = (state: IAppState) =>
  state.communication.loading;
export const errorSelector = (state: IAppState) => state.communication.error;
export const listSelector = (state: IAppState) => state.communication.list;
export const listArraySelector = createSelector([listSelector], (list) =>
  Object.values(list || {}),
);
export const viewSelector = (state: IAppState) => state.communication.view;
