import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { IPost } from '../../entities/post.entity';
import { CmsApi } from '../api/cms.api';
import {
  fetchCms,
  fetchCmsFailed,
  fetchCmsSucceeded,
} from '../slices/cms.slice';

export const addCmsThunk = (
  type: string,
  values: Partial<IPost>,
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse = null;
  dispatch(fetchCms({ key: 'add' }));
  try {
    response = await CmsApi.addPost(type, values);

    dispatch(
      fetchCmsSucceeded({
        type,
        list: { [response.data.id]: response.data },
        key: 'add',
      }),
    );
    onError();
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(
      fetchCmsFailed({
        key: 'add',
        error,
      }),
    );
    onError(error);
    throw error;
  }
};
