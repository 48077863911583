import { ICommunication } from '../../communication';
import { IConfig } from '../../config/entities';
import {
  IDisplayed,
  IOpeningHoursSpecification,
  IServiceType,
} from '../../order/entities';
import { IPerson } from '../../person';
import { IAddress } from '../../shared-entities/address.entity';

export interface IOrganization<T = any> {
  isPaymentAllowed: boolean;
  enabled: boolean;
  location: IAddress;
  types: any[];
  createdAt: string;
  id: string;
  uri: string;
  consumerId: string;
  legalName: string;
  slogan: string;
  geopoint?: string;
  siret?: number;
  metadatas?: T;
  openingHoursSpecification: IOpeningHoursSpecification[];
  serviceType?: IServiceType[];
  employees: IPerson[];
  founders: IPerson[];
  knowsLanguage: any[];
  photos?: IPhoto[];
  displayed?: IDisplayed;
  logo?: IPhoto;
  communication?: ICommunication;
  updatedAt?: string;
  latitude?: number;
  longitude?: number;
  myAddresses?: Array<Addresses>;
  slug?: string;
  sponsorshipCode?: string;
  opinion?: number;
  opinionCount?: number;
  backgroundImage?: IBackgroundImage;
  highlightImg?: string;
  logoImg?: string;
  type?: string;
  thirdParty: {
    facebook: {
      accessToken: string;
      userID: string;
    };
  };
  calendarId?: string;
  isRoaming?: boolean;
  statistics?: Statistics;
  quotas?: IQuotas;
  configs?: IConfig[];
  isPublic?: boolean;
  blackListPersons?: string[];
  bankAlias?: IBankAlias;
}

export interface Addresses {
  types: Array<string>;
  _id?: string;
  id: string;
  uri: string;
  originId: string;
  country: string;
  locality: string;
  name: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  streetAddress: string;
  complementaryStreetAddress: string;
  region: string;
  department: string;
  createdAt: string;
  statistics?: Statistics;
  type?: string;
}

export interface Statistics {
  firstVisit?: Date;
  totalOrder?: number;
  amountTotalOrder?: number;
  subscription?: Subscription;
  opinion?: number;
  opinionCount?: number;
}

export interface Subscription {
  id: string;
  uri: string;
  name: string;
  ownerUri: string; // Créateur du coupon (organization). `null` si admin ?
  consumerId: string;
  activeFrom?: Date; // Dates de validité
  activeUntil?: Date;
  frequency?: OfferFrequencyEnum;
  enabled: boolean;
  disabledAt: Date;
  sourceUri: string;
  contextUri: string;
  metadatas: { [key: string]: any };
  createdAt: Date;
}

export enum OfferFrequencyEnum {
  ONCE = 'once',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export interface ILocation {
  id?: string;
  country?: string;
  locality?: string;
  region?: string;
  postalCode?: string;
  streetAddress?: string;
  additionalAddress?: string;
  latitude?: string | number;
  longitude?: string | number;
  name?: string;
}

export interface ILogo {
  id: string;
  caption: Caption;
  uri: string;
}

export interface IBackgroundImage {
  id: string;
  uri: string;
  createdAt: string;
  categoryUri: string;
  caption?: Caption;
  thumbnail?: Thumbnail;
  domain: string;
  consumerId: string;
}

export interface IPhoto {
  caption?: Caption;
  thumbnail?: Thumbnail;
  id: string;
  width: number;
  height: number;
  uri: string;
  consumerId: string;
  category?: string;
  createdAt?: string;
  domain?: string;
}

export interface Caption {
  id: string;
  size: number;
  contentUrl: string;
  description?: string;
  name: string;
  encodingFormat: string;
}

export interface Thumbnail {
  caption: Caption;
  width: number;
  height: number;
}

export interface IQuotas {
  'notification:email'?: Notification;
  'notification:sms'?: Notification;
  'shop:product:plan'?: AppointmentQuota;
}

export interface AppointmentQuota {
  type: string;
  frequency: string;
  quotaType: string;
  amount: number;
}
export interface Notification {
  id: string;
  ownerUri: string;
  type: string;
  amount: number;
  consumerId: string;
  createdAt: string;
  uri: string;
  sources: Source[];
  updatedAt: string;
}

export interface Source {
  amount: number;
  createdFrom: string;
  id: string;
  lastApply: string;
  nextApply: string;
  remaining: number;
  uri: string;
  createdAt: string;
}

/**
 * Days of week
 */
export enum DaysOfWeek {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

/**
 * Day type
 */
export enum DayType {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export interface IOpeningTime {
  id: string;
  // As date
  opens: string;
  // As date
  closes: string;
}

export interface IDays {
  day: DayType;
  isOpen: boolean;
  openingSlots: IOpeningTime[];
}

export interface IBankAlias {
  creditedUserId: string;
  walletId: string;
  type: string;
  country: string;
  ownerName: string;
  active: boolean;
  iban: string;
  bic: string;
  createdAt: Date;
  updatedAt?: Date;
}
