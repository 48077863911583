import { AxiosError, AxiosResponse } from 'axios';
import { IPerson } from 'person';
import { Dispatch } from 'redux';
import { AppThunk, generateErrorHandlerAction } from 'store';

import { IOrganization } from '../../entities/organization.entity';
import { EmployeeApi } from '../api/employee.api';
import {
  fetchAddEmployee,
  fetchAddEmployeeFailed,
  fetchAddEmployeeSucceeded,
  fetchDeleteEmployee,
  fetchDeleteEmployeeFailed,
  fetchDeleteEmployeeSucceeded,
  fetchUpdateEmployee,
  fetchUpdateEmployeeFailed,
  fetchUpdateEmployeeSucceeded,
} from '../slices/employee.slice';

export const addEmployeeRequest = (
  organizationId: string,
  employee: Partial<IPerson>,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrganization> | null = null;
  dispatch(fetchAddEmployee());

  try {
    if (employee && organizationId) {
      response = await EmployeeApi.addEmployee(organizationId, employee);
    } else {
      dispatch(
        fetchAddEmployeeFailed({
          isAxiosError: false,
          message: 'Employee not created',
          name: 'Format Error',
          stack: '',
        }),
      );
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchAddEmployeeFailed,
      ),
    );
    onError(error);
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set founder
    dispatch(fetchAddEmployeeSucceeded());
    onError(undefined);
  }
};

export const updateEmployeeRequest = (
  organizationId: string,
  accountId: string,
  employee: Partial<IPerson>,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrganization> | null = null;
  dispatch(fetchUpdateEmployee());

  try {
    if (employee && organizationId) {
      response = await EmployeeApi.updateEmployee(
        organizationId,
        accountId,
        employee,
      );
    } else {
      dispatch(
        fetchUpdateEmployeeFailed({
          isAxiosError: false,
          message: 'Employee not updated',
          name: 'Format Error',
          stack: '',
        }),
      );
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchAddEmployeeFailed,
      ),
    );
    onError(error);
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set founder
    dispatch(fetchUpdateEmployeeSucceeded());
    onError(undefined);
  }
};

export const deleteEmployeeRequest = (
  organizationId: string,
  employeeId: string,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchDeleteEmployee());

  try {
    await EmployeeApi.deleteEmployee(organizationId, employeeId);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchDeleteEmployeeFailed({
        isAxiosError,
        message,
        name,
        code: statusCode,
        stack,
      }),
    );
    onError(err);
    return;
  }

  dispatch(fetchDeleteEmployeeSucceeded());
  onError(undefined);
  return;
};
