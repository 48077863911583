export interface IKYCDocument {
  id: string;
  media: IMedia;
  type: KycDocumentType;
}

export interface IMedia {
  id: string;
  caption?: {
    id: string;
    size: number;
    contentUrl: string;
    description: string;
    name: string;
    encodingFormat: string;
  };
  uri: string;
  domain: string;
  consumerId: string;
}

export enum KycDocumentType {
  'IDENTITY_PROOF' = 'IDENTITY_PROOF',
  'REGISTRATION_PROOF' = 'REGISTRATION_PROOF',
  'ARTICLES_OF_ASSOCIATION' = 'ARTICLES_OF_ASSOCIATION',
  'ADDRESS_PROOF' = 'ADDRESS_PROOF',
}
