import { useDispatch, useSelector } from 'react-redux';

import { Loyalty } from '../../entities/loyalty.entity';
import {
  loyaltiesSelector,
  loyaltyErrorSelector,
  loyaltyLoadingSelector,
} from '../slices/loyalty.slice';
import {
  getLoyaltyRequest,
  updateLoyaltyRequest,
} from '../thunks/loyalty.thunks';

export const useLoyalty = () => {
  const dispatch = useDispatch();

  const getLoyalty = (organizationId: string) => {
    dispatch(getLoyaltyRequest(organizationId));
  };

  const updateLoyalty = (organizationId: string, data: Partial<Loyalty>) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateLoyaltyRequest(organizationId, data, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const loyalties = useSelector(loyaltiesSelector);
  const error = useSelector(loyaltyErrorSelector);
  const loading = useSelector(loyaltyLoadingSelector);

  return {
    error,
    loading,
    loyalties,
    getLoyalty,
    updateLoyalty,
  };
};
