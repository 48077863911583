import axios from 'axios';
import { IPagination } from 'shared-entities/pagination.entity';

import { IBugReport } from '../../entities/bug.report.entity';

export class BugReportsApi {
  public static getBugreports = async (
    page: number,
    itemsPerPage: number,
    params?: any,
  ) => {
    return axios.get<IPagination<IBugReport>>(`/api/bug-reports`, {
      params: {
        page,
        itemsPerPage,
        ...params,
      },
    });
  };
}
