import { IOrganization } from 'organization';
import { IPerson } from 'person';
import { IView } from 'shared-entities/pagination.entity';
import { IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAverage, IOpinion } from '../../entities/opinions.entity';

const initialState: {
  list: { [key: string]: IOpinion<any, any> };
  listReceived: {
    [key: string]: IOpinion<IPerson, IOrganization>;
  };
  listGiven: { [key: string]: IOpinion<IOrganization, IPerson> };
  error: { [key: string]: IPayloadError };
  loading: { [key: string]: boolean };
  view: { [key: string]: IView | undefined };
  average: IAverage;
} = {
  list: {},
  listReceived: {},
  listGiven: {},
  error: { listReceived: undefined, listGiven: undefined },
  loading: { listReceived: false, listGiven: false },
  view: {
    list: {
      page: 1,
      itemsPerPage: 3,
      total: 0,
    },
  },
  average: {} as IAverage,
};

export type OpinionState = typeof initialState;

const opinionSlice = createSlice({
  reducers: {
    fetchClear: (state: OpinionState, action: PayloadAction) => {
      state.list = {};
      state.error = {};
      state.loading = {};
      state.view = {
        list: {
          page: 1,
          itemsPerPage: 3,
          total: 0,
        },
      };
    },
    fetchOpinionsRequest: (
      state: OpinionState,
      { payload }: PayloadAction<{ key?: string }>,
    ) => {
      state.loading[payload.key] = true;
    },
    fetchOpinionsSucceeded: (
      state: OpinionState,
      {
        payload,
      }: PayloadAction<{
        list?: { [key: string]: IOpinion<any, any> };
        average?: IAverage;
        view?: IView;
        key?: string;
        type?: string;
      }>,
    ) => {
      if (payload.type === 'received') {
        state.listReceived = { ...state.listReceived, ...payload.list };
        state.loading[payload.key || 'listReceived'] = false;
      } else if (payload.type === 'given') {
        state.listGiven = { ...state.listGiven, ...payload.list };
        state.loading[payload.key || 'listGiven'] = false;
      } else {
        state.list = { ...state.list, ...payload.list };
        state.loading[payload.key || 'list'] = false;
      }

      if (payload.average) {
        state.average = payload.average;
      }

      if (payload.view) {
        state.view[payload.key] = payload.view;
      }
    },
    fetchOpinionsFailed: (
      state: OpinionState,
      { payload }: PayloadAction<{ key?: string; error: IPayloadError }>,
    ) => {
      state.error[payload.key] = payload.error;
      state.loading[payload.key] = false;
    },
  },
  initialState,
  name: 'opinions',
});

export const {
  fetchClear,
  fetchOpinionsFailed,
  fetchOpinionsRequest,
  fetchOpinionsSucceeded,
} = opinionSlice.actions;

export const { reducer: opinionReducer } = opinionSlice;
