import { calendarInstance } from 'store';

import { ICalendar } from '../../entities';
import {
  IAddEventCalendarPayload,
  ICalendarEvent,
  IEventOwnerFilter,
} from '../../entities/calendar.event.entity';

export class CalendarApi {
  public static CALENDAR_EVENTS_ENDPOINT = '/api/calendar-events';
  public static CALENDAR_ENDPOINT = '/api/calendar';

  public static getAllEventsByOwner = (params: IEventOwnerFilter) =>
    calendarInstance.get(`${CalendarApi.CALENDAR_EVENTS_ENDPOINT}/owner`, {
      params,
    });

  public static addEvent = (
    calendarId: string,
    params: IAddEventCalendarPayload,
  ) =>
    calendarInstance.post<ICalendarEvent>(
      `${CalendarApi.CALENDAR_ENDPOINT}/${calendarId}/event`,
      {
        ...params,
      },
    );

  public static updateEvent = (
    calendarId: string,
    eventId: string,
    params: Partial<IAddEventCalendarPayload>,
  ) =>
    calendarInstance.put<ICalendarEvent>(
      `${CalendarApi.CALENDAR_ENDPOINT}/${calendarId}/event/${eventId}`,
      {
        ...params,
      },
    );

  public static deleteEvent = (calendarId: string, eventId: string) =>
    calendarInstance.delete<ICalendarEvent>(
      `${CalendarApi.CALENDAR_ENDPOINT}/${calendarId}/event/${eventId}`,
    );

  public static findOne = (params?: { [key: string]: number | string }) =>
    calendarInstance.get(`/api/calendar/find-one`, {
      params,
    });

  public static update = (calendarId: string, calendar: Partial<ICalendar>) =>
    calendarInstance.put(`/api/calendar/${calendarId}`, calendar);
}
