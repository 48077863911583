import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';
import { v4 as uuidv4 } from 'uuid';
import { IProduct } from '../../entities/product.entity';
import { ProductApi } from '../api/product.api';
import {
  fetchAddProduct,
  fetchAddProductFailed,
  fetchAddProductSucceeded,
} from '../slices/product.slice';

export const addProduct = (
  partialProduct: Partial<IProduct>,
): AppThunk<Promise<IProduct>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IProduct> | null = null;

  dispatch(fetchAddProduct());

  const request = partialProduct;

  try {
    response = await ProductApi.addProduct({
      id: uuidv4(),
      ...request,
    });
  } catch (error) {
    dispatch(
      fetchAddProductFailed({
        ...error,
        message: error?.response?.data?.message ?? 'An error occured',
      }),
    );
    throw error;
  }

  if (response && response.data && response.data.id) {
    dispatch(fetchAddProductSucceeded(response.data));
    return response.data;
  }

  throw new Error();
};
