import Axios, { AxiosResponse } from 'axios';
import { IPlace } from 'place/entities';
import { useEffect, useState } from 'react';
import { IPagination } from 'shared-entities/pagination.entity';
import { placeInstance } from 'store';

export const usePlaceAutocomplete = (city?: boolean) => {
  const [error, setError] = useState<string>('');
  // Contient la recherche texte
  const [searchAutocomplete, setSearchAutocomplete] = useState<string>('');

  // Contient un produit existant avec un id
  const [selectedAutocomplete, setSelectedAutocomplete] = useState<string>();
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [optionsAutocomplete, setOptionsAutocomplete] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    if (!openAutocomplete) {
      return undefined;
    }

    (async () => {
      if (active) {
        setLoading(true);
        let response: AxiosResponse<IPagination<IPlace>> | null = null;

        try {
          response = await placeInstance.get(
            `/api/public/places?query=${searchAutocomplete}${
              city ? '&city=1' : ''
            }`,
            { cancelToken: source.token },
          );
          if (response?.data) {
            const {
              data: { results },
            } = response;
            setSelectedAutocomplete('');

            if (active) {
              setOptionsAutocomplete(results);
            }
          }
        } catch (err) {
          setLoading(false);

          setError('common.unknown-error');
        }

        setLoading(false);
      }
    })();

    return () => {
      active = false;
      source.cancel();
      setLoading(false);
    };
  }, [openAutocomplete, searchAutocomplete]);

  useEffect(() => {
    if (!openAutocomplete) {
      setOptionsAutocomplete([]);
    }
  }, [openAutocomplete]);

  return {
    selectedAutocomplete,
    setSearchAutocomplete,
    setSelectedAutocomplete,
    openAutocomplete,
    setOpenAutocomplete,
    optionsAutocomplete,
    setOptionsAutocomplete,
    loading,
    setLoading,
    error,
  };
};
