import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { ProductApi } from '../api/product.api';
import {
  fetchDeleteOption,
  fetchDeleteOptionFailed,
  fetchDeleteOptionSucceeded,
} from '../slices/product.slice';

export const deleteOptionRequest = (
  optionId: string,
  productId: string,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchDeleteOption());

  try {
    await ProductApi.deleteOption(optionId, productId);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (responseError?.data) {
      const {
        data: { statusCode, message },
      } = responseError;
      // - Fetch failed, set error
      dispatch(
        fetchDeleteOptionFailed({
          isAxiosError,
          message,
          name,
          code: statusCode,
          stack,
        }),
      );
      onError(err);
      return;
    }
    dispatch(
      fetchDeleteOptionFailed({
        isAxiosError,
        message: '',
        name: '',
        code: '404',
        stack: '',
      }),
    );
    onError(err);
    return;
  }

  dispatch(fetchDeleteOptionSucceeded({ productId, optionId }));
  onError(undefined);
  return;
};
