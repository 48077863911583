import {
  addLogo as addLogoThunk,
  addOrganizationBackgroundImageWithGalleryPicture,
  addOrganizationPictureWithGalleryPicture,
  addPictureRequest,
  deleteLogo as deleteLogoThunk,
  removeOrganizationPictureRequest,
} from '../thunks/organization.picture.thunk';

import { IPicture } from 'gallery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EventsStatusListener, ReduxDispatch } from 'store';

export const usePicture = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<ReduxDispatch>();
  const [uploadProgress, setUploadProgress] = useState(0);

  const addOrganizationPictureWithMedia = async (
    organizationId: string,
    media: IPicture,
  ) => {
    setLoading(true);
    try {
      await dispatch(
        addOrganizationPictureWithGalleryPicture(organizationId, media),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const addOrganizationBackgroundImageWithMedia = async (
    organizationId: string,
    media: IPicture,
  ) => {
    setLoading(true);
    console.log({ media });
    try {
      await dispatch(
        addOrganizationBackgroundImageWithGalleryPicture(organizationId, media),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const addOrganizationPicture = async (
    organizationId: string,
    media: File,
  ) => {
    setLoading(true);
    try {
      await dispatch(addPictureRequest(organizationId, media));
      EventsStatusListener.getInstance().publishSuccessMessage(
        t('common.notification.update-success'),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const addLogo = async (organizationId: string, file: File) => {
    setUploadProgress(0);
    setLoading(true);
    try {
      await dispatch(
        addLogoThunk(organizationId, file, (progressEvent) => {
          setUploadProgress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total),
          );
        }),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const deleteLogo = async (organizationId: string) => {
    setLoading(true);
    try {
      await dispatch(deleteLogoThunk(organizationId));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const removeOrganizationPicture = async (
    organizationId: string,
    mediaId: string,
  ) => {
    setLoading(true);
    try {
      await dispatch(removeOrganizationPictureRequest(organizationId, mediaId));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  return {
    addOrganizationBackgroundImageWithMedia,
    addOrganizationPicture,
    uploadProgress,
    addLogo,
    deleteLogo,
    removeOrganizationPicture,
    addOrganizationPictureWithMedia,
    loading,
  };
};
