import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { AppThunk } from 'store';

import { GalleryApi } from '../api/gallery.api';
import {
  fetchGallery,
  fetchGalleryFailed,
  fetchGallerySucceeded,
} from '../slices/gallery.slice';

export const getGalleryThunk = (): AppThunk<Promise<void>> => async (
  dispatch: Dispatch<any>,
) => {
  let response: AxiosResponse = null;
  dispatch(fetchGallery());
  try {
    response = await GalleryApi.getGallery();
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    dispatch(fetchGalleryFailed({ error, key: 'list' }));
    throw err;
  }
  dispatch(fetchGallerySucceeded({ list: response.data, key: 'list' }));
};
