import { IAppState, IPayloadError } from 'store';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDiscount } from '../../entities/discount.entity';

const initialState: {
  list: { [key: string]: Partial<IDiscount> };
  loading: { [key: string]: boolean };
  error: { [key: string]: IPayloadError };
} = {
  list: {},
  loading: {},
  error: {},
};

export type DiscountState = typeof initialState;

export const discountsListSelector = (state: IAppState) => state.discount.list;
export const discountsListLoadingSelector = (state: IAppState) =>
  state.discount.loading;

export const discountByIdSelector = (discountId: string) =>
  createSelector(
    discountsListSelector,
    (list: { [key: string]: IDiscount }) => {
      return list[discountId];
    },
  );
export const makeDiscountByOwnerUri = createSelector(
  discountsListSelector,
  (list: { [key: string]: IDiscount }) => (ownerUri: string) => {
    return Object.values(list).filter((item) => item.ownerUri === ownerUri);
  },
);
const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    fetchClearDiscount: (state: DiscountState, _action: PayloadAction) => {
      state.list = {};
      state.loading = {};
      state.error = {};
    },
    fetchDiscountSuccedded: (
      state: DiscountState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: Partial<IDiscount> };
        id?: string;
      }>,
    ) => {
      const { payload } = action;
      if (payload.key === 'delete') {
        delete state.list[payload.id];
      } else {
        state.list = { ...state.list, ...payload.data };
      }

      const keys = Object.keys(payload);

      keys.map((key) => {
        state.loading[key] = false;
      });
      state.loading[payload.key] = false;
    },

    fetchDiscountFailed: (
      state: DiscountState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IPayloadError };
        error: IPayloadError;
      }>,
    ) => {
      const { payload } = action;

      const keys = Object.keys(payload.data);

      keys.map((key) => {
        state.loading[key] = false;
      });
      keys.map((key) => {
        state.error[key] = payload[key];
      });

      state.error[payload.key] = payload.error;
    },
    fetchAddDiscount: (state: DiscountState, _action: PayloadAction) => {
      state.loading['add'] = true;
    },
    fetchGetDiscounts: (state: DiscountState, _action: PayloadAction) => {
      state.loading['list'] = true;
    },
    fetchDeleteDiscounts: (state: DiscountState, _action: PayloadAction) => {
      state.loading['delete'] = true;
    },
    fetchUpdateDiscount: (
      state: DiscountState,
      action: PayloadAction<string>,
    ) => {
      state.loading['update'] = true;
    },
  },
});

export const {
  fetchAddDiscount,
  fetchDiscountFailed,
  fetchDiscountSuccedded,
  fetchClearDiscount,
  fetchGetDiscounts,
  fetchUpdateDiscount,
  fetchDeleteDiscounts,
} = discountSlice.actions;

export const { reducer: discountReducer } = discountSlice;
