import { IAppState, IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Loyalty } from '../../entities/loyalty.entity';

const initialState: {
  loading: { [key: string]: boolean };
  error: { [key: string]: IPayloadError };
  list: { [key: string]: Loyalty };
} = {
  loading: {},
  error: {},
  list: {},
};

export type LoyaltyState = typeof initialState;

export const loyaltyLoadingSelector = (state: IAppState) =>
  state.loyalty.loading;
export const loyaltyErrorSelector = (state: IAppState) => state.loyalty.error;

export const loyaltiesSelector = (state: IAppState) => state.loyalty.list;

const loyaltySlice = createSlice({
  name: 'loyalty',
  initialState,
  reducers: {
    fetchClearLoyalty: (state: LoyaltyState, _action: PayloadAction) => {
      state.list = {};
      state.error = {};
      state.loading = {};
    },
    fetchLoyalty: (state: LoyaltyState, _action: PayloadAction) => {
      state.loading['list'] = true;
    },

    fetchLoyaltySucceeded: (
      state: LoyaltyState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: Loyalty };
        id?: string;
      }>,
    ) => {
      const { payload } = action;

      if (payload.key === 'delete') {
        delete state.list[payload.id];
      } else {
        state.list = { ...state.list, ...payload.data };
      }

      state.loading[payload.key] = false;
    },

    fetchLoyaltyFailed: (
      state: LoyaltyState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IPayloadError };
        error: IPayloadError;
      }>,
    ) => {
      const { payload } = action;
      const key = payload.key;

      state.loading[key] = false;
      state.error[key] = payload.error;
    },
  },
});

export const {
  fetchLoyaltyFailed,
  fetchLoyaltySucceeded,
  fetchLoyalty,
  fetchClearLoyalty,
} = loyaltySlice.actions;

export const { reducer: loyaltyReducer } = loyaltySlice;
