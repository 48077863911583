import axios from 'axios';

export class BasketApi {
  public static BASKET_ENDPOINT = '/api/shop/baskets';

  public static getCurrentBasket = () => {
    return axios.get(BasketApi.BASKET_ENDPOINT);
  };

  public static validateAndPay = (meansOfPayment: string) => {
    return axios.post(`${BasketApi.BASKET_ENDPOINT}/validate-and-pay`, {
      meansOfPayment,
    });
  };

  public static addSponsorCodeToBasket = (code: string) => {
    return axios.post(`${BasketApi.BASKET_ENDPOINT}/set-sponsorship-code`, {
      code,
    });
  };
}
