import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { AppThunk, generateErrorHandlerAction } from 'store';
import { v4 } from 'uuid';

import { IOrganization } from '../../entities/organization.entity';
import { OrganizationApi } from '../api/organization.api';
import {
  fetchAddOrganizationBackgroundImage,
  fetchAddOrganizationBackgroundImageFailed,
  fetchAddOrganizationBackgroundImageSucceeded,
  fetchAddOrganizationPicture,
  fetchAddOrganizationPictureFailed,
  fetchAddOrganizationPictureSucceeded,
  fetchRemoveOrganizationPicture,
  fetchRemoveOrganizationPictureFailed,
  fetchRemoveOrganizationPictureSucceeded,
} from '../slices/organization.slice';
import { IPicture } from 'gallery';

export const addPictureRequest = (
  organizationId: string,
  media: File,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse | null = null;
  dispatch(fetchAddOrganizationPicture());
  const pictureId = v4();
  try {
    if (organizationId && media) {
      response = await OrganizationApi.addOrganizationPicture(organizationId, {
        pictureId,
        picture: media,
      });
    } else {
      dispatch(
        fetchAddOrganizationPictureFailed({
          isAxiosError: false,
          message: 'Picture not added',
          name: 'Format Error',
          stack: '',
        }),
      );
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchAddOrganizationPictureFailed,
      ),
    );
    throw error;
  }
  if (response && response.status && response.status === 200) {
    const { data } = response;
    // - Dispatch succeeded, set Organization
    dispatch(fetchAddOrganizationPictureSucceeded(data));
  }
};

export const removeOrganizationPictureRequest = (
  organizationId: string,
  mediaId: string,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse | null = null;
  dispatch(fetchRemoveOrganizationPicture());

  try {
    if (organizationId && mediaId) {
      response = await OrganizationApi.removeOrganizationPicture(
        organizationId,
        mediaId,
      );
    } else {
      dispatch(
        fetchRemoveOrganizationPictureFailed({
          isAxiosError: false,
          message: 'Picture not added',
          name: 'Format Error',
          stack: '',
        }),
      );
      return;
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchRemoveOrganizationPictureFailed,
      ),
    );
    throw error;
  }
  if (response && response.status && response.status === 200) {
    const { data } = response;
    // - Dispatch succeeded, set Organization
    dispatch(fetchRemoveOrganizationPictureSucceeded(data));
  }
};

export const addOrganizationPictureWithGalleryPicture = (
  organizationId: string,
  picture: IPicture,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrganization> | undefined = null;
  dispatch(fetchAddOrganizationPicture());
  try {
    response = await OrganizationApi.addPictureWithExistingOne(
      organizationId,
      picture,
      v4(),
    );
  } catch (err) {
    dispatch(
      generateErrorHandlerAction(
        err as AxiosError<any>,
        fetchAddOrganizationPictureFailed,
      ),
    );
    throw err;
  }
  dispatch(fetchAddOrganizationPictureSucceeded(response.data));
};

export const addOrganizationBackgroundImageWithGalleryPicture = (
  organizationId: string,
  picture: IPicture,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrganization> | undefined = null;
  dispatch(fetchAddOrganizationBackgroundImage());
  try {
    response = await OrganizationApi.addBackgroundImageWithExistingOne(
      organizationId,
      picture,
      v4(),
    );
  } catch (err) {
    dispatch(
      generateErrorHandlerAction(
        err as AxiosError<any>,
        fetchAddOrganizationBackgroundImageFailed,
      ),
    );
    throw err;
  }
  dispatch(fetchAddOrganizationBackgroundImageSucceeded(response.data));
};

export const addLogo = (
  organizationId: string,
  file: File,
  progressEvent?: (progressEvent: any) => void,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrganization> | undefined = null;
  dispatch(fetchAddOrganizationPicture());
  try {
    response = await OrganizationApi.addLogo(
      organizationId,
      v4(),
      file,
      progressEvent,
    );
  } catch (err) {
    dispatch(
      generateErrorHandlerAction(
        err as AxiosError<any>,
        fetchAddOrganizationPictureFailed,
      ),
    );
    throw err;
  }
  dispatch(fetchAddOrganizationPictureSucceeded(response.data));
};

export const deleteLogo = (
  organizationId: string,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrganization> | undefined = null;
  dispatch(fetchAddOrganizationPicture());
  try {
    response = await OrganizationApi.deleteLogo(organizationId);
  } catch (err) {
    dispatch(
      generateErrorHandlerAction(
        err as AxiosError<any>,
        fetchAddOrganizationPictureFailed,
      ),
    );
    throw err;
  }
  dispatch(fetchAddOrganizationPictureSucceeded(response.data));
};
