import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';
import { IOrder } from '../../entities/order.entity';
import { OrderApi } from '../api/order.api';
import {
  fetchOrders,
  fetchOrdersFailed,
  fetchOrdersSucceeded,
} from '../slices/order.slice';

export interface ISort {
  field?: string;
  order?: string;
}

export const getOrderRequest = (id: string): AppThunk => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(fetchOrders());

  let response: AxiosResponse<IOrder> | null = null;

  try {
    response = await OrderApi.getOrder(id);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    dispatch(
      fetchOrdersFailed({
        key: 'list',
        error: { isAxiosError, message, name, code: statusCode, stack },
      }),
    );
    return;
  }
  if (response && response.data) {
    dispatch(
      fetchOrdersSucceeded({
        key: 'list',
        data: { [response.data?.id]: response.data },
      }),
    );
  }
};
