import {
  clientsSelector,
  personsByTypeSelector,
  personsItemsPerPageSelector,
  personsPageSelector,
} from '../slices/get-persons.slice';
import {
  personsErrorSelector,
  personsLoadingSelector,
  personsSelector,
  personsTotalSelector,
} from '../slices/person.slice';
import {
  addPersonRequest,
  getPersonsRequest,
  getSearchPersonsRequest,
} from '../thunks/person.thunk';

import { IPerson } from 'person';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState, ReduxDispatch } from 'store';

export const usePersons = () => {
  const dispatch = useDispatch<ReduxDispatch>();

  const getPersons = (page = 1, itemsPerPage = 10, params = {}) => {
    dispatch(getPersonsRequest(page, itemsPerPage, params));
  };

  const getSearchPersons = (page = 1, itemsPerPage = 10, params = {}) => {
    dispatch(getSearchPersonsRequest(page, itemsPerPage, params));
  };

  const addPerson = async (personData: Partial<IPerson>) =>
    dispatch(addPersonRequest(personData));

  const personsByType = (personType: string) =>
    useSelector((state: IAppState) => personsByTypeSelector(personType, state));

  const clients = useSelector(clientsSelector);
  const persons = useSelector(personsSelector);
  const error = useSelector(personsErrorSelector);
  const loading = useSelector(personsLoadingSelector);
  const total = useSelector(personsTotalSelector);
  const page = useSelector(personsPageSelector);
  const itemsPerPage = useSelector(personsItemsPerPageSelector);

  return {
    persons,
    getPersons,
    error,
    loading,
    total,
    addPerson,
    page,
    offset: itemsPerPage,
    getSearchPersons,
    personsByType,
    clients,
  };
};
