import { AxiosResponse } from 'axios';
import { normalize, schema } from 'normalizr';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { IConfig } from '../../entities/config.entity';
import { ConfigApi } from '../api/config.api';
import {
  fetchConfig,
  fetchConfigFailed,
  fetchConfigSucceeded,
} from '../slices/config.slice';

export const update = (
  partialConfigs: Partial<IConfig>[],
): AppThunk<Promise<IConfig>> => async (dispatch: Dispatch) => {
  let response: AxiosResponse<IConfig> = null;
  dispatch(fetchConfig());

  try {
    response = await ConfigApi.update({
      configs: partialConfigs,
    });

    const configSchema = new schema.Entity(
      'config',
      {},
      { idAttribute: 'code' },
    );
    const {
      entities: { config },
    } = normalize(partialConfigs, [configSchema]);

    dispatch(fetchConfigSucceeded({ data: config }));
    return response.data;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: null,
      toJSON: null,
    };
    dispatch(fetchConfigFailed(error));
    throw error;
  }
};
