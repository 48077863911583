import { IOrganization } from 'organization';

export interface IRate {
  id: string;
  rate: number;
  status: RateStatus;
  comment?: string;
  organization: Partial<IOrganization>;
  appointmentParent: string;
}

export enum RateStatus {
  STATUS_PUBLIC = 'published',
  STATUS_REFUSED = 'refused',
  STATUS_CLAIMED = 'is_claimed',
}
