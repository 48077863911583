import { fetchPersonsMeSucceeded, IPerson } from 'person';
import { Dispatch } from 'redux';
import { AppThunk, generateErrorHandlerAction } from 'store';
import PaymentApi from '../api/payment.api';
import {
  fetchSetDefaultCard,
  fetchSetDefaultCardFailed,
  fetchSetDefaultCardSucceeded,
} from '../slices/payment.slice';

export const setDefaultCard = (
  cardId: string,
  actionToDispatch?: (user?: IPerson) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchSetDefaultCard({ cardId }));
  let userUpdated: IPerson | undefined;
  try {
    const { data } = await PaymentApi.postDefaultCard(cardId);

    userUpdated = data;
    dispatch(fetchSetDefaultCardSucceeded({ cardId }));
  } catch (err) {
    dispatch(
      generateErrorHandlerAction({ ...err, cardId }, fetchSetDefaultCardFailed),
    );
    throw err;
  }
  if (actionToDispatch) {
    dispatch(actionToDispatch(userUpdated));
  } else {
    dispatch(fetchPersonsMeSucceeded(userUpdated));
  }
};
