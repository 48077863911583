import { ICategory, ICategoryNested } from '../../entities/category.type';
import {
  categoriesArraySelector,
  categoriesByOrganizationSelector,
  categoriesErrorSelector,
  categoriesLoadingSelector,
  categoriesSelector,
  categoryByIdSelector,
  childrenPrivateCategoryByUriSelector,
  parentCategoriesSelector,
  privateCategoryBySlugSelector,
  publicCategoriesSelector,
  publicCategoryBySlugSelector,
  publicParentCategoriesSelector,
} from '../slices/category.slice';
import { addCategoryRequest } from '../thunks/add-category.thunk';
import { deleteCategoryRequest } from '../thunks/delete-category.thunk';
import {
  getCategoriesByOrganizationRequest,
  getPublicCategoriesRequest,
} from '../thunks/get-category.thunk';
import { updateCategoryRequest } from '../thunks/update-category.thunk';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventsStatusListener, ReduxDispatch } from 'store';

export const useCategory = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const { t } = useTranslation();

  const getPublicCategories = (filters?: { [k: string]: any }) => {
    return dispatch(getPublicCategoriesRequest(filters));
  };

  const getCategoriesByOrganization = (
    organizationId?: string,
    filters?: { [key: string]: any },
  ) => {
    return dispatch(
      getCategoriesByOrganizationRequest(organizationId, filters),
    );
  };

  const updateCategory = (id: string, values: Partial<ICategory>) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateCategoryRequest(id, values, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  const addCategory = (values: Partial<ICategory>, parentId?: string) => {
    const categoryValues = {
      taxeValue: values.taxeValue ? values.taxeValue : 20,
      ...values,
    };

    return new Promise((resolve, reject) => {
      dispatch(
        addCategoryRequest(categoryValues, parentId, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.add-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.add-success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  const deleteCategory = (id: string) => {
    return new Promise((resolve, reject) => {
      dispatch(
        deleteCategoryRequest(id, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.delete-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.delete-success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  const categories = useSelector(categoriesSelector);
  const categoriesArray = useSelector(categoriesArraySelector);
  const parentCategories = useSelector(parentCategoriesSelector);

  const getCategoryById = (categoryId: string) =>
    useSelector(categoryByIdSelector(categoryId));

  const categoriesByOrganization = (organizationId: string) =>
    useSelector(categoriesByOrganizationSelector(organizationId));
  const publicCategories: {
    [key: string]: ICategoryNested;
  } = useSelector(publicCategoriesSelector);
  const isCategoriesLoading = useSelector(categoriesLoadingSelector);
  const isCategoriesError = useSelector(categoriesErrorSelector);
  const publicParentCategories = useSelector(publicParentCategoriesSelector);

  const publicCategoryBySlug = useSelector(publicCategoryBySlugSelector);
  const privateCategoryBySlug = useSelector(privateCategoryBySlugSelector);
  const childrenPrivateCategoryByUri = useSelector(
    childrenPrivateCategoryByUriSelector,
  );

  return {
    categoriesArray,
    publicCategories,
    categoriesByOrganization,
    parentCategories,
    getCategoryById,
    getPublicCategories,
    getCategoriesByOrganization,
    updateCategory,
    addCategory,
    deleteCategory,
    categories,
    isCategoriesLoading,
    isCategoriesError,
    publicParentCategories,
    publicCategoryBySlug,
    privateCategoryBySlug,
    childrenPrivateCategoryByUri,
  };
};
