import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';
import { IMangoPayResponse } from '../../entities/mango-pay.response';
import PaymentApi from '../api/payment.api';
import {
  fetchCredentials,
  fetchCredentialsFailed,
  fetchCredentialsSucceeded,
} from '../slices/payment.slice';

export const postCredentialRequest = (
  firstName: string,
  lastName: string,
  birthDate: string,
  email: string,
  callback?: (error?: { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchCredentials());
  let response: AxiosResponse<IMangoPayResponse> | null = null;

  try {
    response = await PaymentApi.postCredentials(
      firstName,
      lastName,
      birthDate,
      email,
    );
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    dispatch(
      fetchCredentialsFailed({
        isAxiosError,
        message,
        name,
        code: statusCode,
        stack,
      }),
    );
    if (callback) {
      callback({ message, statusCode });
    }
    return;
  }

  if (response && response.data) {
    // - Init manngo pay card registration on succeeded
    dispatch(fetchCredentialsSucceeded(response.data));
    if (callback) {
      callback();
    }
    return;
  }

  dispatch(
    fetchCredentialsFailed({
      isAxiosError: false,
      message: 'Unknown error',
      name: 'Unknown error',
      code: '404',
      stack: '',
    }),
  );
  if (callback) {
    callback({ message: 'Unknown error', statusCode: '404' });
  }
  return;
};
