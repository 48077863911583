import { IProduct } from '../../entities/product.entity';
import { ProductApi } from '../api/product.api';
import {
  fetchProductFailed,
  fetchProducts,
  fetchProductsFromOrganization,
  fetchProductsFromOrganizationFailed,
  fetchProductsFromOrganizationSucceeded,
  fetchProductSucceeded,
} from '../slices/product.slice';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { IPagination } from 'shared-entities/pagination.entity';
import { AppThunk } from 'store';

export const getProductsFromOrganizationRequest = (
  organizationUri?: string,
  categoryUri?: string,
): AppThunk => async (dispatch: Dispatch<any>) => {
  const ITEMS_PER_PAGE = 1000;
  dispatch(fetchProductsFromOrganization());

  let response: AxiosResponse<IPagination<IProduct>> | null = null;

  try {
    response = await ProductApi.getPublicProducts(undefined, ITEMS_PER_PAGE, {
      organizationUri,
      categoryUri,
    });
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchProductsFromOrganizationFailed({
        isAxiosError,
        message,
        name,
        code: statusCode,
        stack,
      }),
    );
    return;
  }
  if (response && response.data && response.data) {
    const products: IProduct[] = response.data.results;

    if (products) {
      const normalizeProducts: {
        [key: string]: IProduct;
      } = products.reduce((previousValue, currentValue) => {
        previousValue = {
          ...previousValue,
          [currentValue.id]: { ...currentValue },
        };
        return previousValue;
      }, {});
      dispatch(fetchProductsFromOrganizationSucceeded(normalizeProducts));
      return;
    }
  }
};

export const getProductsByOrganizationRequest = (
  organizationUri: string,
): AppThunk => async (dispatch: Dispatch<any>) => {
  const ITEMS_PER_PAGE = 1000;
  dispatch(fetchProducts());

  let response: AxiosResponse<IPagination<IProduct>> | null = null;

  try {
    response = await ProductApi.getPublicProducts(undefined, ITEMS_PER_PAGE, {
      organizationUri,
    });
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchProductFailed({
        key: 'list',
        error: {
          isAxiosError,
          message,
          name,
          code: statusCode,
          stack,
        },
      }),
    );
    return;
  }
  if (response && response.data && response.data) {
    const products: IProduct[] = response.data.results;

    if (products) {
      const normalizeProducts: {
        [key: string]: IProduct;
      } = products.reduce((previousValue, currentValue) => {
        previousValue = {
          ...previousValue,
          [currentValue.id]: { ...currentValue },
        };
        return previousValue;
      }, {});
      dispatch(fetchProductSucceeded({ key: 'list', data: normalizeProducts }));
      return;
    }
  }
};

export const getPublicProductsRequest = (
  params: { [key: string]: string | number } = {},
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchProducts());

  let response: AxiosResponse<IPagination<IProduct>> | null = null;
  const { page, itemsPerPage, ...rest } = params;
  try {
    response = await ProductApi.getPublicProducts(
      page as number,
      itemsPerPage as number,
      {
        ...rest,
      },
    );
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchProductFailed({
        key: 'list',
        error: {
          isAxiosError,
          message,
          name,
          code: statusCode,
          stack,
        },
      }),
    );
    return;
  }
  if (response && response.data && response.data) {
    const products: IProduct[] = response.data.results;

    if (products) {
      const normalizeProducts: {
        [key: string]: IProduct;
      } = products.reduce((previousValue, currentValue) => {
        previousValue = {
          ...previousValue,
          [currentValue.id]: { ...currentValue },
        };
        return previousValue;
      }, {});
      dispatch(
        fetchProductSucceeded({
          key: 'list',
          data: normalizeProducts,
          view: response?.data?.view,
          total: response?.data?.view?.total,
        }),
      );
      return;
    }
  }
};

export const getProductsRequest = (
  params: { [key: string]: string | number } = {},
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchProducts());

  let response: AxiosResponse<IPagination<IProduct>> | null = null;
  try {
    response = await ProductApi.getProducts(params);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchProductFailed({
        key: 'list',
        error: {
          isAxiosError,
          message,
          name,
          code: statusCode,
          stack,
        },
      }),
    );
    return;
  }
  if (response && response.data && response.data) {
    const products: IProduct[] = response.data.results;

    if (products) {
      const normalizeProducts: {
        [key: string]: IProduct;
      } = products.reduce((previousValue, currentValue) => {
        previousValue = {
          ...previousValue,
          [currentValue.id]: { ...currentValue },
        };
        return previousValue;
      }, {});
      dispatch(
        fetchProductSucceeded({
          key: 'list',
          data: normalizeProducts,
          view: response?.data?.view,
          total: response?.data?.view?.total,
        }),
      );
      return;
    }
  }
};
