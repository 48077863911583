import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { IRib } from 'shop';
import { AppThunk } from 'store';

import { OrganizationApi } from '../api/organization.api';
import {
  fetchRib,
  fetchRibFailed,
  fetchRibSuccedded,
} from '../slices/organization.slice';

export const getOrganizationRibThunk = (
  organizationId: string,
  onError?: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IRib> | null = null;
  dispatch(fetchRib({ key: 'get' }));

  try {
    response = await OrganizationApi.getRIB(organizationId);
  } catch (error) {
    dispatch(fetchRibFailed({ key: 'get', error: error }));
    onError(error);
  }
  if (response && response.data) {
    // - Dispatch succeeded, set person
    const { data } = response;
    dispatch(fetchRibSuccedded({ data: data, key: 'get' }));
    onError(undefined);
  }
};
