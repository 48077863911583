import { fetchPersonsMeSucceeded, IPerson } from 'person';
import { Dispatch } from 'redux';
import { AppThunk, generateErrorHandlerAction } from 'store';
import PaymentApi from '../api/payment.api';
import {
  fetchCardDeletionFailed,
  fetchCardDeletionRequest,
  fetchCardDeletionSucceeded,
} from '../slices/payment.slice';

export const deleteCard = (
  cardId: string,
  actionToDispatch?: (user?: IPerson) => void,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchCardDeletionRequest({ cardId }));
  let userUpdated: IPerson | undefined;
  try {
    const { data } = await PaymentApi.deleteCard(cardId);
    userUpdated = data;
    dispatch(fetchCardDeletionSucceeded({ cardId }));
  } catch (err) {
    dispatch(
      generateErrorHandlerAction({ ...err, cardId }, fetchCardDeletionFailed),
    );
    throw err;
  }
  if (actionToDispatch) {
    dispatch(actionToDispatch(userUpdated));
  } else {
    dispatch(fetchPersonsMeSucceeded(userUpdated));
  }
};
