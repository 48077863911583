import { IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISubscription } from '../../entities';

const initialState: {
  current: ISubscription;
  loadings?: { current: boolean; [key: string]: boolean };
  errors?: { current: IPayloadError; [key: string]: IPayloadError };
} = {
  current: undefined,
  loadings: { current: false },
  errors: { current: undefined },
};

export type SubscriptionState = typeof initialState;

export type IAppSubscriptionState = {
  subscriptions: SubscriptionState;
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    fetchCurrentSubscription: (state: SubscriptionState) => {
      state.loadings.current = true;
      state.errors.current = undefined;
    },
    fetchSubscriptionFailed: (
      state: SubscriptionState,
      { payload }: PayloadAction<{ key: string; error: IPayloadError }>,
    ) => {
      state.loadings[payload.key] = false;
      state.errors[payload.key] = payload.error;
    },
    fetchCurrentSubscriptionSucceeded: (
      state: SubscriptionState,
      action: PayloadAction<ISubscription>,
    ) => {
      state.loadings.current = false;
      state.current = action.payload;
    },
  },
});

export const {
  fetchCurrentSubscription,
  fetchCurrentSubscriptionSucceeded,
  fetchSubscriptionFailed,
} = subscriptionsSlice.actions;

export const { reducer: subscriptionsReducer } = subscriptionsSlice;
