import axios, { AxiosRequestConfig } from 'axios';
import FormData from 'form-data';
import { IPerson } from 'person';
import { IPagination } from 'shared-entities/pagination.entity';

export class PersonApi {
  public static PERSON_ENDPOINT = '/api/persons';

  public static getPersonIdFromPersonUri = (uri: string) =>
    uri.replace(`${PersonApi.PERSON_ENDPOINT}/`, '');

  public static getMe = (params?: { [key: string]: string }) =>
    axios.get('/api/persons/me', { params: params });

  public static updateMe = (userId: string, params: Partial<IPerson>) =>
    axios.put(`/api/persons/${userId}`, params);

  public static getPersons = (
    page: number,
    itemsPerPage: number,
    params?: any,
  ) =>
    axios.get<IPagination<IPerson>>(`/api/persons`, {
      params: {
        page,
        itemsPerPage,
        ...params,
      },
    });

  public static getPerson = (personId: string, analytics?: boolean) =>
    axios.get(`/api/persons/${personId}`, { params: { analytics } });

  public static disablePerson = (id: string) =>
    axios.post(`/api/persons/${id}/disable`);

  public static addToBlackList = (id: string) =>
    axios.post(`/api/persons/${id}/add-to-black-list`);

  public static enablePerson = (id: string) =>
    axios.post(`/api/persons/${id}/enable`);

  public static changePassword = (
    oldPassword: string,
    password: string,
    passwordRepeat: string,
  ) =>
    axios.post('/api/user/password/change', {
      oldPassword,
      password,
      passwordRepeat,
    });

  public static postPerson = (data: {
    lastName?: string;
    firstName?: string;
    email?: string;
    birthDate?: string;
    id: string;
  }) => {
    return axios.post<IPerson>('/api/persons', data);
  };

  public static postSuperAdmin = (data: {
    lastName?: string;
    firstName?: string;
    email?: string;
    birthDate?: string;
    id: string;
  }) => {
    return axios.post<IPerson>('/api/persons/super-admin', data);
  };

  public static postUserPicture = (
    idUser: string,
    idMedia: string,
    media: File,
  ) => {
    const formData = new FormData();
    formData.append('upload', media);
    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return axios.post(
      `/api/persons/${idUser}/picture/create/${idMedia}`,
      formData,
      config,
    );
  };

  public static deletePerson = (userId: string) => {
    return axios.delete(`/api/persons/${userId}`);
  };
}
