import { AxiosResponse } from 'axios';
import { IContact } from 'contact';
import { IPerson } from 'person';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContactApi from '../api/contact.api';

interface IFormData {
  title: string;
  message: string;
}

export const useContact = (me: IPerson, handleClose?: () => void) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string>();

  const onSubmit = async (values: IFormData) => {
    let response: AxiosResponse<any> | null = null;
    const contact: IContact = {
      email: me?.email,
      name: `${me?.firstName} ${me?.lastName}`,
      message: `Sujet : RGPD Objet : ${values.title} Message : ${values.message}`,
    };

    try {
      setErrors(undefined);
      response = await ContactApi.postContact(contact);
      if (response.status !== 201) {
        setErrors(t('common.form.failed-send-contact'));
        throw new Error();
      }
      handleClose();
    } catch (err) {
      setErrors(t('common.form.failed-send-contact'));
    }
  };

  return { onSubmit, errors };
};
