import { IDocumentResponse } from 'gallery/entities/document.entity';
import { IAppState, IPayloadError } from 'store';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { KycDocumentType } from '../../entities/organization.kyc.entity';

const initialState: {
  loading: { [key: string]: boolean };
  error: { [key: string]: IPayloadError };
  list: { [key: string]: IDocumentResponse };
} = {
  loading: {},
  error: {},
  list: {},
};

export type KycState = typeof initialState;

export const kycLoadingSelector = (state: IAppState) => state.kyc.loading;
export const kycErrorSelector = (state: IAppState) => state.kyc.error;

export const kycSelector = (state: IAppState) => state.kyc.list;

export const kycArraySelector = createSelector([kycSelector], (kycs) => {
  return Object.values(kycs);
});

const kycSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    fetchClear: (state: KycState, action: PayloadAction) => {
      state.list = {};
      state.error = {};
      state.loading = {};
    },
    fetchKyc: (state: KycState, action: PayloadAction<{ key: string }>) => {
      state.loading[action.payload.key] = true;
    },

    fetchKycSucceeded: (
      state: KycState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IDocumentResponse };
        id?: string;
        type?: KycDocumentType;
      }>,
    ) => {
      const { payload } = action;

      if (payload.key === 'delete') {
        delete state.list[payload.id];
      } else {
        state.list = { ...state.list, ...payload.data };
      }

      const keys = Object.keys(payload);

      keys.map((key) => {
        state.loading[key] = false;
      });

      state.loading[payload.key] = false;
    },

    fetchKycFailed: (
      state: KycState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IPayloadError };
        error: IPayloadError;
      }>,
    ) => {
      const { payload } = action;

      state.loading[payload.key] = false;
      state.error[payload.key] = payload.error;
    },

    fetchAddKyc: (state: KycState, _action: PayloadAction) => {
      state.loading['add'] = true;
    },

    fetchUpdateKyc: (state: KycState, action: PayloadAction<string>) => {
      const { payload } = action;
      state.loading[payload] = true;
    },
    fetchDeleteKyc: (state: KycState, _action: PayloadAction) => {
      state.loading['delete'] = true;
    },
  },
});

export const {
  fetchKyc,
  fetchKycFailed,
  fetchKycSucceeded,
  fetchClear,
} = kycSlice.actions;

export const { reducer: kycReducer } = kycSlice;
