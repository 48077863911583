import { IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IClaim } from '../../entities/claim.entity';

const initialState: {
  list: { [key: string]: IClaim };
  error: { [key: string]: IPayloadError };
  loading: { [key: string]: boolean };
} = {
  list: {},
  error: {},
  loading: {},
};

export type ClaimState = typeof initialState;

const claimSlice = createSlice({
  reducers: {
    fetchClear: (state: ClaimState, _action: PayloadAction) => {
      state.list = {};
      state.error = {};
      state.loading = {};
    },

    fetchClaim: (state: ClaimState, action: PayloadAction<{ key: string }>) => {
      state.loading[action.payload.key] = true;
    },

    fetchClaimSucceeded: (
      state: ClaimState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IClaim };
        id?: string;
      }>,
    ) => {
      const { payload } = action;

      if (payload.key === 'delete') {
        delete state.list[payload.id];
      } else {
        state.list = { ...state.list, ...payload.data };
      }

      const keys = Object.keys(payload);

      keys.map((key) => {
        state.loading[key] = false;
      });

      state.loading[payload.key] = false;
    },

    fetchClaimFailed: (
      state: ClaimState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IPayloadError };
        error: IPayloadError;
      }>,
    ) => {
      const { payload } = action;

      state.loading[payload.key] = false;
      state.error[payload.key] = payload.error;
    },
  },
  initialState,
  name: 'claim',
});

export const {
  fetchClaim,
  fetchClear,
  fetchClaimFailed,
  fetchClaimSucceeded,
} = claimSlice.actions;

export const { reducer: claimReducer } = claimSlice;
