import axios from 'axios';

export class UserApi {
  public static postPasswordResetRequest = (email: string) =>
    axios.post('/api/user/password/forgot-request', {
      email,
    });

  public static postPasswordResetValidate = (
    password: string,
    passwordRepeat: string,
    token: string,
  ) =>
    axios.post('/api/user/password/forgot-validate', {
      password,
      passwordRepeat,
      token,
    });

  public static postChangePassword = (
    oldPassword: string,
    password: string,
    passwordRepeat: string,
  ) =>
    axios.post('/api/user/password/change', {
      oldPassword,
      password,
      passwordRepeat,
    });
}
