import axios from 'axios';

export default class AuthApi {
  public static postRequestLoginCredential = (
    username: string,
    password: string,
  ) =>
    axios.post('/api/auth/login', {
      username,
      password,
    });
  public static getImpersonate = (userId: string) => {
    return axios.get(`/api/auth/impersonate/${userId}`);
  };

  public static logout = () => axios.get(`/api/auth/logout`);
}
