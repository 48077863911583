import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { ProductApi } from '../api/product.api';
import {
  fetchBasket,
  fetchBasketClear,
  fetchBasketFailed,
} from '../slices/basket.slice';

export const clearBasketThunk = (): AppThunk<Promise<any>> => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(fetchBasket());
  try {
    await ProductApi.clearBasket();

    dispatch(fetchBasketClear());
    return true;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(fetchBasketFailed(error));
    throw error;
  }
};
