import { CommunicationApi } from '../api/communications.api';
import {
  fetchCommunication,
  fetchCommunicationFailed,
  fetchCommunicationSucceeded,
} from '../slices/communications.slice';

import { AxiosResponse } from 'axios';
import { ICommunication } from 'communication/entities';
import { normalize, schema } from 'normalizr';
import { Dispatch } from 'redux';
import { IPagination } from 'shared-entities/pagination.entity';
import { AppThunk, IAppState } from 'store';

const defaultParams = {
  itemsPerPage: 10,
  page: 1,
};

export const getCommunication = (params?: {
  [key: string]: string | number;
}): AppThunk<Promise<void>> => async (
  dispatch: Dispatch<any>,
  getState: () => IAppState,
) => {
  let response: AxiosResponse<IPagination<ICommunication>> | null = null;
  dispatch(fetchCommunication());
  try {
    response = await CommunicationApi.getCommunication({
      ...defaultParams,
      ...params,
    });
  } catch (err) {
    dispatch(fetchCommunicationFailed(err));
  }

  const communicationSchema = new schema.Entity('communications');
  const {
    entities: { communications },
  } = normalize(response?.data?.results, [communicationSchema]);
  dispatch(
    fetchCommunicationSucceeded({
      list: communications,
      view: params?.itemsPerPage
        ? getState().communication.view
        : response?.data.view,
    }),
  );
};
