export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Async foreach
 *
 * @param array
 * @param callback
 */
export const asyncForEach = async (
  array: any[],
  callback: (item: any, index: number, array: any[]) => Promise<any>,
) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};
