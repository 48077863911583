import { AxiosResponse } from 'axios';
import { ICalendar } from 'calendar/entities';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { CalendarApi } from '../api/calendar.api';
import {
  fetchFindOne,
  fetchFindOneFailed,
  fetchFindOneSucceeded,
} from '../slices/calendar.slice';

export const findOne = (params?: {
  [key: string]: number | string;
}): AppThunk<Promise<ICalendar>> => async (dispatch: Dispatch) => {
  let response: AxiosResponse<ICalendar> = null;
  dispatch(fetchFindOne());

  try {
    response = await CalendarApi.findOne(params);
    dispatch(fetchFindOneSucceeded(response.data));

    return response.data;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: null,
      toJSON: null,
    };
    dispatch(fetchFindOneFailed(error));
    throw error;
  }
};
