import { useDispatch, useSelector } from 'react-redux';

import { validateCredential } from '../thunks/auth.credential.thunk';
import { tokenSelector } from '../slices/auth.slice';

export const useLoginAuthentication = () => {
  const dispatch = useDispatch();

  const signUpWithCredential = (username: string, password: string) =>
    new Promise((resolve, reject) => {
      dispatch(
        validateCredential(username, password, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });

  const token = useSelector(tokenSelector);

  return { signUpWithCredential, token };
};
