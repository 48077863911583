import { IClaimOpinion } from '../../entities/claim.opinion.entity';
import { IOpinion } from '../../entities/opinions.entity';
import { OpinionsApi } from '../api/opinions.api';
import {
  fetchOpinionsFailed,
  fetchOpinionsRequest,
  fetchOpinionsSucceeded,
} from '../slices/opinions.slice';

import { AxiosError, AxiosResponse } from 'axios';
import { IOrganization } from 'organization';
import { IPerson } from 'person';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

export const opinionClaimThunk = (
  opinionId: string,
  claim: IClaimOpinion,
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOpinion<IPerson, IOrganization>> = null;
  dispatch(fetchOpinionsRequest({ key: opinionId }));
  try {
    response = await OpinionsApi.createClaimOpinion(opinionId, claim);
    dispatch(
      fetchOpinionsSucceeded({
        list: { [response.data.id]: response.data },
        key: opinionId,
      }),
    );
    onError(undefined);
    return response.data;
  } catch (err) {
    const { name, response: responseError, isAxiosError, ...rest } = err;
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      ...rest,
    };
    // - Fetch failed, set error
    dispatch(
      fetchOpinionsFailed({
        key: opinionId,
        error,
      }),
    );
    onError(error);
    throw error;
  }
};
