import { AxiosError, AxiosResponse } from 'axios';
import { sleep } from 'helpers/promise.helper';
import { MediaApi } from 'media/redux';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { IPost } from '../../entities/post.entity';
import { CmsApi } from '../api/cms.api';
import {
  fetchCms,
  fetchCmsFailed,
  fetchCmsSucceeded,
} from '../slices/cms.slice';

export const addCmsWithMediaThunk = (
  type: string,
  values: Partial<IPost>,
  media: File,
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse = null;
  dispatch(fetchCms({ key: 'add' }));
  try {
    const responses = await Promise.all([
      CmsApi.addPost(type, values),
      MediaApi.addMedia(values.id, 'cms', media),
    ]);

    const mediaResponse = responses[1];
    await sleep(500);
    response = await CmsApi.addMedia(values.id, mediaResponse.data);

    dispatch(
      fetchCmsSucceeded({
        type,
        list: { [values.id]: response.data },
        key: 'add',
      }),
    );
    onError();
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(
      fetchCmsFailed({
        key: 'add',
        error,
      }),
    );
    onError(error);
    throw error;
  }
};
