import { IPicture } from 'gallery';
import { MediaApi } from 'media/redux';
import { Dispatch } from 'react';
import { AppThunk } from 'store';
import { v4 as uuidv4 } from 'uuid';

import { GalleryApi } from '../api/gallery.api';
import {
  fetchGalleryFailed,
  fetchGallerySucceeded,
  fetchPostGallery,
} from '../slices/gallery.slice';

export const addGalleryThunk = (
  file: File,
  categoryUri: string,
  progressEvent: (progressEvent: any) => void,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchPostGallery());
  try {
    // TODO : replace "cms" by "gallery", when gallery domain will be available
    const { data: picture } = await MediaApi.addMedia(
      uuidv4(),
      'cms',
      file,
      undefined,
      progressEvent,
    );
    const pictureWithCategory: {
      media: IPicture;
      categoryUri: string;
      id: string;
    } = {
      media: picture,
      categoryUri,
      id: uuidv4(),
    };
    await GalleryApi.addGalleryItem(pictureWithCategory);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    dispatch(fetchGalleryFailed({ error, key: 'add' }));
    throw err;
  }
  dispatch(fetchGallerySucceeded({ list: {}, key: 'add' }));
};
