import axios from 'axios';
import FormData from 'form-data';

export class MediaApi {
  public static MEDIA_ENDPOINT = '/api/medias';

  public static addMedia = (
    id: string,
    domain: string,
    media: File,
    type?: string,
    onProgress?: (progressEvent: any) => void,
  ) => {
    const formData = new FormData();
    formData.append('upload', media);
    return axios.post(`${MediaApi.MEDIA_ENDPOINT}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: { id, domain, ...{ type: type } },
      onUploadProgress: onProgress,
    });
  };
}
