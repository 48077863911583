import { InvoiceApi } from '../api/invoice.api';

export const useInvoice = () => {
  const sendLinkToPayOnline = async (orderId: string) =>
    InvoiceApi.sendLinkToPayOnlineRequest(orderId);

  return {
    sendLinkToPayOnline,
  };
};
