import { IView } from 'shared-entities/pagination.entity';
import { IRib } from 'shop/entities/rib.entity';
import { IPayloadError } from 'store';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IOrganization } from '../../entities/organization.entity';
import memoize from 'lodash.memoize';
import { compareAsc, compareDesc } from 'date-fns';

const initialState: {
  getAll: {
    organizations: { [key: string]: IOrganization };
    loading: boolean;
    error: IPayloadError | undefined;
    view: {
      page?: number;
      total?: number;
      itemsPerPage?: number;
    };
  };
  get: {
    organization: IOrganization;
    loading: boolean;
    error: '';
  };
  add: {
    loading: boolean;
    error: '';
    picture: {
      loading: boolean;
      error: string;
    };
    backgroundImage: {
      loading: boolean;
      error: string;
    };
  };
  update: {
    loading: boolean;
  };
  rib: {
    rib: { [key: string]: IRib };
    loading: { [key: string]: boolean };
    error: { [key: string]: IPayloadError };
  };
} = {
  getAll: {
    organizations: {},
    loading: false,
    error: undefined,
    view: {},
  },
  get: {
    organization: undefined,
    loading: false,
    error: '',
  },
  add: {
    loading: false,
    error: '',
    picture: {
      loading: false,
      error: '',
    },
    backgroundImage: {
      loading: false,
      error: '',
    },
  },
  update: {
    loading: false,
  },
  rib: {
    loading: {},
    rib: {},
    error: {},
  },
};

export type OrganizationState = typeof initialState;
export interface IAppStateOrganization {
  organization: OrganizationState;
}

export const organizationsViewSelector = (state: IAppStateOrganization) =>
  state.organization.getAll.view;

export const organizationsSelector = (state: IAppStateOrganization) =>
  state.organization.get.organization;

export const organizationsListSelector = (state: IAppStateOrganization) =>
  state.organization.getAll.organizations;

export const organizationsLoadingSelector = (state: IAppStateOrganization) =>
  state.organization.getAll.loading;

export const makeOrganizationsListItemSelector = createSelector(
  [organizationsListSelector],
  (organizations) => (id: string) => organizations[id],
);

export const organizationsListArraySelector = createSelector(
  [organizationsListSelector],
  (organizations) => Object.values(organizations || []),
);

export const makeOrganizationsSortedByDateSelector = createSelector(
  organizationsListSelector,
  (organization) =>
    memoize((field: string, order: 'asc' | 'desc') => {
      let sorted = [];
      if (order === 'asc') {
        sorted = Object.values(organization).sort((a, b) =>
          compareAsc(new Date(a[field]), new Date(b[field])),
        );
      } else {
        sorted = Object.values(organization).sort((a, b) =>
          compareDesc(new Date(a[field]), new Date(b[field])),
        );
      }
      return sorted;
    }),
);

export const organizationsErrorSelector = (state: IAppStateOrganization) =>
  state.organization.getAll.error;

export const organizationSelector = (state: IAppStateOrganization) =>
  state.organization.get.organization;

export const organizationLoadingSelector = (state: IAppStateOrganization) =>
  state.organization.get.loading;

export const loadingUpdateSelector = (state: IAppStateOrganization) =>
  state.organization.update.loading;
export const loadingAddSelector = (state: IAppStateOrganization) =>
  state.organization.add.loading;

export const loadingPictureSelector = (state: IAppStateOrganization) =>
  state.organization.add.picture.loading;

export const loadingRibSelector = (state: IAppStateOrganization, key = 'get') =>
  state.organization.rib.loading[key];

export const ribSelector = (state: IAppStateOrganization, key = 'get') =>
  state.organization.rib.rib[key];

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    selectOrganization: (
      state: OrganizationState,
      action: PayloadAction<IOrganization>,
    ) => {
      state.get.organization = action.payload;
    },
    fetchGetClear: (state: OrganizationState, _action: PayloadAction) => {
      state.get.organization = {} as IOrganization;
    },
    fetchGetOrganization: (
      state: OrganizationState,
      _action: PayloadAction,
    ) => {
      state.get.loading = true;
    },
    fetchGetOrganizationSucceeded: (
      state: OrganizationState,
      action: PayloadAction<IOrganization>,
    ) => {
      state.get.organization = { ...action.payload };

      state.get.loading = false;
    },
    fetchGetOrganizationFailed: (
      state: OrganizationState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.get.loading = false;
    },
    fetchGetOrganizations: (
      state: OrganizationState,
      _action: PayloadAction,
    ) => {
      state.getAll.loading = true;
      state.getAll.error = undefined;
    },

    fetchGetOrganizationsSucceeded: (
      state: OrganizationState,
      action: PayloadAction<{
        list: { [key: string]: IOrganization };
        view: IView;
      }>,
    ) => {
      state.getAll.organizations = {
        ...state.getAll.organizations,
        ...action.payload.list,
      };
      state.getAll.view = {
        page: parseInt(action.payload.view.page as any),
        total: parseInt(action.payload.view.total as any),
        itemsPerPage: parseInt(action.payload.view.itemsPerPage as any),
      };
      state.getAll.loading = false;
    },

    fetchGetOrganizationsFailed: (
      state: OrganizationState,
      action: PayloadAction<IPayloadError>,
    ) => {
      state.getAll.loading = false;
      state.getAll.error = action.payload;
    },
    fetchGetSearchOrganizations: (
      state: OrganizationState,
      _action: PayloadAction,
    ) => {
      state.getAll.loading = true;
      state.getAll.error = undefined;
    },

    fetchGetSearchOrganizationsSucceeded: (
      state: OrganizationState,
      action: PayloadAction<{
        list: { [key: string]: IOrganization };
        view: IView;
      }>,
    ) => {
      state.getAll.organizations = {
        ...action.payload.list,
      };
      state.getAll.view = {
        page: parseInt(action.payload.view.page as any),
        total: parseInt(action.payload.view.total as any),
        itemsPerPage: parseInt(action.payload.view.itemsPerPage as any),
      };
      state.getAll.loading = false;
    },

    fetchGetSearchOrganizationsFailed: (
      state: OrganizationState,
      action: PayloadAction<IPayloadError>,
    ) => {
      state.getAll.loading = false;
      state.getAll.error = action.payload;
    },

    fetchOrganization: (state: OrganizationState, _action: PayloadAction) => {
      state.get.loading = true;
    },

    fetchOrganizationSucceeded: (
      state: OrganizationState,
      action: PayloadAction<IOrganization>,
    ) => {
      const { payload } = action;
      state.get.organization = { ...state.get.organization, ...payload };
      state.get.loading = false;
    },

    fetchOrganizationFailed: (
      state: OrganizationState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.get.loading = false;
    },

    fetchAddOrganization: (
      state: OrganizationState,
      _action: PayloadAction,
    ) => {
      state.add.loading = true;
    },

    fetchAddOrganizationSucceeded: (state: OrganizationState) => {
      state.add.loading = false;
    },

    fetchAddOrganizationFailed: (
      state: OrganizationState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.add.loading = false;
      state.add.error = '';
    },

    fetchAddOrganizationPicture: (
      state: OrganizationState,
      _action: PayloadAction,
    ) => {
      state.add.picture.loading = true;
    },

    fetchAddOrganizationPictureSucceeded: (
      state: OrganizationState,
      action: PayloadAction<IOrganization>,
    ) => {
      state.get.organization = action.payload;
      state.add.picture.loading = false;
    },

    fetchAddOrganizationPictureFailed: (
      state: OrganizationState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.add.picture.loading = false;
      state.add.picture.error = '';
    },

    fetchAddOrganizationBackgroundImage: (
      state: OrganizationState,
      _action: PayloadAction,
    ) => {
      state.add.backgroundImage.loading = true;
    },

    fetchAddOrganizationBackgroundImageSucceeded: (
      state: OrganizationState,
      action: PayloadAction<IOrganization>,
    ) => {
      state.get.organization = action.payload;
      state.add.backgroundImage.loading = false;
    },

    fetchAddOrganizationBackgroundImageFailed: (
      state: OrganizationState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.add.backgroundImage.loading = false;
      state.add.backgroundImage.error = '';
    },

    fetchRemoveOrganizationPictureFailed: (
      state: OrganizationState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.add.picture.loading = false;
      state.add.picture.error = '';
    },

    fetchRemoveOrganizationPictureSucceeded: (
      state: OrganizationState,
      action: PayloadAction<IOrganization>,
    ) => {
      state.get.organization = action.payload;
      state.get.loading = false;
    },

    fetchRemoveOrganizationPicture: (
      state: OrganizationState,
      _Action: PayloadAction,
    ) => {
      state.get.loading = false;
    },

    fetchAddOrganizationOpeningHours: (
      state: OrganizationState,
      _action: PayloadAction,
    ) => {
      state.add.loading = true;
    },

    fetchAddOrganizationOpeningHoursSucceeded: (state: OrganizationState) => {
      state.add.loading = false;
    },

    fetchAddOrganizationOpeningHoursFailed: (
      state: OrganizationState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.add.loading = false;
      state.add.error = '';
    },

    fetchUpdateOrganization: (
      state: OrganizationState,
      _action: PayloadAction,
    ) => {
      state.update.loading = true;
    },

    fetchUpdateOrganizationSucceeded: (
      state: OrganizationState,
      { payload }: PayloadAction<IOrganization>,
    ) => {
      state.getAll.organizations[payload.id] = payload;

      if (state.get.organization?.id === payload.id) {
        state.get.organization = payload;
      }
      state.update.loading = false;
    },

    fetchUpdateOrganizationFailed: (
      state: OrganizationState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.update.loading = false;
    },
    fetchFullUpdateOrganization: (
      state: OrganizationState,
      _action: PayloadAction,
    ) => {
      state.update.loading = true;
    },

    fetchFullUpdateOrganizationSucceeded: (
      state: OrganizationState,
      { payload }: PayloadAction<IOrganization>,
    ) => {
      state.getAll.organizations[payload.id] = payload;

      if (state.get.organization?.id === payload.id) {
        state.get.organization = payload;
      }
      state.update.loading = false;
    },

    fetchFullUpdateOrganizationFailed: (
      state: OrganizationState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.update.loading = false;
    },
    fetchRib: (
      state: OrganizationState,
      action: PayloadAction<{ key: string }>,
    ) => {
      state.rib.loading[action.payload.key] = true;
    },
    fetchRibSuccedded: (
      state: OrganizationState,
      action: PayloadAction<{ data: IRib; key: string }>,
    ) => {
      const { payload } = action;
      state.rib.rib[payload.key] = payload.data;
      state.rib.loading[payload.key] = false;
    },
    fetchRibFailed: (
      state: OrganizationState,
      action: PayloadAction<{ error: IPayloadError; key: string }>,
    ) => {
      const { payload } = action;
      state.rib.loading[payload.key] = false;
    },
  },
});

export const {
  fetchGetOrganization,
  fetchGetOrganizationFailed,
  fetchGetOrganizationSucceeded,
  fetchOrganization,
  fetchOrganizationSucceeded,
  fetchOrganizationFailed,
  fetchAddOrganization,
  fetchAddOrganizationSucceeded,
  fetchAddOrganizationFailed,
  fetchGetOrganizationsFailed,
  fetchGetOrganizationsSucceeded,
  fetchGetOrganizations,
  fetchAddOrganizationPicture,
  fetchAddOrganizationPictureSucceeded,
  fetchAddOrganizationPictureFailed,
  fetchAddOrganizationBackgroundImage,
  fetchAddOrganizationBackgroundImageFailed,
  fetchAddOrganizationBackgroundImageSucceeded,
  fetchAddOrganizationOpeningHours,
  fetchAddOrganizationOpeningHoursSucceeded,
  fetchAddOrganizationOpeningHoursFailed,
  fetchUpdateOrganization,
  fetchUpdateOrganizationSucceeded,
  fetchUpdateOrganizationFailed,
  fetchRemoveOrganizationPicture,
  fetchRemoveOrganizationPictureFailed,
  fetchRemoveOrganizationPictureSucceeded,
  fetchGetClear,
  fetchFullUpdateOrganization,
  fetchFullUpdateOrganizationFailed,
  fetchFullUpdateOrganizationSucceeded,
  selectOrganization,
  fetchRib,
  fetchRibFailed,
  fetchRibSuccedded,
  fetchGetSearchOrganizations,
  fetchGetSearchOrganizationsFailed,
  fetchGetSearchOrganizationsSucceeded,
} = organizationSlice.actions;

export const { reducer: organizationReducer } = organizationSlice;
