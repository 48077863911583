import { IAppStateConfig } from './config.slice';

export const configSelector = (state: IAppStateConfig) => state.config.config;
export const errorConfigSelector = (state: IAppStateConfig) =>
  state.config.error;
export const loadingConfigSelector = (state: IAppStateConfig) =>
  state.config.loading;

export const publicConfigSelector = (state: IAppStateConfig) =>
  state.config.publicConfig;
