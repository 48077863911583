import { IOpinion } from '../../entities/opinions.entity';
import {
  averageSelector,
  errorSelector,
  loadingSelector,
  makeOpinionToUriSelector,
  opinionsArraySelector,
  opinionsGivenArraySelector,
  opinionsReceivedArraySelector,
  viewGivenSelector,
  viewReceivedSelector,
  viewSelector,
} from '../slices/opinions.selectors';
import { fetchClear } from '../slices/opinions.slice';
import { getAverageThunk } from '../thunks/get-average.thunk';
import { createOpinionThunk } from '../thunks/opinion.create.thunk';
import { getOpinionsGivenThunk } from '../thunks/opinions-given.thunk';
import { getOpinionsReceivedThunk } from '../thunks/opinions-received.thunk';
import { getPublicOpinionsThunk } from '../thunks/opinions.get.all.thunk';

import { IOrganization } from 'organization';
import { IPerson } from 'person';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventsStatusListener, IAppState, ReduxDispatch } from 'store';

export const useOpinions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ReduxDispatch>();
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const viewPublic = useSelector(viewSelector);
  const viewListReceived = useSelector(viewReceivedSelector);
  const opinionsReceived = useSelector(opinionsReceivedArraySelector);
  const opinionsGiven = useSelector(opinionsGivenArraySelector);
  const publicOpinions = useSelector(opinionsArraySelector);
  const loadingGiven = useSelector((state: IAppState) =>
    loadingSelector(state, 'listGiven'),
  );
  const loadingReceived = useSelector((state: IAppState) =>
    loadingSelector(state, 'listReceived'),
  );
  const viewListGiven = useSelector(viewGivenSelector);

  const opinionToUriSelector = useSelector(makeOpinionToUriSelector);
  const getOpinionsByToUri = (opinionToUri: string) =>
    opinionToUriSelector(opinionToUri);

  const getOpinionsReceived = (
    params: { [key: string]: string | number } = {},
  ) => {
    return dispatch(getOpinionsReceivedThunk(params));
  };

  const getOpinionsGiven = (
    params: { [key: string]: string | number } = {},
  ) => {
    return dispatch(getOpinionsGivenThunk(params));
  };

  const createOpinion = (
    id: string,
    opinion: Partial<IOpinion<IOrganization, IPerson>>,
  ) => {
    return new Promise((resolve, reject) =>
      dispatch(
        createOpinionThunk(id, opinion, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
            resolve();
          }
        }),
      ),
    );
  };

  const getAverage = (params: { [key: string]: string | number } = {}) => {
    return dispatch(getAverageThunk(params));
  };

  const getPublicOpinions = (params: { [key: string]: string | number } = {}) =>
    dispatch(getPublicOpinionsThunk(params));

  const clear = () => dispatch(fetchClear());

  const average = useSelector(averageSelector);

  return {
    clear,
    viewPublic,
    publicOpinions,
    getPublicOpinions,
    getOpinionsByToUri,
    createOpinion,
    getOpinionsReceived,
    getOpinionsGiven,
    opinionsReceived,
    opinionsGiven,
    loading,
    loadingGiven,
    loadingReceived,
    error,
    viewListReceived,
    viewListGiven,
    getAverage,
    average,
  };
};
