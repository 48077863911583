import { useDispatch, useSelector } from 'react-redux';
import { ReduxDispatch } from 'store';

import { ICalendar } from '../../entities';
import {
  findOneErrorSelector,
  findOneLoadingSelector,
  findOneSelector,
} from '../slices/calendar.selectors';
import { findOne } from '../thunks/find-one.thunk';
import { updateCalendar } from '../thunks/update.calendar.thunk';

export const useCalendar = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const loading = useSelector(findOneLoadingSelector);
  const error = useSelector(findOneErrorSelector);
  const calendar = useSelector(findOneSelector);

  const get = (params?: { [key: string]: string | number }) => {
    return dispatch(findOne(params));
  };

  const update = (partialCalendar: Partial<ICalendar>) => {
    return dispatch(updateCalendar(calendar.id, partialCalendar, 'findOne'));
  };

  return { loading, calendar, error, get, update };
};
