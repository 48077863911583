import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxDispatch } from 'store';

import {
  errorSelector,
  listArraySelector,
  loadingSelector,
  viewSelector,
} from '../slices/communications.selectors';
import { getCommunication } from '../thunks/communication.get.thunk';

export const useCommunication = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const [refreshing, setRefreshing] = useState(false);
  const loading = useSelector(loadingSelector);
  const list = useSelector(listArraySelector);
  const error = useSelector(errorSelector);
  const view = useSelector(viewSelector);

  const get = async (...params: Parameters<typeof getCommunication>) => {
    return dispatch(getCommunication(...params));
  };

  const refresh = async () => {
    setRefreshing(true);
    try {
      await get({ itemsPerPage: list.length, page: 1 });
      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
    }
  };

  return { loading, refresh, refreshing, list, error, view, get };
};
