import { useDispatch, useSelector } from 'react-redux';
import { ReduxDispatch } from 'store';

import { IAddEventCalendarPayload, ICalendarEvent } from '../../entities';
import {
  errorSelector,
  loadingSelector,
  makeEventSelector,
} from '../slices/calendar.selectors';
import { IAppStateCalendar } from '../slices/calendar.slice';
import { deleteEventThunk } from '../thunks/delete-event.thunk';
import { updateEventThunk } from '../thunks/update-event.thunk';

export const useEvent = <T>(id: string) => {
  const dispatch = useDispatch<ReduxDispatch>();
  const loading = useSelector(loadingSelector);
  const loadingAdd = useSelector((state: IAppStateCalendar) =>
    loadingSelector(state, 'add'),
  );
  const loadingDelete = useSelector((state: IAppStateCalendar) =>
    loadingSelector(state, 'delete'),
  );
  const loadingUpdate = useSelector((state: IAppStateCalendar) =>
    loadingSelector(state, id),
  );

  const error = useSelector(errorSelector);
  const getEventSelector = useSelector(makeEventSelector);
  const event: ICalendarEvent<T> = getEventSelector(id);

  const updateEvent = (
    calendarId: string,
    values: Partial<IAddEventCalendarPayload>,
  ) => dispatch(updateEventThunk<T>(calendarId, id, values));

  const deleteEvent = (calendarId: string) =>
    dispatch(deleteEventThunk(calendarId, id));

  return {
    deleteEvent,
    updateEvent,
    event,
    loading,
    error,
    loadingAdd,
    loadingUpdate,
    loadingDelete,
  };
};
