import {
  basketSelector,
  errorSelector,
  loadingSelector,
} from '../slices/basket.slice';
import { addBasketThunk } from '../thunks/add-basket.thunk';
import { addSponsorCodeToBasketThunk } from '../thunks/add-sponsor-code.thunk';
import { clearBasketThunk } from '../thunks/clear-basket.thunk';
import { getCurrentBasketRequest } from '../thunks/get-basket.thunk';
import { validateAndPayThunk } from '../thunks/validate-and-pay.thunk';

import { ShopMeansOfPaymentEnum } from 'config/entities';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventsStatusListener, ReduxDispatch } from 'store';

export const useBasket = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const { t } = useTranslation();
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const basket = useSelector(basketSelector);

  const addBasket = async (params: { [key: string]: any }) => {
    try {
      await dispatch(addBasketThunk(params));
    } catch (err) {
      EventsStatusListener.getInstance().publishErrorMessage(
        t('order-payment.form.errors.failed-add-basket'),
      );
      throw new Error(t('order-payment.form.errors.failed-add-basket'));
    }
  };

  const clearBasket = async () => dispatch(clearBasketThunk());

  const getBasket = async () => {
    return dispatch(getCurrentBasketRequest());
  };

  const validateAndPay = async (meansOfPayment: ShopMeansOfPaymentEnum) => {
    return dispatch(validateAndPayThunk(meansOfPayment));
  };

  const addSponsorCode = async (code: string) => {
    try {
      await dispatch(addSponsorCodeToBasketThunk(code, t));
      EventsStatusListener.getInstance().publishSuccessMessage(
        t('order-payment.form.success-sponsor-code'),
      );
    } catch (err) {
      EventsStatusListener.getInstance().publishErrorMessage(
        t('order-payment.form.errors.sponsorCode-failed'),
      );
      throw new Error(t('order-payment.form.errors.sponsorCode-failed'));
    }
  };

  return {
    clearBasket,
    addBasket,
    getBasket,
    validateAndPay,
    addSponsorCode,
    basket,
    loading,
    error,
  };
};
