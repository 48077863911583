import { useDispatch, useSelector } from 'react-redux';
import { ReduxDispatch } from 'store';

import {
  errorSelector,
  loadingSelector,
  opinionsArraySelector,
  viewSelector,
} from '../slices/opinions.selectors';
import { getOpinionsThunk } from '../thunks/opinions.get.all.thunk';

const pagination = {
  itemsPerPage: '10',
};

export const useAdminOpinions = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const view = useSelector(viewSelector);
  const opinions = useSelector(opinionsArraySelector);

  const getOpinions = (params: { [key: string]: string } = {}) => {
    return dispatch(getOpinionsThunk({ ...params, ...pagination }));
  };

  return { getOpinions, opinions, loading, error, view, pagination };
};
