import { useDispatch, useSelector } from 'react-redux';
import { ReduxDispatch } from 'store';

import { IDiscount } from '../../entities/discount.entity';
import { DiscountApi } from '../api/discount.api';
import {
  discountsListLoadingSelector,
  discountsListSelector,
  makeDiscountByOwnerUri,
} from '../slices/discount.slice';
import { addDiscount } from '../thunks/add-discount.thunk';
import { deleteDiscountRequest } from '../thunks/delete-discount.thunk';
import { getDiscounts } from '../thunks/get-discounts.thunks';
import { updateDiscountRequest } from '../thunks/update-discount.thunks';

export const useDiscount = () => {
  const isLoadingListDiscount = useSelector(discountsListLoadingSelector);

  const dispatch = useDispatch<ReduxDispatch>();

  const getAllDiscounts = (filter?: object) => {
    return dispatch(getDiscounts(filter));
  };

  const createDiscount = (data: Partial<IDiscount>) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addDiscount(data, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const updateDiscount = (discountId: string, data: Partial<IDiscount>) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateDiscountRequest(discountId, data, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const deleteDiscount = (discountId: string) => {
    return new Promise((resolve, reject) => {
      dispatch(
        deleteDiscountRequest(discountId, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const getDiscountId = (id: string) => {
    return DiscountApi.getDiscountById(id);
  };

  const listDiscountSelector = useSelector(discountsListSelector);
  const discountByOwnerUriSelector = useSelector(makeDiscountByOwnerUri);
  const discountByOwnerUri = (ownerUri: string) =>
    discountByOwnerUriSelector(ownerUri);

  return {
    discountByOwnerUri,
    isLoadingListDiscount,
    listDiscountSelector,
    createDiscount,
    getAllDiscounts,
    updateDiscount,
    deleteDiscount,
    getDiscountId,
  };
};
