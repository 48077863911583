import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk, generateErrorHandlerAction } from 'store';

import { IOrganization } from '../../entities/organization.entity';
import { OrganizationApi } from '../api/organization.api';
import {
  fetchUpdateOrganization,
  fetchUpdateOrganizationFailed,
  fetchUpdateOrganizationSucceeded,
} from '../slices/organization.slice';

export const disableOrganizationThunk = (
  organizationID: string,
  enabled: boolean,
): AppThunk<Promise<boolean>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrganization> | null = null;
  dispatch(fetchUpdateOrganization());

  try {
    response = await OrganizationApi.updateOrganization(organizationID, {
      enabled,
    });
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchUpdateOrganizationFailed,
      ),
    );
    return false;
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set person
    const { data } = response;
    dispatch(fetchUpdateOrganizationSucceeded(data));
  }
  return true;
};
