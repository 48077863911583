import { PersonApi } from '../api/person.api';
import {
  fetchClear,
  idSelector,
  loadingSelector,
  meLoadingSelector,
  meSelector,
  personErrorSelector,
  personLoadingSelector,
  personSelector,
} from '../slices/person.slice';
import { addUserPictureRequest } from '../thunks/add-user-picture.thunk';
import { changePasswordRequest } from '../thunks/change-password.thunk';
import {
  addSuperAdminRequest,
  deletePersonRequest,
  disabledPersonRequest,
  enabledPersonRequest,
  getMeRequest,
  getPersonRequest,
  updateMe as updateMeThunk,
  updateUserThunk,
} from '../thunks/person.thunk';

import { IPerson } from 'person';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventsStatusListener, ReduxDispatch } from 'store';

export const usePerson = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const { t } = useTranslation();

  const updateUser = (id: string, personData: Partial<IPerson>) =>
    dispatch(updateUserThunk(id, personData));

  const updateMe = (personData: Partial<IPerson>) =>
    dispatch(updateMeThunk(personData));

  const getMe = (params?: { [key: string]: string }) => {
    return new Promise((resolve, reject) => {
      dispatch(
        getMeRequest((err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }, params),
      );
    });
  };

  const getPerson = (personId: string, analytics?: boolean) => {
    dispatch(getPersonRequest(personId, analytics));
  };

  const disablePerson = (customerId: string) =>
    disabledPersonRequest(customerId);

  const enablePerson = (customerId: string) =>
    dispatch(enabledPersonRequest(customerId));

  const changePassword = (
    oldPassword: string,
    password: string,
    passwordRepeat: string,
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        changePasswordRequest(oldPassword, password, passwordRepeat, (err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const updateUserAndChangePassword = (
    personData: Partial<IPerson>,
    oldPassword: string,
    password: string,
    passwordRepeat: string,
  ) => {
    return Promise.all([
      updateMe(personData),
      changePassword(oldPassword, password, passwordRepeat),
    ]);
  };

  const addUserPicture = (idUser: string, idMedia: string, media: File) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addUserPictureRequest(idUser, idMedia, media, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const addToBlackList = (idPerson: string) =>
    PersonApi.addToBlackList(idPerson);

  const addSuperAdmin = (personData: Partial<IPerson>) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addSuperAdminRequest(personData, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve(personData);
          }
        }),
      );
    });
  };

  const deletePerson = async (userId: string) => {
    return new Promise((resolve, reject) => {
      dispatch(
        deletePersonRequest(userId, (err) => {
          if (err != null) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.unknown-error'),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  const id = useSelector(idSelector);

  const loading = useSelector(loadingSelector);

  const me = useSelector(meSelector);
  const clear = () => {
    dispatch(fetchClear());
  };
  const person = useSelector(personSelector);
  const personLoading = useSelector(personLoadingSelector);
  const personError = useSelector(personErrorSelector);
  const meLoading = useSelector(meLoadingSelector);

  return {
    updateUser,
    addToBlackList,
    meLoading,
    updateUserAndChangePassword,
    changePassword,
    enablePerson,
    disablePerson,
    getPerson,
    getMe,
    id,
    clear,
    updateMe,
    loading,
    me,
    person,
    personLoading,
    personError,
    addUserPicture,
    addSuperAdmin,
    deletePerson,
  };
};
