import Axios from 'axios';

import { IConfig } from '../../entities/config.entity';

export class ConfigApi {
  public static get = () => Axios.get(`/api/persons/config`);

  public static update = (config: { configs: Array<Partial<IConfig>> }) =>
    Axios.put(`/api/persons/config/update`, config);

  public static getPublicConfig = (code: string) =>
    Axios.get(`/api/public/configs/${code}`);

  public static postAdminConfig = (config: Partial<IConfig>) =>
    Axios.post(`/api/configs`, config);
}
