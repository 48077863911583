export class IBugReport {
  public id: string;
  public consumerId: string;
  public osName: string;
  public browserName: string;
  public windowHeight: number;
  public windowWidth: number;
  public contactEmail: string;
  public message: string;
  public createdAt: string;
}
