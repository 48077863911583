import { Addresses, IOrganization } from '../../entities/organization.entity';
import { KycDocumentType } from '../../entities/organization.kyc.entity';
import { OrganizationApi } from '../api/organization.api';
import { kycArraySelector, kycSelector } from '../slices/kyc.slice';
import {
  loadingAddSelector,
  loadingPictureSelector,
  loadingRibSelector,
  loadingUpdateSelector,
  makeOrganizationsListItemSelector,
  organizationLoadingSelector,
  organizationSelector,
  ribSelector,
  selectOrganization,
} from '../slices/organization.slice';
import { addOrganizationRibThunk } from '../thunks/add.rib.thunk';
import { getOrganizationRibThunk } from '../thunks/get.rib.thunk';
import { addKycThunk, getKycThunk } from '../thunks/kyc.thunks';
import { disableOrganizationThunk } from '../thunks/organization.disable.thunk';
import {
  addOrganizationRequest,
  clearGetOrganizationRequest,
  getOrganizationRequest,
  updateFullOrganizationRequest,
  updateOrganization as updateOrganizationRequest,
} from '../thunks/organization.thunk';
import { useFounder } from './founder.hook';
import { usePicture } from './picture.hook';

import { IPerson } from 'person';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IRib } from 'shop/entities/rib.entity';
import { EventsStatusListener } from 'store';
import { v4 as uuidv4 } from 'uuid';

export const useOrganization = <T, P>() => {
  const dispatch = useDispatch();
  const reduxDispatch = useDispatch();
  const { addOrganizationPicture } = usePicture();
  const { addFounder } = useFounder();
  const { t } = useTranslation();
  const organizationListItemSelector = useSelector(
    makeOrganizationsListItemSelector,
  );

  const selectListItemOrganizationById = (organizationId: string) => {
    const organization = organizationListItemSelector(organizationId);
    dispatch(selectOrganization(organization));
  };

  const clearGetOrganization = () => {
    dispatch(clearGetOrganizationRequest());
  };

  const getOrganization = (organizationId: string) => {
    return new Promise((resolve, reject) => {
      dispatch(
        getOrganizationRequest(organizationId, (err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const disableOrganization = (orgId: string, enabled: boolean) => {
    return reduxDispatch(disableOrganizationThunk(orgId, enabled));
  };
  const addOrganization = async (
    organizationDatas: Partial<IOrganization<T>>,
  ) =>
    new Promise((resolve, reject) => {
      dispatch(
        addOrganizationRequest(organizationDatas, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });

  const addFullOrganization = async (
    organizationDatas: Partial<IOrganization<T>>,
    founder: Partial<IPerson<P>>,
    pictureFile?: File,
  ) => {
    const organizationId = uuidv4();

    await addOrganization({
      ...organizationDatas,
      id: organizationId,
    });
    if (pictureFile) {
      await addOrganizationPicture(organizationId, pictureFile);
    }

    await addFounder(organizationId, founder);
  };

  const updateFullOrganization = async (
    organizationDatas: Partial<IOrganization<T>>,
    founder: Partial<IPerson>,
    pictureFile?: File,
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateFullOrganizationRequest(
          organizationDatas,
          founder,
          (err) => {
            if (err != null) {
              reject(err);

              EventsStatusListener.getInstance().publishErrorMessage(
                t('common.notification.update-failed', {
                  message: err.message,
                }),
              );
            } else {
              resolve();
              EventsStatusListener.getInstance().publishSuccessMessage(
                t('common.notification.update-success'),
              );
            }
          },
          pictureFile,
        ),
      );
    });
  };

  const updateOrganization = (data: Partial<IOrganization<T>>) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateOrganizationRequest(data, (err) => {
          if (err != null) {
            reject(err);
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
          } else {
            resolve();
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
          }
        }),
      );
    });
  };

  const addRib = (id: string, rib: IRib) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addOrganizationRibThunk(id, rib, (err) => {
          if (err != null) {
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('rib.success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  const addKyc = async (
    file: File,
    type: KycDocumentType,
    organizationId: string,
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addKycThunk(type, file, organizationId, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve(type);
          }
        }),
      );
    });
  };

  const getRib = (organizationId: string) => {
    return new Promise((resolve, reject) => {
      dispatch(
        getOrganizationRibThunk(organizationId, (err) => {
          if (err !== null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const getKyc = (organizationId: string) => {
    return dispatch(getKycThunk(organizationId));
  };

  const updateAddress = async (
    organizationId: string,
    addressId: string,
    updateAddress: Partial<Addresses>,
  ) => {
    await OrganizationApi.updateAddress(
      organizationId,
      addressId,
      updateAddress,
    );
  };

  const organization: IOrganization<T> = useSelector(organizationSelector);
  const loadingUpdate = useSelector(loadingUpdateSelector);
  const loadingAdd = useSelector(loadingAddSelector);
  const loadingPicture = useSelector(loadingPictureSelector);
  const loadingOrganization = useSelector(organizationLoadingSelector);
  const loadingRib = useSelector(loadingRibSelector);
  const rib = useSelector(ribSelector);
  const kyc = useSelector(kycSelector);
  const kycArray = useSelector(kycArraySelector);

  return {
    addFullOrganization,
    loadingOrganization,
    clearGetOrganization,
    organization,
    loadingUpdate,
    loadingPicture,
    loadingAdd,
    getOrganization,
    addOrganization,
    disableOrganization,
    updateOrganization,
    updateFullOrganization,
    selectListItemOrganizationById,
    addRib,
    getRib,
    loadingRib,
    rib,
    addKyc,
    getKyc,
    kyc,
    kycArray,
    updateAddress,
  };
};
