import { ICategoryNestedResponse } from '../../entities/category.type';
import { ProductApi } from '../api/product.api';
import {
  fetchCategories,
  fetchCategoryFailed,
  fetchCategorySucceeded,
} from '../slices/category.slice';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { categoryAdapter } from 'shop/adapter/category.adapter';
import { AppThunk } from 'store';

export const getPublicCategoriesRequest = (filters?: {
  [k: string]: any;
}): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchCategories());

  let response: AxiosResponse<ICategoryNestedResponse[]> | null = null;

  try {
    response = await ProductApi.getPublicCategoriesAll(filters);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (responseError?.data) {
      const {
        data: { statusCode, message },
      } = responseError;
      // - Fetch failed, set error
      dispatch(
        fetchCategoryFailed({
          key: 'list',
          data: {},
          error: {
            isAxiosError,
            message,
            name,
            code: statusCode,
            stack,
          },
        }),
      );
      return;
    }

    dispatch(
      fetchCategoryFailed({
        key: 'list',
        data: {},
        error: {
          isAxiosError,
          message: 'An error occured',
          name,
          code: '404',
          stack,
        },
      }),
    );
    return;
  }

  if (response != null && response?.data) {
    const { data } = response;
    const categories = data;
    /**
     * Create an array of parent categories with sub categories
     */

    dispatch(
      fetchCategorySucceeded({
        key: 'list',
        data: categoryAdapter(categories),
      }),
    );
    return;
  }

  return;
};

export const getCategoriesByOrganizationRequest = (
  organizationId: string,
  filters?: { [key: string]: any },
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchCategories());

  let response: AxiosResponse<ICategoryNestedResponse[]> | null = null;

  try {
    response = await ProductApi.getCategoriesAll(organizationId, filters);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (responseError?.data) {
      const {
        data: { statusCode, message },
      } = responseError;
      // - Fetch failed, set error
      dispatch(
        fetchCategoryFailed({
          key: 'list',
          data: {},
          error: {
            isAxiosError,
            message,
            name,
            code: statusCode,
            stack,
          },
        }),
      );
      return;
    }

    dispatch(
      fetchCategoryFailed({
        key: 'list',
        data: {},
        error: {
          isAxiosError,
          message: 'An error occured',
          name,
          code: '404',
          stack,
        },
      }),
    );
    return;
  }

  if (response != null && response?.data) {
    const { data } = response;
    const categories = data;

    dispatch(
      fetchCategorySucceeded({
        key: 'list',
        data: categoryAdapter(categories),
      }),
    );
    return;
  }

  return;
};
