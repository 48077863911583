import { IPost } from '../../entities/post.entity';
import {
  errorSelector,
  getPostById,
  getViewByTypeSelector,
  loadingSelector,
  postsArrayByTypeSelector,
  postsByTagsSelector,
} from '../slices/cms.selectors';
import { addMediaToArticleThunk } from '../thunks/cms-add-media.thunk';
import { addCmsWithMediaThunk } from '../thunks/cms-add-with-media.thunk';
import { addCmsThunk } from '../thunks/cms-add.thunk';
import { getCmsThunk } from '../thunks/cms-get-all.thunk';
import { updateCmsWithMediaThunk } from '../thunks/cms-update-with-media.thunk';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventsStatusListener } from 'store';

const pagination = {
  itemsPerPost: 10,
  page: 1,
};

export const usePosts = <T>() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const viewByType = (type: string) => useSelector(getViewByTypeSelector(type));
  const postsByType = (type: string) =>
    useSelector(postsArrayByTypeSelector(type)) as IPost<T>[];
  const postById = (id: string) => useSelector(getPostById(id)) as IPost<T>;
  const postsByTags = (tags: string) =>
    useSelector(postsByTagsSelector(tags)) as IPost<T>[];

  const getPosts = (
    params: { [key: string]: string | number | { tags: string } } = {},
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        getCmsThunk({ ...pagination, ...params }, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const addPost = (type: string, values: Partial<IPost<T>>) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addCmsThunk(type, values, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
            reject(err);
          } else {
            // needed to not show double toast
            if (!values.tags) {
              EventsStatusListener.getInstance().publishSuccessMessage(
                t('common.notification.update-success'),
              );
            }
            resolve();
          }
        }),
      );
    });
  };

  const addPostWithMedia = (
    type: string,
    values: Partial<IPost<T>>,
    media: File,
  ) => {
    return new Promise((resolve, reject) => {
      if (media) {
        dispatch(
          addCmsWithMediaThunk(type, values, media, (err) => {
            if (err) {
              EventsStatusListener.getInstance().publishErrorMessage(
                t('common.notification.update-failed', {
                  message: err.message,
                }),
              );
              reject(err);
            } else {
              EventsStatusListener.getInstance().publishSuccessMessage(
                t('common.notification.update-success'),
              );
              resolve();
            }
          }),
        );
      } else {
        dispatch(
          addCmsThunk(type, values, (err) => {
            if (err) {
              EventsStatusListener.getInstance().publishErrorMessage(
                t('common.notification.update-failed', {
                  message: err.message,
                }),
              );
              reject(err);
            } else {
              EventsStatusListener.getInstance().publishSuccessMessage(
                t('common.notification.update-success'),
              );
              resolve();
            }
          }),
        );
      }
    });
  };

  const updatePostWithMedia = (
    id: string,
    values: Partial<IPost<T>>,
    media: File,
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateCmsWithMediaThunk(id, values, media, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  const addMedia = (
    postId: string,
    data: {
      id: string;
      media: File;
    },
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        addMediaToArticleThunk('file', postId, data, (err) => {
          if (err) {
            EventsStatusListener.getInstance().publishErrorMessage(
              t('common.notification.update-failed', { message: err.message }),
            );
            reject(err);
          } else {
            EventsStatusListener.getInstance().publishSuccessMessage(
              t('common.notification.update-success'),
            );
            resolve();
          }
        }),
      );
    });
  };

  return {
    updatePostWithMedia,
    addPostWithMedia,
    addPost,
    postById,
    getPosts,
    postsByType,
    loading,
    error,
    viewByType,
    pagination,
    addMedia,
    postsByTags,
  };
};
