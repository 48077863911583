import { analyticsInstance } from 'store';

export default class AnalyticsApi {
  public static ANALYTICS_ENDPOINT = '/api/analytics';

  public static getAudiences = (
    sortBy: string,
    limit: number,
    userId?: string,
  ) =>
    analyticsInstance.get(`${AnalyticsApi.ANALYTICS_ENDPOINT}/audiences`, {
      params: { sortBy, limit, userId },
    });

  public static getCa = (
    sortBy: string,
    limit?: number,
    userId?: string,
    beginDate?: Date,
    endDate?: Date,
    filterByDate?: string,
  ) =>
    analyticsInstance.get(`${AnalyticsApi.ANALYTICS_ENDPOINT}/ca`, {
      params: { sortBy, limit, userId, beginDate, endDate, filterByDate },
    });

  public static getCaAverage = (
    sortBy: string,
    limit?: number,
    userId?: string,
    beginDate?: Date,
    endDate?: Date,
    filterByDate?: string,
  ) =>
    analyticsInstance.get(`${AnalyticsApi.ANALYTICS_ENDPOINT}/ca`, {
      params: {
        sortBy,
        limit,
        userId,
        beginDate,
        endDate,
        filterByDate,
        average: 1,
      },
    });
  public static findOneBy = (action: string, userId?: string, sort?: string) =>
    analyticsInstance.get(`${AnalyticsApi.ANALYTICS_ENDPOINT}`, {
      params: { action, userId, sort },
    });
  public static getProduct = (
    sortBy: string,
    limit?: number,
    userId?: string,
    beginDate?: Date,
    endDate?: Date,
    productId?: string,
  ) =>
    analyticsInstance.get(`${AnalyticsApi.ANALYTICS_ENDPOINT}/products`, {
      params: { sortBy, limit, userId, beginDate, endDate, productId },
    });
}
