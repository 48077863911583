import { IDiscount } from 'shop';

export const discountAdapter = (
  categories: IDiscount[],
): { [key: string]: IDiscount } => {
  if (!categories || categories.length === 0) {
    return {};
  }
  const neestedDiscount: {
    [key: string]: IDiscount;
  } = categories.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

  return neestedDiscount;
};
