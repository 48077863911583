import { AxiosError, AxiosResponse } from 'axios';
import { sleep } from 'helpers/promise.helper';
import { MediaApi } from 'media/redux';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { IPost } from '../../entities/post.entity';
import { CmsApi } from '../api/cms.api';
import {
  fetchCms,
  fetchCmsFailed,
  fetchCmsSucceeded,
} from '../slices/cms.slice';

export const updateCmsWithMediaThunk = (
  id: string,
  values: Partial<IPost>,
  media: File | undefined,
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse = null;
  dispatch(fetchCms({ key: id }));
  try {
    if (media) {
      const responses = await Promise.all([
        CmsApi.updatePost(id, values),
        MediaApi.addMedia(id, 'cms', media),
      ]);

      const mediaResponse = responses[1];
      await sleep(500);
      response = await CmsApi.addMedia(id, mediaResponse.data);
    } else {
      response = await CmsApi.updatePost(id, values);
    }

    dispatch(
      fetchCmsSucceeded({
        list: { [id]: response.data },
        key: id,
      }),
    );
    onError();
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(
      fetchCmsFailed({
        key: id,
        error,
      }),
    );
    onError(error);
    throw error;
  }
};
