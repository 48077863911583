import { AxiosError, AxiosResponse } from 'axios';
import { IDocumentResponse } from 'gallery';
import { sleep } from 'helpers/promise.helper';
import { MediaApi } from 'media/redux';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';
import { v4 as uuidv4 } from 'uuid';

import { KycDocumentType } from '../../entities/organization.kyc.entity';
import { OrganizationApi } from '../api/organization.api';
import {
  fetchKyc,
  fetchKycFailed,
  fetchKycSucceeded,
} from '../slices/kyc.slice';

const HTTP_SUCCESS = 201;

export const addKycThunk = (
  type: KycDocumentType,
  media: File,
  organizationId: string,
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IDocumentResponse> | null = null;
  dispatch(fetchKyc({ key: 'add' }));
  try {
    const mediaResponse = await MediaApi.addMedia(
      uuidv4(),
      'kyc',
      media,
      'file',
    );

    await sleep(500);
    if (mediaResponse.status !== HTTP_SUCCESS) {
      throw new Error();
    }
    response = await OrganizationApi.addKyc(organizationId, {
      id: uuidv4(),
      type: type,
      media: { ...mediaResponse.data },
    });

    dispatch(
      fetchKycSucceeded({
        data: { [response.data.id]: response.data },
        key: 'add',
      }),
    );
    onError();
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(
      fetchKycFailed({
        key: 'add',
        error,
      }),
    );
    onError(error);
    throw error;
  }
};

export const getKycThunk = (
  organizationId: string,
): AppThunk<Promise<any>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IDocumentResponse> | null = null;
  dispatch(fetchKyc({ key: 'list' }));
  try {
    response = await OrganizationApi.getKyc(organizationId);

    dispatch(
      fetchKycSucceeded({
        data: { [response.data.id]: response.data },
        key: 'list',
      }),
    );
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;

    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: {},
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(
      fetchKycFailed({
        key: 'list',
        error,
      }),
    );
    throw error;
  }
};
