import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { ProductApi } from '../api/product.api';
import {
  fetchCategoryFailed,
  fetchCategorySucceeded,
  fetchDeleteCategory,
} from '../slices/category.slice';

export const deleteCategoryRequest = (
  id: string,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchDeleteCategory());

  try {
    await ProductApi.deleteCategory(id);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (responseError?.data) {
      const {
        data: { statusCode, message },
      } = responseError;
      // - Fetch failed, set error
      dispatch(
        fetchCategoryFailed({
          key: 'delete',
          error: {
            isAxiosError,
            message,
            name,
            code: statusCode,
            stack,
          },
        }),
      );
      onError(err);
      return;
    }
    dispatch(
      fetchCategoryFailed({
        key: 'delete',
        error: {
          isAxiosError,
          message: '',
          name: '',
          code: '404',
          stack: '',
        },
      }),
    );
    onError(err);
    return;
  }

  dispatch(fetchCategorySucceeded({ key: 'delete', id }));
  onError(undefined);
  return;
};
