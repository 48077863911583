import { IAppState, IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPerson } from '../../entities/person.entity';
import {
  errorSelector as personsErrorSelector,
  initialState as personsInitialState,
  loadingSelector as personsLoadingSelector,
  personSlice as personsSlices,
  personsSelector,
  PersonState,
  totalSelector as personsTotalSelector,
} from './get-persons.slice';

interface InitialState {
  me?: IPerson;
  loading?: { [key: string]: boolean };
  loadingPasswordChange: boolean;
  loadingAddPicture: boolean;
  person: {
    person?: Partial<IPerson>;
    loading: boolean;
    error: string;
  };
}

export type State = InitialState & PersonState;

const initialState: State = {
  me: undefined,
  loading: {},
  loadingPasswordChange: false,
  loadingAddPicture: false,
  person: {
    person: undefined,
    loading: false,
    error: '',
  },
  ...personsInitialState,
};

export const personSelector = (state: IAppState) => state.person.person.person;
export const personLoadingSelector = (state: IAppState) =>
  state.person.person.loading;
export const personErrorSelector = (state: IAppState) =>
  state.person.person.error;
export const idSelector = (state: IAppState) => state.person?.me?.id;
export const meSelector = (state: IAppState) => state.person.me;
export const loadingSelector = (state: IAppState) => state.person.loading;
export const meLoadingSelector = (state: IAppState) =>
  state.person.loading['me'];

export const loadingChangePasswordSelector = (state: IAppState) =>
  state.person.loadingPasswordChange;

export const serviceTypeSelector = (state: IAppState) =>
  state.person.me.affiliation?.serviceType[0]?.code;

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    /**
     * Set loading to true when waiting for person info
     */
    fetchClear: (state: State, _action: PayloadAction) => {
      state.me = undefined;
    },
    /**
     * Set loading to true when waiting for person info
     */
    fetchPersonsMe: (state: State, _action: PayloadAction) => {
      state.loading['me'] = true;
    },

    /**
     * Store the user info and reset loading
     */
    fetchPersonsMeSucceeded: (state: State, action: PayloadAction<IPerson>) => {
      const { payload } = action;
      state.me = { ...state.me, ...payload };
      state.loading['me'] = false;
    },

    /**
     * Reset loading
     */
    fetchPersonsMeFailed: (
      state: State,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.loading['me'] = false;
    },
    /**
     * Set loading to true when waiting for person info
     */
    fetchChangePassword: (state: State, _action: PayloadAction) => {
      state.loadingPasswordChange = true;
    },
    /**
     * Store the user info and reset loading
     */
    fetchChangePasswordSucceeded: (state: State, _action: PayloadAction) => {
      state.loadingPasswordChange = false;
    },
    /**
     * Reset loading
     */
    fetchChangePasswordFailed: (
      state: State,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.loadingPasswordChange = false;
    },
    /**
     * Set loading to true when waiting for person info
     */
    fetchUpdateUserAndChangePassword: (
      state: State,
      _action: PayloadAction,
    ) => {
      state.loading['update-user-password-change'] = true;
      state.loadingPasswordChange = true;
    },
    /**
     * Store the user info and reset loading
     */
    fetchUpdateUserAndChangePasswordSucceeded: (
      state: State,
      _action: PayloadAction,
    ) => {
      state.loading['update-user-password-change'] = false;
      state.loadingPasswordChange = false;
    },
    /**
     * Reset loading
     */
    fetchUpdateUserAndChangePasswordFailed: (
      state: State,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.loading['update-user-password-change'] = false;
      state.loadingPasswordChange = false;
    },

    /**
     * Get a person
     */
    fetchPerson: (state: State, _action: PayloadAction) => {
      state.person.loading = true;
    },
    fetchPersonSucceeded: (state: State, action: PayloadAction<IPerson>) => {
      const { payload } = action;
      state.person.person = payload;
      state.person.loading = false;
    },
    fetchPersonFailed: (state: State, action: PayloadAction<IPayloadError>) => {
      const { payload } = action;
      state.person.loading = false;
      state.person.error =
        typeof payload.message === 'string'
          ? payload.message
          : 'An error occured';
    },
    fetchAddPicturePersonSucceeded: (
      state: State,
      action: PayloadAction<IPerson>,
    ) => {
      const { payload } = action;
      state.me = payload;
      state.loading['add-picture-user'] = false;
      state.loadingAddPicture = false;
    },
    fetchAddPicturePerson: (state: State, _action: PayloadAction) => {
      state.loadingAddPicture = true;
      state.loading['add-picture-user'] = true;
    },
    fetchAddPicturePersonFailed: (
      state: State,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.loading['add-picture-user'] = false;
      state.loadingAddPicture = false;
    },

    fetchPersonDisabledSucceeded: (
      state: State,
      action: PayloadAction<string>,
    ) => {
      const { payload } = action;
      state.list[payload].enabled = false;
      state.loading['disabled'] = false;
    },

    fetchPersonEnabledSucceeded: (
      state: State,
      action: PayloadAction<string>,
    ) => {
      const { payload } = action;
      state.list[payload].enabled = true;
      state.loading['enabled'] = false;
    },

    /**
     * Get persons with filter.
     */
    fetchPersonsSearchSucceeded: (
      state: State,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IPerson };
        total?: number;
        page?: number;
        itemsPerPage?: number;
        id?: string;
      }>,
    ) => {
      const { payload } = action;
      if (action.payload.page === 1) {
        state.list = { ...payload.data };
      } else {
        state.list = { ...state.list, ...payload.data };
      }
      state.view['list'].total = action.payload.total;
      state.view['list'].itemsPerPage = action.payload.itemsPerPage;

      const keys = Object.keys(payload);

      keys.map((key) => {
        state.loading[key] = false;
      });
      state.loading[payload.key] = false;
    },
    fetchUpdatePerson: (state: State, action: PayloadAction<string>) => {
      state.loading[action.payload] = true;
      state.person.loading = true;
    },
    fetchUpdatePersonSucceeded: (
      state: State,
      action: PayloadAction<{ id: string; data: IPerson }>,
    ) => {
      const { payload } = action;
      state.person.person = payload.data;
      state.loading[action.payload.id] = false;
      state.person.loading = false;
    },
    fetchUpdatePersonFailed: (
      state: State,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      state.loading[action.payload.id] = false;
      state.person.loading = false;
      state.person.error = action.payload.error;
    },
    ...personsSlices,
  },
});

export const {
  fetchClear,
  fetchPersonsMe,
  fetchPersonsMeFailed,
  fetchPersonsMeSucceeded,
  fetchChangePassword,
  fetchChangePasswordFailed,
  fetchChangePasswordSucceeded,
  fetchUpdateUserAndChangePassword,
  fetchUpdateUserAndChangePasswordFailed,
  fetchUpdateUserAndChangePasswordSucceeded,
  fetchPersons,
  fetchPersonsClear,
  fetchPersonsFailed,
  fetchPersonsSucceeded,
  fetchPerson,
  fetchPersonSucceeded,
  fetchPersonFailed,
  fetchAddPicturePerson,
  fetchAddPicturePersonFailed,
  fetchAddPicturePersonSucceeded,
  fetchPersonDisabledSucceeded,
  fetchPersonEnabledSucceeded,
  fetchPersonsSearchSucceeded,
  fetchUpdatePerson,
  fetchUpdatePersonFailed,
  fetchUpdatePersonSucceeded,
} = personSlice.actions;

export const { reducer: personReducer } = personSlice;

export {
  personsSelector,
  personsLoadingSelector,
  personsErrorSelector,
  personsTotalSelector,
};
