import { useDispatch, useSelector } from 'react-redux';

import { errorSelector, loadingSelector } from '../slices/user.slice';
import { changePasswordRequest } from '../thunks/change-password.thunk';

export const useChangePassword = () => {
  const dispatch = useDispatch();

  const changePassword = async (
    oldPassword: string,
    password: string,
    passwordRepeat: string,
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        changePasswordRequest(oldPassword, password, passwordRepeat, (err) => {
          if (err != null) {
            reject(err);
          } else {
            resolve();
          }
        }),
      );
    });
  };

  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);

  return { changePassword, loading, error };
};
