import { useDispatch } from 'react-redux';

import { postPasswordResetValidate } from '../thunks/user.reset-validate.thunk';

export const usePasswordResetValidate = () => {
  const dispatch = useDispatch();

  const sendPasswordResetValidate = async (
    password: string,
    passwordRepeat: string,
    token: string,
  ) =>
    dispatch(
      postPasswordResetValidate(password, passwordRepeat, token, (err) => {
        if (err) {
          throw err;
        }
      }),
    );

  return { sendPasswordResetValidate };
};
