import { useDispatch } from 'react-redux';

import { postPasswordResetRequest } from '../thunks/user.reset-request.thunk';

export const usePasswordResetRequest = () => {
  const dispatch = useDispatch();

  const sendPasswordResetRequest = async (email: string) =>
    dispatch(
      postPasswordResetRequest(email, (err) => {
        if (err) {
          throw err;
        }
      }),
    );

  return { sendPasswordResetRequest };
};
