import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { IPagination } from 'shared-entities/pagination.entity';
import { IAppState, useReduxDispatch } from 'store';

import { IOrder } from '../../entities/order.entity';
import { OrderApi } from '../api/order.api';
import {
  makeOrdersSortedByDateSelector,
  ordersLoadingSelector,
  ordersOfTodaySelector,
  ordersSelector,
  totalOrdersSelector,
  viewSelector,
} from '../slices/order.slice';
import {
  getOrdersByOrganizationRequest,
  getOrdersRequest,
  ISort,
} from '../thunks/orders.thunk';

export const useOrders = <T>() => {
  const dispatch = useReduxDispatch();
  const getOrdersTable = async (
    page: number,
    itemsPerPage: number,
    filters?: any,
  ) => {
    const response: AxiosResponse<IPagination<
      IOrder<T>
    >> = await OrderApi.getOrders(page, itemsPerPage, filters);
    const { results, view } = response.data;

    const normalizedResults: IOrder<T>[] = results.map((order) => order);

    return { results: normalizedResults, view };
  };

  const getOrders = (
    page = 1,
    itemsPerPage = 1000,
    sort: ISort = { field: 'createdAt', order: 'desc' },
    filters: any = {},
  ) => {
    dispatch(getOrdersRequest(page, itemsPerPage, sort, filters));
  };

  const getOrdersByOrganization = (
    organizationId: string,
    page = 1,
    itemsPerPage = 15,
    sort: ISort = { field: 'createdAt', order: 'desc' },
    filters: any = {},
  ) => {
    dispatch(
      getOrdersByOrganizationRequest(
        organizationId,
        page,
        itemsPerPage,
        sort,
        filters,
      ),
    );
  };

  const view = useSelector(viewSelector);
  const orders = useSelector((state: IAppState) => ordersSelector<T>(state));
  const total = useSelector(totalOrdersSelector);
  const ordersLoading = useSelector(ordersLoadingSelector);
  const ordersOfToday = useSelector(ordersOfTodaySelector);
  const ordersSortedSelector = useSelector(makeOrdersSortedByDateSelector);

  return {
    ordersSortedSelector,
    total,
    orders,
    ordersOfToday,
    view,
    ordersLoading,
    getOrdersTable,
    getOrders,
    getOrdersByOrganization,
  };
};
