export class ConfigService {
  public static config: {
    apiKey: string;
    apiSecret: string;
    instanceName: string;
  } = null;

  static get(code) {
    return ConfigService.config[code];
  }

  static set(code, value) {
    ConfigService.config[code] = value;
  }

  static init(value) {
    ConfigService.config = value;
  }
}
