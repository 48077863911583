import { AxiosError, AxiosResponse } from 'axios';
import { IPerson } from 'person';
import { Dispatch } from 'redux';
import { IView } from 'shared-entities/pagination.entity';
import { AppThunk, generateErrorHandlerAction } from 'store';
import { v4 as uuidv4 } from 'uuid';
import { IOrganization } from '../../entities/organization.entity';
import { OrganizationApi } from '../api/organization.api';
import { IAppStateEmployee } from '../slices/employee.slice';
import { IAppStateFounder } from '../slices/founder.slice';
import {
  fetchAddOrganization,
  fetchAddOrganizationFailed,
  fetchAddOrganizationOpeningHours,
  fetchAddOrganizationOpeningHoursFailed,
  fetchAddOrganizationOpeningHoursSucceeded,
  fetchAddOrganizationSucceeded,
  fetchFullUpdateOrganization,
  fetchFullUpdateOrganizationFailed,
  fetchFullUpdateOrganizationSucceeded,
  fetchGetClear,
  fetchGetOrganizations,
  fetchGetOrganizationsFailed,
  fetchGetOrganizationsSucceeded,
  fetchGetSearchOrganizations,
  fetchGetSearchOrganizationsFailed,
  fetchGetSearchOrganizationsSucceeded,
  fetchOrganization,
  fetchOrganizationFailed,
  fetchOrganizationSucceeded,
  fetchUpdateOrganization,
  fetchUpdateOrganizationFailed,
  fetchUpdateOrganizationSucceeded,
  IAppStateOrganization,
} from '../slices/organization.slice';

interface IAppState
  extends IAppStateEmployee,
    IAppStateFounder,
    IAppStateOrganization {}

export const clearGetOrganizationRequest = (): AppThunk => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(fetchGetClear());
};

export const getOrganizationsRequest = (
  page = 1,
  itemsPerPage = 10,
  params = {},
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchGetOrganizations());

  let response: AxiosResponse<{
    results: IOrganization[];
    view: IView;
  }> | null = null;

  try {
    response = await OrganizationApi.getOrganizations(
      page,
      itemsPerPage,
      params,
    );
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchGetOrganizationsFailed({
        isAxiosError,
        message,
        name,
        code: statusCode,
        stack,
      }),
    );
    return;
  }
  if (response && response.data) {
    // - Dispatch succeeded, set organizations list

    const organizations: {
      [key: string]: IOrganization;
    } = response?.data.results.reduce((acc, cur) => {
      acc[cur.id] = { ...cur };
      return acc;
    }, {});

    dispatch(
      fetchGetOrganizationsSucceeded({
        list: organizations,
        view: response.data.view,
      }),
    );
    return;
  }
};

export const getSearchOrganizationsRequest = (
  page = 1,
  itemsPerPage = 10,
  params = {},
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  dispatch(fetchGetSearchOrganizations());

  let response: AxiosResponse<{
    results: IOrganization[];
    view: IView;
  }> | null = null;

  try {
    response = await OrganizationApi.getOrganizations(
      page,
      itemsPerPage,
      params,
    );
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchGetSearchOrganizationsFailed({
        isAxiosError,
        message,
        name,
        code: statusCode,
        stack,
      }),
    );
    return;
  }
  if (response && response.data) {
    // - Dispatch succeeded, set organizations list

    const organizations: {
      [key: string]: IOrganization;
    } = response?.data.results.reduce((acc, cur) => {
      acc[cur.id] = { ...cur };
      return acc;
    }, {});

    dispatch(
      fetchGetSearchOrganizationsSucceeded({
        list: organizations,
        view: response.data.view,
      }),
    );
    return;
  }
};

export const getOrganizationRequest = (
  organizationId: string,
  callback: (error?: { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  dispatch(fetchOrganization());

  let response: AxiosResponse<IOrganization> | null = null;

  try {
    response = await OrganizationApi.getOrganization(organizationId);
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    if (name && response && stack && isAxiosError) {
      const {
        data: { statusCode, message },
      } = responseError;
      // - Fetch failed, set error
      dispatch(
        fetchOrganizationFailed({
          isAxiosError,
          message,
          name,
          code: statusCode,
          stack,
        }),
      );
      callback({ message, statusCode });
      return;
    }
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set establishement
    const { data } = response;

    const establishementNormelized: IOrganization = data;

    dispatch(fetchOrganizationSucceeded(establishementNormelized));
    return;
  }
  callback({ message: 'failed', statusCode: '' });
};

export const addOrganizationRequest = (
  organization: Partial<IOrganization>,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IOrganization> | null = null;
  dispatch(fetchAddOrganization());
  const data = organization;

  const openingTimes = data.openingHoursSpecification?.map((openingTime) => {
    return { ...openingTime, id: uuidv4() };
  });

  try {
    const { id } = organization;
    if (id === undefined) {
      dispatch(
        fetchAddOrganizationFailed({
          isAxiosError: false,
          message: 'Organization not created',
          name: 'Format Error',
          stack: '',
        }),
      );
      return;
    }

    response = await OrganizationApi.addOrganization({
      ...data,
      openingHoursSpecification: openingTimes,
    });
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchAddOrganizationFailed,
      ),
    );
    onError(error);
  }
  if (response && response?.data && response.data.id) {
    // - Dispatch succeeded, set Organization
    dispatch(fetchAddOrganizationSucceeded());
    onError(undefined);
  }
};

export const addOpeningHoursRequest = (
  organizationId: string,
  openingHours: {
    id: string;
    dayOfWeek: string;
    closes: Date | string;
    opens: Date | string;
  },
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse | null = null;
  dispatch(fetchAddOrganizationOpeningHours());

  try {
    if (
      organizationId &&
      openingHours &&
      openingHours.closes &&
      openingHours.opens
    ) {
      response = await OrganizationApi.addOpeningHours(
        organizationId,
        openingHours,
      );
    } else {
      dispatch(
        fetchAddOrganizationOpeningHoursFailed({
          isAxiosError: false,
          message: 'Opening hours not added',
          name: 'Format Error',
          stack: '',
        }),
      );
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchAddOrganizationOpeningHoursFailed,
      ),
    );
    onError(error);
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set Organization
    dispatch(fetchAddOrganizationOpeningHoursSucceeded());
    onError(undefined);
  }
};

export const updateOrganization = (
  partialOrganization: Partial<IOrganization>,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  let response: AxiosResponse<IOrganization> | null = null;

  dispatch(fetchUpdateOrganization());

  try {
    const organization = getState().organization.get.organization;

    let metadatas = undefined;
    if (
      organization &&
      organization.metadatas &&
      partialOrganization &&
      partialOrganization.metadatas
    ) {
      metadatas = {
        ...organization.metadatas,
        ...partialOrganization.metadatas,
      };
    } else if (
      organization &&
      organization.metadatas &&
      (!partialOrganization || !partialOrganization.metadatas)
    ) {
      metadatas = organization.metadatas;
    } else if (
      (!organization || !organization.metadatas) &&
      partialOrganization &&
      partialOrganization.metadatas
    ) {
      metadatas = partialOrganization.metadatas;
    }

    if (partialOrganization.openingHoursSpecification) {
      partialOrganization.openingHoursSpecification = partialOrganization.openingHoursSpecification.map(
        (item) => (!item.id ? { ...item, id: uuidv4() } : item),
      );
    }

    response = await OrganizationApi.updateOrganization(
      partialOrganization.id,
      {
        ...partialOrganization,
        metadatas,
      },
    );
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchUpdateOrganizationFailed,
      ),
    );
    onError(error);
    return;
  }
  if (response && response.data && response.data.id) {
    const { data } = response;
    dispatch(fetchUpdateOrganizationSucceeded(data));
    onError(undefined);
  }
};
export const updateFullOrganizationRequest = (
  organizationDatas: Partial<IOrganization>,
  founder: Partial<IPerson>,
  onError: (err?: AxiosError) => void,
  pictureFile?: File,
): AppThunk => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  let response: AxiosResponse<IOrganization> | null = null;
  // Todo : implement picture edit in updateFullOrganization
  dispatch(fetchFullUpdateOrganization());

  try {
    const organization = getState().organization.get.organization;
    if (organizationDatas?.id === organization?.id) {
      if (organizationDatas.openingHoursSpecification) {
        organizationDatas.openingHoursSpecification = organizationDatas.openingHoursSpecification.map(
          (item) => (!item.id ? { ...item, id: uuidv4() } : item),
        );
      }
      await OrganizationApi.updateFounder(
        organization?.id,
        founder.id,
        founder,
      );

      response = await OrganizationApi.updateOrganization(
        organizationDatas.id,
        {
          ...organizationDatas,
          metadatas: {
            ...organization.metadatas,
            ...organizationDatas.metadatas,
          },
        },
      );
    } else {
      const error = {
        isAxiosError: false,
        message: 'Organization not found',
        name: 'Format Error',
        stack: '',
        config: null,
        toJSON: null,
      };
      dispatch(fetchFullUpdateOrganizationFailed(error));
      onError(error);
      return;
    }
  } catch (error) {
    dispatch(
      generateErrorHandlerAction(
        error as AxiosError<any>,
        fetchUpdateOrganizationFailed,
      ),
    );
    onError(error);
    return;
  }
  if (response && response.data && response.data.id) {
    const { data } = response;
    dispatch(fetchFullUpdateOrganizationSucceeded(data));
    onError(undefined);
  }
};
