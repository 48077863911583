import axios from 'axios';
import { IPerson } from 'person';
import qs from 'qs';

export default class PaymentApi {
  public static postCredentials = (
    firstName: string,
    lastName: string,
    birthDate: string,
    email: string,
  ) => {
    const data = new window.FormData();

    data.append('firstName', firstName);
    data.append('lastName', lastName);
    data.append('email', email);
    data.append('birthDate', birthDate);
    return axios.post(
      '/api/shop/payments/request-credentials-to-add-card',
      data,
    );
  };

  public static registerCard = async (
    cardCvx: string,
    cardExpirationDate: string,
    cardNumber: string,
    CardRegistrationURL: string,
    PreregistrationData: string,
    AccessKey: string,
    Id: string,
  ) => {
    const instance = axios.create({
      validateStatus: (status: number) => status >= 200 && status <= 302,
    });
    instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    instance.interceptors.request.use((config) => {
      config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };
      config.maxRedirects = 0;

      return config;
    });
    const res = await instance.post<string>(
      CardRegistrationURL,
      qs.stringify({
        data: PreregistrationData,
        cardNumber,
        cardExpirationDate,
        accessKeyRef: AccessKey,
        cardCvx,
      }),
    );

    if (res && res.data) {
      const data = res.data.replace('data=', '');
      const validateResponse = await axios.get<{
        id: string;
        data: string;
      }>(`/shop/payments/validate-card/${Id}?data=${data}`);
      if (validateResponse && validateResponse.data) {
        return validateResponse;
      }
    }

    throw new Error('Impossible to add card');
  };

  public static getValidateCard = (id: string) => {
    return axios.get(`/api/shop/payments/validate-card/${id}`);
  };
  public static postSaveCard = (id: string, token: string) => {
    return axios.post('/api/shop/payments/save-card', {
      id,
      token,
    });
  };
  public static postDefaultCard = (id: string) => {
    return axios.post<IPerson>(`/api/shop/payments/card/${id}/default`);
  };
  public static deleteCard = (id: string) => {
    return axios.delete(`/api/shop/payments/card/${id}`);
  };
  public static getSecurePayment = (id: string) => {
    return axios.get(`/api/shop/payments/secure/${id}`);
  };
}
