import { IPlace } from 'place/entities/place.entity';
import { useState } from 'react';

import { OrganizationApi } from '../api/organization.api';

export type AddressType = 'PERSONAL' | 'PROFESSIONAL' | 'BILLING';

export const useOrganizationAddress = (organizationId: string) => {
  const [loading, setLoading] = useState(false);

  const addAddress = async (place: IPlace, type: AddressType) => {
    setLoading(true);
    await OrganizationApi.addAddressToOrganization(organizationId, {
      ...place,
      type,
    });
    setLoading(false);
  };

  const removeAddress = async (addressId: string) => {
    setLoading(true);
    await OrganizationApi.removeAddressFromOrganization(
      organizationId,
      addressId,
    );
    setLoading(false);
  };

  return {
    loading,
    addAddress,
    removeAddress,
  };
};
