import { useDispatch, useSelector } from 'react-redux';
import { IAppState, selectError } from 'store';
import { isUserValidForMangoPay } from '../helpers/mangopay.user.validation';
import {
  fetchCredentialsClear,
  fetchCredentialsFailed,
  makeLoadingSelector,
  mangoPaySelector,
} from '../slices/payment.slice';
import { postCredentialRequest } from '../thunks/payment.thunk';

export const usePayment = () => {
  const dispatch = useDispatch();

  const requestCredentials = (
    firstName?: string,
    lastName?: string,
    birthDate?: string,
    email?: string,
    callback?: (err: any) => void,
  ) => {
    if (
      isUserValidForMangoPay({ firstName, lastName, birthDate, email }) &&
      firstName &&
      lastName &&
      birthDate &&
      email
    ) {
      dispatch(
        postCredentialRequest(firstName, lastName, birthDate, email, callback),
      );
    } else {
      dispatch(
        fetchCredentialsFailed({
          name: '',
          message: 'Property missing for posting mangoPay credentials',
          isAxiosError: false,
          stack: '',
        }),
      );
    }
  };
  const mangoPay = useSelector(mangoPaySelector);

  const error = useSelector((state: IAppState) =>
    selectError(state, 'payment/fetchCredential'),
  );

  const loading = useSelector(makeLoadingSelector)('loading');
  const clear = () => {
    dispatch(fetchCredentialsClear());
  };

  return {
    requestCredentials,
    mangoPay,
    error,
    clear,
    loading,
  };
};
