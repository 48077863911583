export enum StatusType {
  'NOT_UPLOADED' = 'NOT_UPLOADED',
  'CREATED' = 'CREATED',
  'VALIDATION_ASKED' = 'VALIDATION_ASKED',
  'VALIDATED' = 'VALIDATED',
  'REFUSED' = 'REFUSED',
}

export interface IDocumentResponse {
  id?: string;
  type?: string;
  media?: IMedia;
  organizationId?: string;
  createdAt?: string;
  uri?: string;
  status?: string;
  consumerId?: string;
  tag?: string;
  refusedReasonMessage?: string;
  refusedReasonType?: string;
}

interface IMedia {
  caption: ICaption;
  id: string;
  uri: string;
  domain: string;
  consumerId: string;
}

interface ICaption {
  id: string;
  size: number;
  contentUrl: string;
  description: string;
  name: string;
  encodingFormat: string;
}
