import { IAppState, IPayloadError } from 'store';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICategoryNested } from '../../entities/category.type';

const initialState: {
  loading: { [key: string]: boolean };
  error: { [key: string]: IPayloadError };
  list: { [key: string]: ICategoryNested };
} = {
  loading: {},
  error: {},
  list: {},
};

export type CategoryState = typeof initialState;

export const categoriesLoadingSelector = (state: IAppState) =>
  state.category.loading;
export const categoriesErrorSelector = (state: IAppState) =>
  state.category.error;

export const categoriesSelector = (state: IAppState) => state.category.list;

export const categoriesArraySelector = (state: IAppState) =>
  Object.values(state.category.list);

export const publicCategoriesSelector = createSelector(
  categoriesSelector,
  (categories: { [key: string]: ICategoryNested }) => {
    const filteredCategories = Object.values(categories).filter(
      (cat) => cat.organizationUri === '/api/organizations/null',
    );
    return filteredCategories.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
  },
);

export const categoriesByOrganizationSelector = (organizationId: string) =>
  createSelector(
    categoriesSelector,
    (categories: { [key: string]: ICategoryNested }) => {
      const filteredCategories = Object.values(categories).filter(
        (cat) =>
          cat.organizationUri === `/api/organizations/${organizationId}` &&
          cat.parentUri == null,
      );
      return filteredCategories;
    },
  );

export const publicCategoryBySlugSelector = createSelector(
  categoriesSelector,
  (categories) => (categorySlug: string) =>
    Object.values(categories).find(
      (cat) =>
        cat.slug === categorySlug &&
        cat.organizationUri === '/api/organizations/null',
    ),
);

export const privateCategoryBySlugSelector = createSelector(
  categoriesSelector,
  (categories) => (categorySlug: string, organizationUri: string) =>
    Object.values(categories).find(
      (cat) =>
        cat.slug === categorySlug && cat.organizationUri === organizationUri,
    ),
);

export const categoryByIdSelector = (categoryId: string) =>
  createSelector(
    categoriesSelector,
    (categories: { [key: string]: ICategoryNested }) => {
      return categories[categoryId];
    },
  );

export const parentCategoriesSelector = createSelector(
  categoriesSelector,
  (categories: { [key: string]: ICategoryNested }) => {
    const filteredCategories = Object.values(categories).filter(
      (cat) => !cat?.parentUri,
    );
    return filteredCategories;
  },
);

export const publicParentCategoriesSelector = createSelector(
  categoriesSelector,
  (categories: { [key: string]: ICategoryNested }) => {
    const filteredCategories = Object.values(categories).filter(
      (cat) =>
        !cat?.parentUri && cat?.organizationUri === '/api/organizations/null',
    );
    return filteredCategories;
  },
);

export const childrenPrivateCategoryByUriSelector = createSelector(
  categoriesSelector,
  (categories) => (categoryUri: string) =>
    Object.values(categories).filter((cat) => cat.parentUri === categoryUri),
);

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    fetchClear: (state: CategoryState, _action: PayloadAction) => {
      state.list = {};
      state.error = {};
      state.loading = {};
    },
    fetchCategories: (state: CategoryState, _action: PayloadAction) => {
      state.loading['list'] = true;
    },

    fetchCategorySucceeded: (
      state: CategoryState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: ICategoryNested };
        id?: string;
      }>,
    ) => {
      const { payload } = action;

      if (payload.key === 'delete') {
        delete state.list[payload.id];
      } else {
        state.list = { ...state.list, ...payload.data };
      }

      const keys = Object.keys(payload);

      keys.map((key) => {
        state.loading[key] = false;
      });

      state.loading[payload.key] = false;
    },

    fetchCategoryFailed: (
      state: CategoryState,
      action: PayloadAction<{
        key: string;
        data?: { [key: string]: IPayloadError };
        error: IPayloadError;
      }>,
    ) => {
      const { payload } = action;

      const keys = Object.keys(payload.data);

      keys.map((key) => {
        state.loading[key] = false;
      });
      keys.map((key) => {
        state.error[key] = payload[key];
      });
      state.error[payload.key] = payload.error;
    },

    fetchAddCategory: (state: CategoryState, _action: PayloadAction) => {
      state.loading['add'] = true;
    },

    fetchUpdateCategory: (
      state: CategoryState,
      action: PayloadAction<string>,
    ) => {
      const { payload } = action;
      state.loading[payload] = true;
    },
    fetchDeleteCategory: (state: CategoryState, _action: PayloadAction) => {
      state.loading['delete'] = true;
    },
  },
});

export const {
  fetchCategories,
  fetchCategoryFailed,
  fetchCategorySucceeded,
  fetchClear,
  fetchUpdateCategory,
  fetchAddCategory,
  fetchDeleteCategory,
} = categorySlice.actions;

export const { reducer: categoryReducer } = categorySlice;
