import { useDispatch, useSelector } from 'react-redux';
import { IAppState, ReduxDispatch } from 'store';

import { IOpinion } from '../../entities/opinions.entity';
import {
  errorSelector,
  loadingSelector,
  makeOpinionListSelector,
} from '../slices/opinions.selectors';
import { updateOpinionThunk } from '../thunks/opinions.update.thunk';

export const useAdminOpinion = (id: string) => {
  const dispatch = useDispatch<ReduxDispatch>();
  const loading = useSelector((state: IAppState) => loadingSelector(state, id));
  const error = useSelector((state: IAppState) => errorSelector(state, id));
  const opinionSelector = useSelector(makeOpinionListSelector);
  const opinion = opinionSelector(id);

  const updateOpinion = (opinion: Partial<IOpinion<any, any>>) => {
    return dispatch(updateOpinionThunk(id, opinion));
  };

  return { updateOpinion, opinion, loading, error };
};
