import { useSelector } from 'react-redux';
import { useReduxDispatch } from 'store';
import { IOrder } from '../../entities';
import { makeOrderSelector } from '../slices/order.slice';
import { changeOrderStatusThunk } from '../thunks/order.status.thunk';
import { getOrderRequest } from '../thunks/order.thunk';

export const useOrder = <T>(id: string) => {
  const dispatch = useReduxDispatch();

  const getOrder = () => {
    return dispatch(getOrderRequest(id));
  };

  const updateStatus = (status: number) => {
    return dispatch(changeOrderStatusThunk(id, status));
  };

  const orderSelector = useSelector(makeOrderSelector);
  const order = orderSelector(id) as IOrder<T>;

  return { getOrder, order, updateStatus };
};
