import { useSelector } from 'react-redux';

import {
  errorSelector,
  loadingSelector,
  tokenSelector,
} from '../slices/auth.slice';

export const useAuthStatus = () => {
  const token = useSelector(tokenSelector);

  const loading = useSelector(loadingSelector);

  const error = useSelector(errorSelector);

  return { token, loading, error };
};
