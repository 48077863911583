import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { IClaim } from '../../entities/claim.entity';
import ClaimApi from '../api/claim.api';
import {
  fetchClaim,
  fetchClaimFailed,
  fetchClaimSucceeded,
} from '../slices/claim.slice';

export const addClaimRequest = (
  idClaim: string,
  claim: IClaim,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk<Promise<void>> => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IClaim> | null = null;

  dispatch(fetchClaim({ key: 'add' }));

  try {
    response = await ClaimApi.createClaim(idClaim, claim);
  } catch (error) {
    dispatch(
      fetchClaimFailed({
        key: 'add',
        error: {
          ...error,
          message: 'An error occured',
        },
      }),
    );
    onError({ ...error, message: 'An error occured' });
    return;
  }
  if (response && response.data) {
    dispatch(
      fetchClaimSucceeded({
        key: 'add',
        data: { [response.data.id]: response.data },
      }),
    );
    onError(undefined);
    return;
  }
  onError({
    statusCode: '404',
    message: 'An error occured',
  });
  return;
};
