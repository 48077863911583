import { useDispatch, useSelector } from 'react-redux';
import { ReduxDispatch } from 'store';

import { impersonateTokenSelector } from '../slices/auth.slice';
import { getImpersonateRequest } from '../thunks/auth.impersonate.thunk';

export const useImpersonate = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const impersonate = (userId: string) => {
    return dispatch(getImpersonateRequest(userId));
  };
  const impersonateToken = useSelector(impersonateTokenSelector);
  return { impersonate, impersonateToken };
};
