import { AxiosResponse } from 'axios';
import { IPagination } from 'shared-entities/pagination.entity';
import { cmsInstance } from 'store';

import { IPost } from '../../entities/post.entity';

export interface IPostFilter {
  page: number;
  itemsPerPage: number;
  id: string;
  organizationId: string;
  metadatas: string;
  type: 'page' | 'story' | 'trainings' | 'faq' | 'post';
}

export class CmsApi {
  public static CMS_ENDPOINT = '/api/cms/articles';
  public static POST_ENDPOINT = `${CmsApi.CMS_ENDPOINT}/posts`;

  public static getPosts = (
    filter: Partial<IPostFilter>,
  ): Promise<AxiosResponse<IPagination<IPost>>> =>
    cmsInstance.get(CmsApi.POST_ENDPOINT, { params: filter });

  public static getPages = (filter: Partial<IPostFilter>) =>
    CmsApi.getPosts({ ...filter, type: 'page' });

  public static updatePost = (id: string, values: Partial<IPost>) =>
    cmsInstance.put(`${CmsApi.POST_ENDPOINT}/${id}`, values);

  public static deletePost = (id: string) =>
    cmsInstance.delete(`${CmsApi.POST_ENDPOINT}/${id}`);

  public static addPost = (type: string, values: Partial<IPost>) => {
    switch (type) {
      case 'faq':
        return cmsInstance.post(`${CmsApi.CMS_ENDPOINT}/faqs`, values);
      case 'story':
        return cmsInstance.post(`${CmsApi.CMS_ENDPOINT}/stories`, values);
      case 'blog':
        return cmsInstance.post(`${CmsApi.CMS_ENDPOINT}/posts`, values);
      case 'page':
        return cmsInstance.post(`${CmsApi.CMS_ENDPOINT}/static-page`, values);
      case 'trainings':
        return cmsInstance.post(`${CmsApi.CMS_ENDPOINT}/trainings`, values);
    }
  };

  public static addMedia = (id: string, data: any) => {
    return cmsInstance.post(`${CmsApi.POST_ENDPOINT}/${id}/media`, data);
  };
}
