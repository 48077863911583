import { IOrder } from 'order/entities';
import { IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  order: IOrder;
  error: IPayloadError;
  loading: boolean;
  sponsorCode?: string;
} = {
  loading: false,
  error: null,
  order: null,
  sponsorCode: null,
};

export type BasketState = typeof initialState;
export interface IAppStateBasket {
  basket: BasketState;
}

export const basketSelector = (state: IAppStateBasket) => state.basket.order;
export const loadingSelector = (state: IAppStateBasket) => state.basket.loading;
export const errorSelector = (state: IAppStateBasket) => state.basket.error;

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    fetchBasketClear: (state: BasketState, _action: PayloadAction) => {
      state.order = null;
      state.error = null;
      state.loading = false;
    },
    fetchBasket: (state: BasketState, payload: PayloadAction) => {
      state.loading = true;
    },
    fetchBasketSucceeded: (
      state: BasketState,
      { payload }: PayloadAction<IOrder>,
    ) => {
      state.order = payload;
      state.loading = false;
    },
    fetchBasketFailed: (
      state: BasketState,
      { payload }: PayloadAction<IPayloadError>,
    ) => {
      state.error = payload;
      state.loading = false;
    },
    fetchSponsorCodeToBasketSuccedded: (
      state: BasketState,
      { payload }: PayloadAction<string>,
    ) => {
      state.sponsorCode = payload;
      state.loading = false;
    },
    fetchSponsorCodeToBasketFailed: (
      state: BasketState,
      { payload }: PayloadAction<IPayloadError>,
    ) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  fetchBasket,
  fetchBasketFailed,
  fetchBasketSucceeded,
  fetchBasketClear,
  fetchSponsorCodeToBasketFailed,
  fetchSponsorCodeToBasketSuccedded,
} = basketSlice.actions;

export const { reducer: basketReducer } = basketSlice;
