import { IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  loading: boolean;
  error: string;
  delete: {
    loading: boolean;
    error: string;
  };
} = {
  loading: false,
  error: '',
  delete: {
    loading: false,
    error: '',
  },
};

export type FounderState = typeof initialState;
export interface IAppStateFounder {
  founder: FounderState;
}
export const founderLoadingSelector = (state: IAppStateFounder) =>
  state.founder.loading;

const founderSlice = createSlice({
  name: 'founder',
  initialState,
  reducers: {
    fetchAddFounder: (state: FounderState, _action: PayloadAction) => {
      state.loading = true;
    },

    fetchAddFounderSucceeded: (state: FounderState) => {
      state.loading = false;
    },

    fetchAddFounderFailed: (
      state: FounderState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.loading = false;
      state.error = '';
    },

    fetchUpdateFounder: (state: FounderState, _action: PayloadAction) => {
      state.loading = true;
    },

    fetchUpdateFounderSucceeded: (state: FounderState) => {
      state.loading = false;
    },

    fetchUpdateFounderFailed: (
      state: FounderState,
      _action: PayloadAction<IPayloadError>,
    ) => {
      state.loading = false;
      state.error = '';
    },

    fetchDeleteFounder: (state: FounderState, _action: PayloadAction) => {
      state.delete.loading = true;
    },

    fetchDeleteFounderSucceeded: (
      state: FounderState,
      _action: PayloadAction,
    ) => {
      state.delete.loading = false;
    },

    fetchDeleteFounderFailed: (
      state: FounderState,
      action: PayloadAction<IPayloadError>,
    ) => {
      const { payload } = action;
      state.delete.error = payload.message;
      state.delete.loading = false;
    },
  },
});

export const {
  fetchAddFounder,
  fetchAddFounderSucceeded,
  fetchAddFounderFailed,
  fetchUpdateFounder,
  fetchUpdateFounderSucceeded,
  fetchUpdateFounderFailed,
  fetchDeleteFounder,
  fetchDeleteFounderSucceeded,
  fetchDeleteFounderFailed,
} = founderSlice.actions;

export const { reducer: founderReducer } = founderSlice;
