import { AxiosError, AxiosResponse } from 'axios';
import { normalize, schema } from 'normalizr';
import { Dispatch } from 'redux';
import { IPagination } from 'shared-entities/pagination.entity';
import { AppThunk } from 'store';

import { IPost } from '../../entities/post.entity';
import { CmsApi, IPostFilter } from '../api/cms.api';
import {
  fetchCms,
  fetchCmsFailed,
  fetchCmsSucceeded,
} from '../slices/cms.slice';

export const getCmsThunk = (
  params: Partial<IPostFilter> = {},
  onError: (err?: AxiosError) => void,
): AppThunk<Promise<{ [key: string]: IPost }>> => async (
  dispatch: Dispatch<any>,
) => {
  let response: AxiosResponse<IPagination<IPost>> = null;
  dispatch(fetchCms('list'));
  try {
    response = await CmsApi.getPosts(params);
    const cmsSchema = new schema.Entity('cms');
    const {
      entities: { cms },
    } = normalize(response.data.results, [cmsSchema]);

    dispatch(
      fetchCmsSucceeded({
        list: cms as { [key: string]: IPost },
        view: response.data.view,
        key: 'list',
        type: params?.type,
      }),
    );
    onError();
    return cms;
  } catch (err) {
    const { name, response: responseError, stack, isAxiosError } = err;
    console.error({ err });
    const error = {
      isAxiosError,
      message: responseError?.data?.message,
      name,
      code: responseError?.data?.statusCode,
      stack,
      config: null,
      toJSON: null,
    };
    // - Fetch failed, set error
    dispatch(
      fetchCmsFailed({
        key: 'list',
        error,
      }),
    );
    onError(error);
    throw error;
  }
};
