import { IAppState, IPayloadError } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingValidate: false,
  error: '',
};

export type UserState = typeof initialState;

export const loadingSelector = (state: IAppState) => state.user.loading;
export const errorSelector = (state: IAppState) => state.user.error;

const userSlice = createSlice({
  reducers: {
    fetchPasswordResetRequest(state: UserState, _action: PayloadAction) {
      state.loading = true;
    },

    fetchPasswordResetRequestSucceeded(
      state: UserState,
      _action: PayloadAction,
    ) {
      state.loading = false;
    },
    fetchPasswordResetRequestFailed(
      state: UserState,
      _action: PayloadAction<IPayloadError>,
    ) {
      state.loading = false;
    },
    fetchPasswordResetValidate(state: UserState, _action: PayloadAction) {
      state.loadingValidate = true;
    },

    fetchPasswordResetValidateSucceeded(
      state: UserState,
      _action: PayloadAction,
    ) {
      state.loadingValidate = false;
    },

    fetchPasswordResetValidateFailed(
      state: UserState,
      _action: PayloadAction<IPayloadError>,
    ) {
      state.loadingValidate = false;
    },
    fetchChangePassword: (state: UserState, _action: PayloadAction) => {
      state.loading = true;
    },
    fetchPasswordSucceeded: (state: UserState, _action: PayloadAction) => {
      state.loading = false;
    },
    fetchPasswordFailed: (
      state: UserState,
      action: PayloadAction<IPayloadError>,
    ) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
  initialState,
  name: 'user',
});

export const {
  fetchChangePassword,
  fetchPasswordFailed,
  fetchPasswordSucceeded,
  fetchPasswordResetRequest,
  fetchPasswordResetRequestFailed,
  fetchPasswordResetRequestSucceeded,
  fetchPasswordResetValidate,
  fetchPasswordResetValidateFailed,
  fetchPasswordResetValidateSucceeded,
} = userSlice.actions;

export const { reducer: userReducer } = userSlice;
