import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';

import { BasketApi } from '../api/basket.api';
import {
  fetchBasket,
  fetchBasketFailed,
  fetchBasketSucceeded,
} from '../slices/basket.slice';

export const getCurrentBasketRequest = (): AppThunk<Promise<any>> => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(fetchBasket());

  let response: AxiosResponse<any> | null = null;

  try {
    response = await BasketApi.getCurrentBasket();
  } catch (error) {
    // - Fetch failed, set error
    dispatch(fetchBasketFailed(...error));
    return;
  }

  if (response != null && response?.data) {
    const { data } = response;
    const basket = data;
    dispatch(fetchBasketSucceeded(basket));
    return basket;
  }

  return;
};
