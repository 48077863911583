import { Loyalty } from '../../entities/loyalty.entity';
import { LoyaltyApi } from '../api/loyalty.api';
import { fetchUpdateDiscount } from '../slices/discount.slice';
import {
  fetchLoyalty,
  fetchLoyaltyFailed,
  fetchLoyaltySucceeded,
} from '../slices/loyalty.slice';

import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk, IAppState } from 'store';

export const getLoyaltyRequest = (organizationId: string): AppThunk => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(fetchLoyalty());

  let response: AxiosResponse<Loyalty> | null = null;

  try {
    response = await LoyaltyApi.getLoyalty(organizationId);
  } catch (err) {
    const { name, response: responseError, isAxiosError, stack } = err;
    const {
      data: { statusCode, message },
    } = responseError;
    // - Fetch failed, set error
    dispatch(
      fetchLoyaltyFailed({
        key: 'list',
        error: { isAxiosError, message, name, code: statusCode, stack },
      }),
    );
    return;
  }
  if (response && response.data) {
    const result = response.data;

    dispatch(
      fetchLoyaltySucceeded({
        key: 'list',
        data: { [result.id]: result },
      }),
    );
    return;
  }
};

export const updateLoyaltyRequest = (
  organizationId: string,
  data: Partial<Loyalty>,
  onError: (err?: AxiosError) => void,
): AppThunk => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  let response: AxiosResponse<Loyalty> | null = null;
  dispatch(fetchUpdateDiscount());

  try {
    response = await LoyaltyApi.updateLoyalty(organizationId, {
      ...data,
      amount: parseInt((data.amount as unknown) as string, 10),
    });
  } catch (error) {
    const { name, response: responseError, stack, isAxiosError } = error;
    if (responseError?.data) {
      const {
        data: { statusCode, message },
      } = responseError;
      dispatch(
        fetchLoyaltyFailed({
          key: 'update',
          error: {
            isAxiosError,
            message,
            name,
            code: statusCode,
            stack,
          },
        }),
      );
      onError(error);
      return;
    }
  }
  if (response && response.data && response.data.id) {
    // - Dispatch succeeded, set loyalty

    dispatch(
      fetchLoyaltySucceeded({
        key: 'update',
        data: { [response.data.id]: response.data },
      }),
    );
    onError(undefined);
  }
};
