import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store';
import { v4 as uuidv4 } from 'uuid';

import { IProduct, IOption } from '../../entities/product.entity';
import { ProductApi } from '../api/product.api';
import {
  fetchAddOption,
  fetchAddOptionFailed,
  fetchAddOptionSucceeded,
} from '../slices/product.slice';

export const addOptionRequest = (
  productId: string,
  option: Partial<IOption>,
  onError: (err?: AxiosError | { message: string; statusCode: string }) => void,
): AppThunk => async (dispatch: Dispatch<any>) => {
  let response: AxiosResponse<IProduct> | null = null;

  dispatch(fetchAddOption());

  try {
    response = await ProductApi.addOption(productId, {
      ...option,
      id: uuidv4(),
    });
  } catch (error) {
    dispatch(
      fetchAddOptionFailed({
        ...error,
        message: error?.data?.message ?? 'An error occured',
      }),
    );
    onError({
      ...error,
      message: error?.data?.message ?? 'An error occured',
    });
    return;
  }

  if (response && response.data && response.data.id) {
    dispatch(fetchAddOptionSucceeded(response.data));
    onError(undefined);
    return;
  }

  onError({
    statusCode: '404',
    message: 'An error occured',
  });
  return;
};
