import axios from 'axios';

export class InvoiceApi {
  public static PUBLIC_INVOINCE_ENDPOINT = '/api/shop/invoices';

  public static sendLinkToPayOnlineRequest = (orderId: string) =>
    axios.post(
      `${InvoiceApi.PUBLIC_INVOINCE_ENDPOINT}/${orderId}/send-link-to-payed-online`,
    );
}
