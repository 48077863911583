import axios from 'axios';
import { ILemonwayAddCard } from 'shop/entities/lemonway.entity';

export class PaymentApi {
  public static PUBLIC_DISCOUNT_ENDPOINT = '/api/shop/payments';

  public static requestCredentials = () => {
    return axios.post(
      `${PaymentApi.PUBLIC_DISCOUNT_ENDPOINT}/request-credentials-to-add-card`,
    );
  };

  public static saveCardLemonway = (card: ILemonwayAddCard) => {
    return axios.post(`${PaymentApi.PUBLIC_DISCOUNT_ENDPOINT}/save-card`, card);
  };
}
