import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addOpeningHoursRequest } from '../thunks/organization.thunk';
import { IOpeningHoursSpecification } from 'order';

export const useOpeningHours = () => {
  const dispatch = useDispatch();

  const addOpeningHours = async (
    organizationId: string,
    openingHours: IOpeningHoursSpecification,
  ) =>
    new Promise((resolve, reject) => {
      const normalizedOrganizationOpeningHours = {
        id: uuidv4(),
        dayOfWeek: openingHours.dayOfWeek,
        closes: openingHours.closes,
        opens: openingHours.opens,
      };
      dispatch(
        addOpeningHoursRequest(
          organizationId,
          normalizedOrganizationOpeningHours,
          (err) => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          },
        ),
      );
    });

  return { addOpeningHours };
};
