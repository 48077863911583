import axios from 'axios';
import { IOpeningHoursSpecification } from 'order';

export class HoursSpecificationApi {
  public static update = (
    organizationId: string,
    hoursSpecificationId: string,
    data: IOpeningHoursSpecification,
  ) =>
    axios.put(
      `/api/organizations/${organizationId}/opening-hours-specification/${hoursSpecificationId}`,
      data,
    );

  public static delete = (
    organizationId: string,
    hoursSpecificationId: string,
  ) =>
    axios.delete(
      `/api/organizations/${organizationId}/opening-hours-specification/${hoursSpecificationId}`,
    );
}
