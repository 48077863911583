import { parse } from 'date-fns';

export const formatBirthDate = (dateString: string | undefined): string => {
  return dateString
    ? parse(
        dateString,
        "dd'/'MM'/'yyyy",
        new Date(1990, 0, 0, 0, 0, 0),
      ).toISOString()
    : '';
};
