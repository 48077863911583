import { IView } from 'shared-entities/pagination.entity';
import { IAppState, IPayloadError } from 'store';

import { createSelector, PayloadAction } from '@reduxjs/toolkit';

import { IPerson } from '../../entities/person.entity';

export interface IInitialState {
  list: { [key: string]: IPerson };
  loading: { [key: string]: boolean };
  error: { [key: string]: IPayloadError };
  view: { [key: string]: IView | undefined };
}

export const initialState: {
  list: { [key: string]: IPerson };
  loading: { [key: string]: boolean };
  error: { [key: string]: IPayloadError };
  view: { [key: string]: IView | undefined };
} = {
  list: {},
  error: {},
  loading: {},
  view: {
    list: {
      page: 1,
      itemsPerPage: 10,
      total: 0,
    },
  },
};

export type PersonState = typeof initialState;

export const personSlice = {
  fetchPersonsClear: (state: PersonState, _action: PayloadAction) => {
    state.list = {};
    state.error = {};
    state.loading = {};
    state.view = {};
  },

  fetchPersons: (
    state: PersonState,
    action: PayloadAction<{
      key: string;
    }>,
  ) => {
    const { payload } = action;
    state.loading[payload.key] = true;
  },

  fetchPersonsSucceeded: (
    state: PersonState,
    action: PayloadAction<{
      key: string;
      data?: { [key: string]: IPerson };
      total?: number;
      page?: number;
      itemsPerPage?: number;
      id?: string;
    }>,
  ) => {
    const { payload } = action;
    if (payload.key === 'delete') {
      delete state.list[payload.id];
      state.view['list'].total--;
    }
    if (payload.key === 'list') {
      state.list = { ...state.list, ...payload.data };
      state.view['list'].total = action.payload.total;
      state.view['list'].itemsPerPage = action.payload.itemsPerPage;
    }
    if (payload.key === 'add') {
      state.list = { ...payload.data, ...state.list };
      state.view['list'].total++;
    } else {
      state.list = { ...state.list, ...payload.data };
    }

    const keys = Object.keys(payload);

    keys.map((key) => {
      state.loading[key] = false;
    });
    state.loading[payload.key] = false;
  },

  fetchPersonsFailed: (
    state: PersonState,
    action: PayloadAction<{
      key: string;
      data?: { [key: string]: IPayloadError };
      error: IPayloadError;
    }>,
  ) => {
    const { payload } = action;

    state.loading[payload.key] = false;
    state.error[payload.key] = payload.error;
  },
};

export const personsSelector = (state: IAppState) => state.person.list;

export const personsByTypeSelector = (personType: string, state: IAppState) =>
  Object.values(state.person.list).filter((user) => user.type === personType);

export const clientsSelector = createSelector(
  personsSelector,
  (users: { [key: string]: IPerson }) => {
    return Object.values(users).filter((user) => user.type === 'DEFAULT');
  },
);

export const loadingSelector = (state: IAppState) => state.person.loading;
export const errorSelector = (state: IAppState) => state.person.error;
export const totalSelector = (state: IAppState) =>
  state.person.view['list'].total;
export const personsPageSelector = (state: IAppState) =>
  state.person.view['list'].page;
export const personsItemsPerPageSelector = (state: IAppState) =>
  state.person.view['list'].itemsPerPage;
